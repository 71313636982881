import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import Loader from "react-loader-spinner";
import styled from 'styled-components'
import { blue } from '../Shared/colors'
import { setModalView, signUp, googleOAuth2, googleSignIn, setAuthLoading, addNotification } from '../../actions'
// import { GoogleLogin  } from 'react-google-login';
import { Icon } from 'semantic-ui-react'
import LogoImage from '../../images/Logo.png'
import jwt_decode from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';


const Header = styled.div`
    grid-row: 1 / 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`

const Logo = styled.img`
    height: 70px;
    align-self: center;
    margin-bottom: 20px;
`

const Title = styled.h3`
    margin: 0 auto 10px auto;
    text-transform: uppercase;
    font-size: 1.2rem;
    user-select: none;
`

const Body = styled.div`
    grid-row: 2 / 3;    
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
`

const Form = styled.form`
    width: 80%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-self: center;
    font-family: Roboto;
`

const InputContainer = styled.div`
    background: #ddd;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    padding: 0 5px;
    & > div {
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
    display: block !important;
    width: 35px !important;
    height: 18px !important;
    opacity: ${props => props.showPass ? 1 : .5} !important;
`

const TogglePasswordVisibility = styled(StyledIcon)`
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
`

const Input = styled.input`
    outline: none;
    border: none;
    background: #ddd;
    font-weight: 700;
    width: 100%;
    height: 100%;
`

const Submit = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: ${blue};
    color: #fff;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
`

const Footer = styled.div`
    grid-row: 3 / 4;    
    display: flex;
    flex-direction: column;
    user-select: none;
    color: #999;
    width: 80%;
    margin-top: 20px;
    text-align: center;
    justify-self: center;
    font-size: .9rem;
`

const Link = styled.a`
    color: #999;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`

const ErrorMessage = styled.p`
    color: red;
    font-size: .7rem;
    user-select: none;
    position: relative;
    bottom: 15px;
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const UserSelect = styled.select`
    height: 25px;
    padding: 2px 0;
    background: none;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    font-family: Roboto;
    font-weight: 700;
    color: #888;
    text-transform: capitalize;
    &:hover {
        cursor: pointer;
    }
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`

const GoogleButton = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    width: 80%;
    outline: none;
    border: none;
    border-radius: 5px;
    background: #ddd;
    color: ${blue};
    font-weight: 700;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 10px;
`

const GoogleLogo = styled.img`
    height: 60%;
    margin-right: 5px;
`

const MyCustomButton = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-self: center;
`


const renderInput = ({ input, meta, placeholder, type}) => {
    return (
        <div>
            <Input {...input}  type={type} placeholder={placeholder}  autoComplete="off"/>
            { renderError(meta)}
        </div>
    )
}

const renderRoleSelect = ({ input, meta, placeholder, type}) => {
        const options = ['client'].map(role => <option key={role} value={role}>{role}</option>)
        return (
            <div>
                <UserSelect {...input} name='role'>
                    { options }
                </UserSelect>
                { renderError(meta)}
            </div>
            
        )
    }

const renderError = props => {
    const { error, touched, active, submitFailed } = props
    if ((touched && error && !active) || submitFailed) {
        return <ErrorMessage>{error}</ErrorMessage>
    } else return <div />
}

const SignUp = ({ signUp, handleSubmit, setAuthLoading, auth_loading, setModalView, addNotification, form, googleSignIn }) =>  {
    
    const [showPass, setShowPass] = useState(false)

    const onSubmit = async formValues => {
        setAuthLoading(true)
        try {
            await signUp(formValues)
        } catch (e) {
            addNotification({ type: 'error', message: e.message })
        }
    }

    const handleGoogleLogin = data => {
        if (form.values && form.values.role) {
            const googleData = jwt_decode(data.credential);
            console.log(googleData)
            const user = {
                firstName: googleData.given_name,
                lastName: googleData.family_name,
                email: googleData.email,
                googleProfileImage: googleData.picture,
                id_token: googleData.sub,
                role: form.values.role
            }

            googleOAuth2(data)
            googleSignIn(user, 'signup')
        } else {
            addNotification({ type: 'error', message: 'Please select an account type'})
        }
    }
    
    if (auth_loading) {
        return (
            <Loading>
                <Loader
                    type="Bars"
                    color={blue}
                    height={50}
                    width={50}
                />
            </Loading>
        )
    }

    return (
        <Fragment>
                <Header>
                <Logo src={LogoImage} />
            </Header>
            <Body>
                <Title>Sign Up</Title>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <StyledIcon name='mail' />
                        <Field name="email" type="text" component={renderInput} placeholder='Email'/>
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='user' />
                        <Field name="firstName" type="text" component={renderInput} placeholder='First Name'/>
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='user' />
                        <Field name="lastName" type="text" component={renderInput} placeholder='Last Name'/>
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='shield' />
                        <Field name="password" type={showPass ? 'text' : 'password'} component={renderInput} placeholder='Password'/>
                        <TogglePasswordVisibility showPass={showPass} onClick={() => setShowPass(!showPass)} name='eye' />
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='shield' />
                        <Field name="verify" type={showPass ? 'text' : 'password'} component={renderInput} placeholder='Password'/>
                        <TogglePasswordVisibility showPass={showPass} onClick={() => setShowPass(!showPass)} name='eye' />
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='settings' />
                        <Field name="role" component={renderRoleSelect} />
                    </InputContainer>
                    <Submit type='submit'>Sign Up</Submit>
                </Form>
                <br />
                <MyCustomButton>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        onSuccess={handleGoogleLogin}
                        onFailure={googleOAuth2}
                        type = 'standard'
                        size = 'medium'
                        cookiePolicy={'single_host_origin'}
                        flow = 'auth-code'
                        text = 'signup_with'
                        scope={'https://www.googleapis.com/auth/drive.apps.readonly https://www.googleapis.com/auth/calendar.readonly'}
                        render={renderProps => (
                            <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}><GoogleLogo src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'/>Sign Up With Google</GoogleButton>
                        )}
                    />
                </MyCustomButton>
            </Body> 
            <Footer>
                <Link onClick={() => setModalView('signIn')} >Return to Sign In</Link>
            </Footer>
        </Fragment>
    )  
}

const validate = formValues => {
    const errors = {}

    if (!formValues.email) {
        errors.email = 'Email Required'
    }

    if (!formValues.password) {
        errors.password = 'Password Required'
    }

    if (!formValues.firstName) {
        errors.firstName = 'First Name Required'
    }

    if (!formValues.lastName) {
        errors.lastName = 'Last Name Required'
    }

    if (!formValues.verify) {
        errors.verify = 'Please Verify Password'
    }

    if (formValues.password && formValues.password.length < 8) {
        errors.password = 'Passwords Must > 7 Characters'
    }

    if (formValues.verify !== formValues.password) {
        errors.verify = 'Passwords Do Not Match'
    }

    if (formValues.email && !validateEmail(formValues.email)) {
        errors.email = 'Invalid Email Address'
    }

    if (!formValues.role || formValues.role === 'Account Type') {
        errors.role = 'Please Select a Role'
    }

    return errors
}

const validateEmail = email =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const mapStateToProps = state => {
    return {
        error: state.user.error,
        auth_loading: state.user.auth_loading,
        form: state.form.signUpForm
    }
}

const connected = connect(mapStateToProps, { setModalView, signUp, setAuthLoading, addNotification, googleSignIn })(SignUp)

export default reduxForm({
    form: 'signUpForm',
    validate,
    setModalView
})(connected)

