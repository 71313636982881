import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { blue, white } from '../../Shared/colors'


const NavItem = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: ${ props => props.mobile ? 'calc(50% - 50px)' : '110px'};
`

const NavItemTitle = styled.div`
    font-size: 1rem;
    color: #ddd;
    margin-left: 10px;
    position: relative;
    line-height: 1.2rem;
    ${ props => props.notification && `&:after {
        content: "${props.notification}";
        font-size: .75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        width: 18px;
        position: absolute;
        right: -18px;
        bottom: 10px;
        border-radius: 50%;
        background: red;
        color: white;
    }`}
`

const LinkContainer = styled.span`
    color: ${white};
    text-decoration: none;
    padding: 10px 0;
    opacity: ${props => !props.show ? '0' : '1'};
    visibilty: ${props => props.show ? 'visible' : 'hidden'};
    background: ${props => props.active ? 'rgba(0,0,0,.25)' : 'rgba(0,0,0,0)' };
    &:hover {
        cursor: pointer;
        background: rgba(0,0,0,.25);
    }
    &:nth-of-type(1):not(.logout) {
        transition: ${props => props.show && 'opacity .3s .5s ease-out'};
    }
    &:nth-of-type(2) {
        transition: ${props => props.show && 'opacity .3s .6s ease-out'};
    }
    &:nth-of-type(3) {
        transition: ${props => props.show && 'opacity .3s .7s ease-out'};
    }
    &:nth-of-type(4) {
        transition: ${props => props.show && 'opacity .3s .8s ease-out'};
    }
    &:nth-of-type(5) {
        transition: ${props => props.show && 'opacity .3s .9s ease-out'};
    }
    &:nth-of-type(6) {
        transition: ${props => props.show && 'opacity .3s 1s ease-out'};
    }
    &:nth-of-type(7) {
        transition: ${props => props.show && 'opacity .3s 1.1s ease-out'};
    }
    &:nth-of-type(8) {
        transition: ${props => props.show && 'opacity .3s 1.2s ease-out'};
    }
    &:last-of-type {
        margin-top: auto;
        margin-bottom: ${props => props.mobile ? '30px' : '10px'};
        width: 100%;
    }
`


const StyledLink = styled(Link)`
    color: ${white};
    text-decoration: none;
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
`

const ClientMenu = ({ currentUser, showMenu, view, mobile, handleShowView }) => (
    <>
        <LinkContainer show={showMenu} view={view} active={view === 'projects'} onClick={() => handleShowView('projects')}>
            <StyledLink to="/" >
                <NavItem mobile={mobile}>
                    <StyledIcon name='photo' size='large'/>
                    <NavItemTitle>Projects</NavItemTitle>
                </NavItem>
            </StyledLink>
        </LinkContainer>
        <LinkContainer show={showMenu} view={view} active={view === 'tutorials'} onClick={() => handleShowView('tutorials')}>
            <StyledLink to="/tutorials" >
                <NavItem mobile={mobile}>
                    <StyledIcon name='video play' size='large'/>
                    <NavItemTitle>Tutorials</NavItemTitle>
                </NavItem>
            </StyledLink>
        </LinkContainer> 
    </>
)

export default ClientMenu