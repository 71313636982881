import { 
    ADD_NOTIFICATION,
    CLEAR_NOTIFICATION
} from '../actions/types'


const initialState = { notifications: []}

const notificationReducer =  (state = initialState, action) => {
    switch(action.type) {
        case ADD_NOTIFICATION:
            const notification = action.payload
            const activeNotificationMessages = state.notifications.map(notification => notification.message)
            const exists = activeNotificationMessages.includes(notification.message) 
            
            if (!exists) {
                notification.id = Date.now()
                return { ...state, notifications: [ ...state.notifications, notification ]}
            } else return state
        case CLEAR_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter(notification => notification.id !== action.payload) }
        default:
            return state;
    }
}

export default notificationReducer