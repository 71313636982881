import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { blue } from '../Shared/colors'
import { setModalView, createProject } from '../../actions'
import moment from 'moment'


const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: Roboto;
`
const Header = styled.div`
    width: 66%;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 33% 33% 34%;
`

const Background = styled.div`
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    background: ${blue};
    height: 100%;
`

const Icon = styled.svg`
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    height: 65%;
    width: 65%;
    align-self: center;
    justify-self: center;
    fill: #fff;
`
const Title = styled.h3`
   grid-row: 1 / 2;
   grid-column: 2 / 3;
   align-self: flex-end;
   font-size: 1.3rem;
   margin-left: 20px;
`

const EventDate = styled.p`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    font-size: .8rem;
    margin-left: 20px;
`

const Form = styled.form`
  grid-row:  3 / 4;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;


  & select {
    color: ${blue};
    height: 20px;
    width: 130px;
    border: none;
    font-size: .9rem;
    text-align: center;
    border-bottom: 2px solid ${blue};
    margin-bottom: 20px;
  }

  & select:focus {
    outline: none;
    caret-color: ${blue};
  }  
`

const Select = styled.select`
`

const Button = styled.button`
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: flex-start;
  color: #fff;
  background: ${blue};
  border: none;
  font-family: Roboto;
  font-size: .8em;
  font-weight: 400;
  width: 80px;
  height: 25px;
  margin: 3px auto;
  justify-self: center;
  align-self: flex-end;
  margin-bottom: 20px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #fff;
    color: ${blue};
    border: 2px solid ${blue};
  }
  &:active {
    outline: none;
  }
`

const services = ['culling', 'editing', 'culling & editing', 'retouching']

function captialize(str) {
    const words = str.split(' ')
    return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ')
}

const renderServiceSelector = ({ input, meta: { touched, error } }) => (
      <Select {...input}>
          <option>Service</option>
        {services.map(val => (
          <option value={val} key={val}>
            {captialize(val)}
          </option>
        ))}
      </Select>  
    )


const CalendarEvent = ({ setModalView, selectedEvent, scheduleProject, createProject }) => {

    const handleSubmit = () => {
        if (scheduleProject && scheduleProject.serviceType !== "Service") {
            createProject({ name: selectedEvent.title, scheduledFor: selectedEvent.start, service: scheduleProject.serviceType})
            setModalView(null)
        }
    }

    if (selectedEvent) return (
        <Container onClick={e => e.stopPropagation()}>
            <Header>
                <Background />
                <Icon id="Icons" height="512" viewBox="0 0 74 74" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m21.231 7.87a1 1 0 0 1 -.464-1.886c.938-.49 1.844-.915 2.771-1.3a1 1 0 1 1 .762 1.85c-.871.36-1.724.76-2.609 1.222a1 1 0 0 1 -.46.114z"/><path d="m37 72a35.007 35.007 0 0 1 -29.525-53.8 1 1 0 0 1 1.865.494 1.042 1.042 0 0 1 -.157.549 33 33 0 1 0 27.817-15.243 33.692 33.692 0 0 0 -6.06.554 1 1 0 0 1 -.36-1.968 35.758 35.758 0 0 1 6.42-.586 35 35 0 0 1 0 70z"/><path d="m13.14 13.78a1 1 0 0 1 -.7-1.713c.735-.724 1.506-1.422 2.293-2.076a1 1 0 0 1 1.278 1.538c-.743.617-1.472 1.278-2.167 1.963a1 1 0 0 1 -.704.288z"/><path d="m37 65.271a28.271 28.271 0 1 1 28.271-28.271 28.3 28.3 0 0 1 -28.271 28.271zm0-54.542a26.271 26.271 0 1 0 26.271 26.271 26.3 26.3 0 0 0 -26.271-26.271z"/><path d="m71 72h-34a1 1 0 0 1 0-2h34a1 1 0 0 1 0 2z"/><path d="m37 27.144a6.072 6.072 0 1 1 6.072-6.072 6.079 6.079 0 0 1 -6.072 6.072zm0-10.144a4.072 4.072 0 1 0 4.072 4.072 4.077 4.077 0 0 0 -4.072-4.072z"/><path d="m21.773 38.205a6.075 6.075 0 0 1 -5.773-7.95 6.072 6.072 0 1 1 5.771 7.95zm0-10.147a4.079 4.079 0 0 0 -3.868 2.816 4.064 4.064 0 1 0 3.868-2.816z"/><path d="m27.583 56.1a6.043 6.043 0 0 1 -3.561-1.157 6.08 6.08 0 1 1 3.561 1.157zm-2.383-2.778a4.073 4.073 0 1 0 -1.628-2.657 4.077 4.077 0 0 0 1.628 2.657z"/><path d="m46.417 56.1a6.066 6.066 0 1 1 3.561-1.157 6.043 6.043 0 0 1 -3.561 1.157zm-.017-10.145a4.085 4.085 0 1 0 .645.051 4.133 4.133 0 0 0 -.645-.051z"/><path d="m52.227 38.205a6.075 6.075 0 1 1 1.872-.3 6.084 6.084 0 0 1 -1.872.3zm0-10.147a4.073 4.073 0 1 0 3.873 2.816 4.079 4.079 0 0 0 -3.868-2.816z"/><path d="m37 42.292a5.292 5.292 0 1 1 5.292-5.292 5.3 5.3 0 0 1 -5.292 5.292zm0-8.584a3.292 3.292 0 1 0 3.292 3.292 3.3 3.3 0 0 0 -3.292-3.292z"/></Icon>
                <Title>{ selectedEvent.title }</Title>
                <EventDate>{ moment(selectedEvent.start).format("MM/DD/YYYY hh:mm A") } </EventDate>
                <Form>
                    <Field
                        name="serviceType"
                        component={renderServiceSelector}
                    />
                </Form>
            </Header>
            <Button type="button" onClick={handleSubmit}>Schedule</Button>
        </Container>
    )    
    
    return null
}

const mapStateToProps = state => {
    return {
        selectedEvent: state.calendar.selectedEvent,
        scheduleProject: state.form.scheduleProject ? state.form.scheduleProject.values : null
    }
}


const connected = connect(mapStateToProps, { setModalView, createProject })(CalendarEvent)


export default reduxForm({
    form: 'scheduleProject',
    setModalView
})(connected)