import {
    CREATE_ACTION,
    FETCH_ACTIONS,
    NEXT_HISTORY_ACTION_PAGE
} from '../actions/types'

const initialState = {
    actions: [],
    page: 1
}

const historyReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_ACTION:
            return { ...state, actions: [ ...state.actions, action.payload ] }
        case FETCH_ACTIONS:
            return { ...state, actions: action.payload.actions, count: action.payload.count }
        case NEXT_HISTORY_ACTION_PAGE:
            return { ...state, page: state.page + 1 }
        default:
            return state
    }
}

export default historyReducer