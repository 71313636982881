/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'

import ProjectType from './pages/Type'
import ProjectName from './pages/Name'
import PhotoQuantity from './pages/Quantity'
import AnchorCount from './pages/AnchorCount'
import AnchorCheck from './pages/AnchorCheck'
import ToolsCheck from './pages/ToolsCheck'
import CullCount from './pages/CullCount'
import JPGCheck from './pages/JPGCheck'
import ProfilesCheck from './pages/ProfilesCheck'
import SmartPreviewCheck from './pages/SmartPreviewCheck'
import CroppingNotes from './pages/CroppingNotes'
import ProjectNotes from './pages/ProjectNotes'
import RushDeliveryCheck from './pages/RushDeliveryCheck'
import LetsGo from './pages/LetsGo'

import { 
    createProject, 
    setDrawerView, 
    updateUser 
} from '../../../actions'

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

class NewProjectForm extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.state = { page: 1, show: false }
  }

  componentDidMount() {
      setTimeout(() => this.setState({ show: true}), 1)
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 })
  }

  onSubmit = async () => {
      const newProject = this.props.newProject.values
      newProject.clientUserName = this.props.currentUser.fullName
      newProject.clientBio = this.props.currentUser.bio

      try {
          await this.props.createProject(newProject, this.props.currentUser)
          reset()
          this.props.setDrawerView('project')
          await this.props.updateUser(this.props.currentUser.id, { serviceLog: [...this.props.currentUser.serviceLog, this.props.newProject.service] })
      } catch(e) {
          console.log(e)
      }
  }

  buildWizzard() {
    const projectType = (this.props.newProject && this.props.newProject.values) ? this.props.newProject.values.service : null
    const { page } = this.state

    switch(projectType) {
        case 'editing':
            return (
                <Fragment>
                    {page === 2 && <ProjectName onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 3 && <PhotoQuantity odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 4 && <AnchorCount onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 5 && <SmartPreviewCheck odd onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 6 && <AnchorCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 7 && <ProfilesCheck odd onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 8 && <ToolsCheck optional={true} onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 9 && <CroppingNotes odd onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 10 && <ProjectNotes onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 11 && <RushDeliveryCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 12 && <LetsGo onSubmit={this.onSubmit} previousPage={this.previousPage} />}
                </Fragment>
            )
        case 'culling':
            return (
                <Fragment>
                    {page === 2 && <ProjectName onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 3 && <PhotoQuantity odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 4 && <CullCount onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 5 && <ProjectNotes onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 6 && <RushDeliveryCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 7 && <LetsGo onSubmit={this.onSubmit} previousPage={this.previousPage} />}
                </Fragment>
            )
        case 'editing and culling':
            return (
                <Fragment>
                    {page === 2 && <ProjectName onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 3 && <PhotoQuantity odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 4 && <AnchorCount onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 5 && <CullCount odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 6 && <SmartPreviewCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 7 && <AnchorCheck odd onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 8 && <ProfilesCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 9 && <ToolsCheck odd optional={true} onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 10 && <CroppingNotes onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 11 && <ProjectNotes odd onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 12 && <RushDeliveryCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 13 && <LetsGo onSubmit={this.onSubmit} previousPage={this.previousPage} />}
                </Fragment>
            )
        case 'retouching':
            return (
                <Fragment>
                    {page === 2 && <ProjectName onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 3 && <PhotoQuantity odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 4 && <JPGCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 5 && <ProjectNotes odd onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 6 && <RushDeliveryCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 7 && <LetsGo onSubmit={this.onSubmit} previousPage={this.previousPage} />}
                </Fragment>
            )
        case `batch.ai tools/cropping`:

        default:
            return (
                <>
                    {page === 2 && <ProjectName onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 3 && <PhotoQuantity odd onSubmit={this.nextPage} previousPage={this.previousPage}/>}
                    {page === 4 && <SmartPreviewCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 5 && <ToolsCheck odd optional={false} onSubmit={this.nextPage} previousPage={this.previousPage} />}
                    {page === 6 && <ProjectNotes odd onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 8 && <RushDeliveryCheck onSubmit={this.nextPage} previousPage={this.previousPage} />}  
                    {page === 9 && <LetsGo onSubmit={this.onSubmit} previousPage={this.previousPage} />}
                </>
            )
    }
  }

  render() {
    const { page, show } = this.state
    return (
      <Container className='Sidebar' show={show}>
          {page === 1 && <ProjectType odd onSubmit={this.nextPage} />}
          { this.buildWizzard() }
      </Container>
    )
  }
}

const mapStateToProps = state => {
    return {
        newProject: (state.form && state.form.newProject) || null,
        currentUser: state.user.currentUser
    }
}

const connected = connect(mapStateToProps, { createProject, setDrawerView, updateUser })(NewProjectForm)

export default reduxForm({
    form: 'newProject',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(connected)
