import {
    CREATE_PROJECT,
    FETCH_PROJECTS,
    SET_LOADING_PROJECTS,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    UPDATE_DATE,
    ESTIMATE_DEADLINE,
    SELECT_PROJECT,
    SET_UPLOAD_PROGRESS,
    SET_UPLOAD_TYPE,
    CREATE_COMMENT,
    SET_EDITOR_NOTES,
    SET_UPLOAD,
    SET_SUBMITTING_NOTES,
    SET_SUBMISSION_NOTES,
    SET_UPLOAD_REMAINING_TIME,
    SET_UPLOAD_START,
    NEXT_PROJECT_PAGE,
    SORT_PROJECTS_BY,
    CHANGE_PROJECT_SEARCH_TERM,
    CHANGE_PROJECT_STATE_FILTER,
    CLEAR_PROJECTS,
    FETCH_PROJECT,
    SET_FETCH_INTERVAL,
    SET_PAGE,
    ADD_FETCH_INTERVAL,
    CLEAR_FETCH_INTERVALS,
    UPDATE_SEARCH_TERM,
    SIGN_OUT
} from '../actions/types'

const initialState = { 
    loading: false,
    selectedProject: null, 
    fetchInterval: null,
    projects: [], 
    individualFetchIntervals: [],
    searchTerm: null,
    sort_by: 'editorDeadline',
    sort_direction: 'desc',
    page: 1,
    count: 0,
    uploadProgress: null,
    uploadType: null,
    date: new Date().toISOString(), 
    editorNotes: '',
    upload: null,
    submittingNotes: false,
    submissionNotes: null,
    uploadRemainingTime: null,
    uploadStart: null,
    stateFilter: null,
    estimatedDeadline: null
}

const projectReducer = (state = initialState, action) => {

    let filteredProjects
    const individualFetchIntervals = state.individualFetchIntervals || []

    switch(action.type) {
        case FETCH_PROJECTS:
            return { ...state, ...action.payload }
        case FETCH_PROJECT:
            const projectsInState = [ ...state.projects ]
            const allProjects = state.projects.map(project => project._id)
            const fetchedProjectIndex = allProjects.indexOf(action.payload._id)
            if(fetchedProjectIndex !== -1) {
                projectsInState[fetchedProjectIndex] = action.payload
                return { ...state, projects: projectsInState }
            }
            break
        case SELECT_PROJECT:
            return { ...state, selectedProject: action.payload }
        case UPDATE_PROJECT:
            const currentStateProjects = [ ...state.projects ]
            const projectIndex = currentStateProjects.map(project => project._id).indexOf(action.payload._id)
            currentStateProjects[projectIndex] = action.payload
            const updatedState = { ...state, projects: currentStateProjects }
            let isSelectedProject = state.selectedProject && state.selectedProject._id === action.payload._id
            if (isSelectedProject) updatedState.selectedProject = action.payload
            return updatedState
        case CREATE_COMMENT:
            return { ...state, selectedProject: action.payload }
        case CREATE_PROJECT:
            return { ...state, selectedProject: action.payload, projects: [action.payload, ...state.projects] }
        case DELETE_PROJECT:
            filteredProjects = state.projects.filter(project => project._id !== action.payload)
            return {...state, selectedProject: null, projects: filteredProjects }
        case UPDATE_DATE:
            return { ...state, date: action.payload, loadingProjects: true }
        case ESTIMATE_DEADLINE:
            return { ...state, estimatedDeadline: action.payload }
        case SET_LOADING_PROJECTS:
            return { ...state, loadingProjects: action.payload }
        case SET_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload }
        case SET_UPLOAD_TYPE:
            return {...state, uploadType: action.payload }
        case SET_EDITOR_NOTES:
            return {...state, editorNotes: action.payload }
        case SET_UPLOAD:
            return {...state, upload: action.payload }
        case SET_SUBMITTING_NOTES:
            return { ...state, submittingNotes: action.payload }
        case SET_SUBMISSION_NOTES:
            return { ...state, submissionNotes: action.payload }
        case SET_UPLOAD_REMAINING_TIME:
            return { ...state, uploadRemainingTime: action.payload }
        case SET_UPLOAD_START:
            return { ...state, uploadStart: action.payload }
        case NEXT_PROJECT_PAGE:
            return { ...state, page: state.page + 1 }
        case SORT_PROJECTS_BY:
            return { ...state, sort_by: action.payload, projects: [], page: 1, count: 0 }
        case CHANGE_PROJECT_SEARCH_TERM:
            return { ...state, projects: [], page: 1, count: 0, searchTerm: action.payload }
        case CHANGE_PROJECT_STATE_FILTER:
            return { ...state, projects: [], page: 1, count: 0, stateFilter: action.payload }
        case CLEAR_PROJECTS:
            return { ...state, projects: [] }
        case SET_FETCH_INTERVAL:
            clearInterval(state.fetchInterval)
            return { ...state, fetchInterval: action.payload, projects: [] }
        case SET_PAGE:
            return { ...state, page: action.payload }
        case ADD_FETCH_INTERVAL:
            individualFetchIntervals.push(action.payload)
            return { ...state, individualFetchIntervals }
        case CLEAR_FETCH_INTERVALS:
            individualFetchIntervals && individualFetchIntervals.length > 0 && individualFetchIntervals.forEach(interval => {
                clearInterval(interval)
            })
            return { ...state, individualFetchIntervals: [] }
        case SIGN_OUT:
            clearInterval(state.fetchInterval)
            break
        default:
            return state
    }
}

export default projectReducer