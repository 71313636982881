import React from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components'

import generateInitials from '../../../lib/generateInitials'
import {
  updateProject,
  addNotification
} from '../../../actions'

import {
  Icon,
  Checkbox,
  Popup,
} from 'semantic-ui-react'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0;
  padding: 1px 0;
`
const StatusCheck = styled.div`
  display: flex;
  flex-direction: row;
`;

const Initials = styled.span`
  line-height: 17px;
`

const StatusChecks = ({ currentUser, updateProject, project }) => {

  const handleUpdateProject = async (event, projectId, update) => {
    event.stopPropagation()
    try {
      await updateProject(projectId, update)
    } catch (e) {
      addNotification(e)
    }
  }

  return (
    <Container>
      {['super', 'dev'].includes(currentUser.role) && (
        <StatusCheck>
          <Popup
            content='Logged in Quickbooks'
            trigger={<Icon name='dollar sign' />}
          />
          <Checkbox
            onChange={e =>
              handleUpdateProject(e, project._id, {
                loggedInQuickbooks: !project.loggedInQuickbooks
              })
            }
            checked={project.loggedInQuickbooks}
          />
        </StatusCheck>
      )}
      <StatusCheck>
        <Popup content='File integrity checked' trigger={<Icon name='bug' />} />
        <Checkbox
          onChange={e =>
            handleUpdateProject(e, project._id, {
              checkedForBugs: !project.checkedForBugs
            })
          }
          checked={project.checkedForBugs}
        />
      </StatusCheck>
      <StatusCheck>
        <Popup content='Reviewed' trigger={<Icon name='search' />} />
        {project.reviewed && (
          <Initials
            onClick={e =>
              handleUpdateProject(e, project._id, {
                reviewed: false,
                reviewedBy: null
              })
            }
          >
            {generateInitials(project.reviewedBy.fullName)}
          </Initials>
        )}
        {!project.reviewed && (
          <Checkbox
            onChange={e =>
              handleUpdateProject(e, project._id, {
                reviewed: true,
                reviewedBy: currentUser
              })
            }
            checked={project.reviewed}
          />
        )}
      </StatusCheck>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  }
}

export default connect(mapStateToProps, {
  updateProject,
})(StatusChecks)