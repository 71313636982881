import React, { useState } from 'react';
import styled from 'styled-components'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import PrivacyPolicy from './Privacy.pdf'

const DocumentContainer = styled.div`
    height: 600px;
    width: 100%;
    overflow-y: scroll;
`

const Privacy = () =>  {
    const [numPages, setNumPages] = useState(null)

    return (
        <DocumentContainer>
            <Document
                file={PrivacyPolicy}
                onLoadSuccess={({numPages}) => setNumPages(numPages)}
                options={{
                    cMapUrl: 'cmaps/',
                    cMapPacked: true
                }}
                
            >
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.2}
                            />
                        ),
                    )
                }
            </Document>
        </DocumentContainer>
    )  
}

export default Privacy

