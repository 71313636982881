import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import validate from '../validate'
import { blue, white } from '../../../Shared/colors'

const Page = styled.div`
  background: ${props => props.odd ? blue : white};
  height: 100%;
  width: 100%;
  color: ${props => props.odd ? white : blue};
  padding: 20px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 200ms ease-in;
`

const Title = styled.p`
  font-size: 1.8rem;
  font-family: Krub;
  font-weight: 700;
  margin-bottom: 10px;
`

const CheckText = styled.p`
  text-align: center;
  font-size: .9rem;
  font-family: Krub;
  margin-bottom: 20px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  & input {
    color: ${props => props.odd ? white : blue};
    height: 30px;
    border: none;
    width: 250px;
    font-size: 1.2rem;
    text-align: center;
    background: ${props => props.odd ? blue : white};
    border-bottom: 2px solid ${props => props.odd ? white : blue};
  }

  & input:focus {
    outline: none;
    caret-color: ${props => props.odd ? blue : white};
  }

  & input::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
  
  & input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
`

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  margin-left: ${props => props.next && 'auto'};
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
`

const Wipe = styled.div`
  position: absolute;
  width: ${props => props.grow ? '1200px' : '0'};
  height: ${props => props.grow ? '1200px' : '0'};
  border-radius: 50%;
  background: ${props => props.odd ? white : blue};
  transition: height 350ms ease-in, width 350ms ease-in;
`

const renderField = ({ input }) => <input {...input} type='checkbox' />


const JPGCheckPage = ({ handleSubmit, previousPage, jpgCheck, odd }) => {

  const [grow, setGrow] = useState(false)
  const [show, setShow] = useState(false)
  
  useEffect(() => {
    setTimeout(() => setShow(true), 1)
  }, [])

  const handleNext = e => {
    e.preventDefault()
    setGrow(true)
    setTimeout(handleSubmit, 350)
  }

  return (
    <Page odd={odd}>
      <Container show={show}>
        <Title>JPEG Check</Title>
        <CheckText>Check that all images to be retouched are high-res JPEGs as opposed to Lightroom catalogs. Only zip the images you would like us to use for the retouching project.</CheckText>
        <Form odd={odd} onSubmit={handleSubmit}>
          <Field
            name="jpgCheck"
            component={renderField}
          />

          <ButtonGroup>
            <Button odd={odd} type="button" className="previous" onClick={previousPage}>Back</Button>
            <Button odd={odd} next hidden={!jpgCheck} onClick={handleNext} className="next">Next</Button>
          </ButtonGroup>
        </Form>
        <Wipe odd={odd} grow={grow} />
      </Container>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    jpgCheck: state.form.newProject.values ? state.form.newProject.values.jpgCheck : false
  }
}

const connected = connect(mapStateToProps)(JPGCheckPage)

export default reduxForm({
    form: 'newProject', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
  })(connected)