import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { connect } from 'react-redux'
import {
  selectProject,
  setDrawerView,
  setShowOverlay,
  fetchProjects,
  changeProjectStateFilter,
  redirect
} from '../../../actions'

import ProjectTable from './ProjectTable'

import {
  Icon,
  Header,
  Input,
  Select
} from 'semantic-ui-react'

import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  width: 100%;
  font-family: Roboto;
  margin-top: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: ${props => props.fadeIn ? '1' : '0'};
  transition: left .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .4s ease-out;
`

const ProjectsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;

  & h5 {
    margin-top: 0 !important;
  }

  & > div {
    width: 100%;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    text-align: center;
  }
`

const StyledTitle = styled(Header)`
  display: inline;
  margin-bottom: 0;
`

const StyledIcon = styled(Icon)`
  margin-left: 10px !important;
  cursor: pointer;
`

const StyledSearch = styled(Input)`
  display: flex !important;
  margin: auto;
  width: ${props => props.mobile ? '100%' : '350px'} !important;
`

const StyledSelect = styled(Select)`
  display: flex !important;
  margin: auto;
  margin-bottom: ${props => props.mobile && '10px'};
  width: ${props => props.mobile ? '100%' : '350px'} !important;

  & * {
    line-height: 20px;
  }
`

const ProjectView = ({
  currentUser,
  projects,
  selectProject,
  changeProjectStateFilter,
  showMenu,
  setDrawerView,
  setShowOverlay,
  stateFilter,
  count,
  mobile
}) => {
  const [fadeIn, updateFadeIn] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)

  const { projectId } = useParams()

  useEffect(() => {
    redirect(null)
    updateFadeIn(true)
    if (projectId) {
      (async () => {
        await selectProject(projectId)
        setDrawerView('project')
        setShowOverlay(true)
      })()
    }
  }, [projectId])


  const handeUpdateStateFilter = async value => {
    await changeProjectStateFilter(value)
  }

  const projectStates = [
    { key: 'All', value: null, text: 'All'},
    {
      key: 'Awaiting Client Upload',
      value: 1,
      text: 'Awaiting Client Upload'
    },
    { key: 'Uploading', value: 2, text: 'Uploading' },
    { key: 'New', value: 3, text: 'New' },
    { key: 'In Editing', value: 4, text: 'In Editing' },
    { key: 'In Review', value: 6, text: 'In Review' },
    { key: 'Complete', value: 7, text: 'Complete' }
  ]

  return (
    <Container showMenu={showMenu} fadeIn={fadeIn} mobile={mobile}>
      <Title>
        <ProjectsHeader>
          <StyledTitle as='h2'>
            Projects
            {currentUser.role !== 'editor' && (
              <StyledIcon
                name='plus'
                onClick={() => setDrawerView('newProject')}
              />
            )}
          </StyledTitle>
          <Header as='h5'>
            {count} matching project{count !== 1 ? 's' : ''}
          </Header>
          
        </ProjectsHeader>
        <StyledSearch
            size='large'
            icon='search'
            placeholder='Search...'
            value={searchTerm || ''}
            onChange={e => setSearchTerm(e.target.value)}
            mobile={mobile}
          />
          <StyledSelect
            placeholder='Project Status'
            value={stateFilter || null}
            options={projectStates}
            onChange={(e, val) => handeUpdateStateFilter(val.value)}
            mobile={mobile}
          />
      </Title>
      <ProjectTable searchTerm={searchTerm} projects={projects} mobile={mobile}/>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    showMenu: state.view.showMenu,
    currentUser: state.user.currentUser,
    mobile: state.index.mobile,
    projects: state.project.projects,
    stateFilter: state.project.stateFilter,
    sort_by: state.project.sort_by,
    sort_direction: state.project.sort_direction,
    page: state.project.page,
    count: state.project.count,
    fetchInterval: state.project.fetchInterval
  }
}

export default connect(mapStateToProps, {
  selectProject,
  setDrawerView,
  setShowOverlay,
  fetchProjects,
  changeProjectStateFilter
})(ProjectView)
