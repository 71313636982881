import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import Loader from "react-loader-spinner";
import styled from 'styled-components'
import { blue } from '../Shared/colors'
import { setModalView, resetPassword, setAuthLoading, addNotification } from '../../actions'
import { Icon } from 'semantic-ui-react'
import LogoImage from '../../images/Logo.png'


const Header = styled.div`
    grid-row: 1 / 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`

const Logo = styled.img`
    height: 70px;
    align-self: center;
    margin-bottom: 20px;
`

const Title = styled.h3`
    margin: 0 auto 10px auto;
    text-transform: uppercase;
    font-size: 1.2rem;
    user-select: none;
`

const Body = styled.div`
    grid-row: 2 / 3;    
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
`

const Form = styled.form`
    width: 80%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-self: center;
    font-family: Roboto;
`

const InputContainer = styled.div`
    background: #ddd;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    padding: 0 5px;
    & > div {
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
    display: block !important;
    width: 35px !important;
    height: 18px !important;
    opacity: ${props => props.showPass ? 1 : .5} !important;
`

const TogglePasswordVisibility = styled(StyledIcon)`
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
`

const Input = styled.input`
    outline: none;
    border: none;
    background: #ddd;
    font-weight: 700;
    width: 100%;
    height: 100%;
`

const Submit = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: ${blue};
    color: #fff;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
`

const Footer = styled.div`
    grid-row: 3 / 4;    
    display: flex;
    flex-direction: column;
    user-select: none;
    color: #999;
    width: 80%;
    margin-top: 20px;
    text-align: center;
    justify-self: center;
    font-size: .9rem;
`

const Link = styled.a`
    color: #999;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`

const ErrorMessage = styled.p`
    color: red;
    font-size: .7rem;
    user-select: none;
    position: relative;
    bottom: 15px;
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`



const renderInput = ({ input, meta, placeholder, type}) => {
    return (
        <div>
            <Input {...input}  type={type} placeholder={placeholder}  autoComplete="off"/>
            { renderError(meta)}
        </div>
    )
}

const renderError = props => {
    const { error, touched, active, submitFailed } = props
    if ((touched && error && !active) || submitFailed) {
        return <ErrorMessage>{error}</ErrorMessage>
    } else return <div />
}

const ResetPassword = ({ currentUser, resetPassword, handleSubmit, setAuthLoading, auth_loading, setModalView, addNotification, redirectTo }) =>  {
    
    const [showPass, setShowPass] = useState(false)
    const [token, setToken] = useState(null)

    useEffect(() => {
        let search = window.location.search
        const params = new URLSearchParams(search)
        setToken(params.get('token'))
    }, [])

    const onSubmit = async formValues => {
        setAuthLoading(true)
        try {
            await resetPassword({...formValues, token })
        } catch (e) {
            addNotification({ type: 'error', message: e.message })
        }
    }
    
    if (auth_loading) {
        return (
            <Loading>
                <Loader
                    type="Bars"
                    color={blue}
                    height={50}
                    width={50}
                />
            </Loading>
        )
    }

    return (
        <Fragment>
                <Header>
                <Logo src={LogoImage} />
            </Header>
            <Body>
                <Title>Reset Your Password</Title>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <StyledIcon name='shield' />
                        <Field name="password" type={showPass ? 'text' : 'password'} component={renderInput} placeholder='Password'/>
                        <TogglePasswordVisibility showPass={showPass} onClick={() => setShowPass(!showPass)} name='eye' />
                    </InputContainer>
                    <InputContainer>
                        <StyledIcon name='shield' />
                        <Field name="verify" type={showPass ? 'text' : 'password'} component={renderInput} placeholder='Confirm Password'/>
                        <TogglePasswordVisibility showPass={showPass} onClick={() => setShowPass(!showPass)} name='eye' />
                    </InputContainer>
                    <Submit type='submit'>Reset</Submit>
                </Form>
            </Body> 
            <Footer>
                <Link onClick={() => setModalView('signIn')} >Return to Sign In</Link>
            </Footer>
        </Fragment>
    )  
}

const validate = formValues => {
    const errors = {}

    if (!formValues.password) {
        errors.password = 'Password Required'
    }


    if (!formValues.verify) {
        errors.verify = 'Please Verify Password'
    }

    if (formValues.password && formValues.password.length < 8) {
        errors.password = 'Passwords Must > 7 Characters'
    }

    if (formValues.verify !== formValues.password) {
        errors.verify = 'Passwords Do Not Match'
    }
    return errors
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        error: state.user.error,
        auth_loading: state.user.auth_loading,
        form: state.form.signUpForm,
        redirectTo: state.user.redirectTo
    }
}

const connected = connect(mapStateToProps, { setModalView, resetPassword, setAuthLoading, addNotification, })(ResetPassword)

export default reduxForm({
    form: 'signUpForm',
    validate,
    setModalView
})(connected)

