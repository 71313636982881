import React, { useState, useRef } from 'react';
import { connect } from 'react-redux'
import { updateUser, signContract, setError, setAuthLoading } from '../../../actions'
import SignatureCanvas from 'react-signature-canvas'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ip from 'ip'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import styled from 'styled-components'
import Loader from "react-loader-spinner";
import { blue } from '../../Shared/colors'
import ClientContract from './TOS_Client.pdf'
import ContractorContract from './ICA_SH.pdf'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
`

const Title = styled.h2`
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 15px;
`

const DocumentContainer = styled.div`
    height: 400px;
    width: auto;
    overflow-y: scroll;
    border: 5px solid #eee;
    padding: 5px;
    margin-bottom: 20px;
`
const SignatureContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 350px;
    height: 100px;
    justify-content: center;
    align-items: center;
`   

const PleaseSignHere = styled.h3`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    font-size: 1.5rem;
    margin: 0 5px;
    color: #000;
    text-align: center;
`

const SignatureBox = styled.div`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
` 

const Notification = styled.p`
    margin 5px 60px 20px 60px;
    font-size: .7rem;
    text-align: center;
`

const ButtonGroup = styled.div`
    display: flex;
    gap: 40px;
    flex-direction: row;
    margin-top: 5px;
`

const Button = styled.button`
    width: 100px;
    padding: 10px;
    border-radius: 5px;
    margin: 0 auto;
    background: ${blue};
    color: #fff;
    border: none;
    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`

const Input = styled.input`
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: .8rem;
    height: 30px;
    width: 250px;
    padding: 5px;
    margin-top: 5px;
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`


const ToS = ({ onSubmit, currentUser, updateUser, signContract, setError, auth_loading, setAuthLoading }) => {
    const [numPages, setNumPages] = useState(null)
    const [signed, setSigned] = useState(false)
    const [ITS, setITS] = useState("")
    const [printedName, setPrintedName] = useState("")

    const sigRef = useRef(null)

    const handleClear = e => {
        e.preventDefault()
        sigRef.current.clear()
        setSigned(false)
        setITS("")
        setPrintedName("")
    }

    const handleNext = async e => {
        e.preventDefault()
        const update = { tosAccepted: true, onboard: true, completedOnboarding: true }

        if (ITS !== '') {
            update.ITS = ITS
        }

        try {
            const img = sigRef.current.toDataURL('image/png')
            await signContract(currentUser.role, printedName, ITS, img, ip.address())
            if (currentUser.role === 'client')
                return onSubmit()
            return await updateUser(currentUser._id, update, true)
        } catch (e) {
            console.log(e)
            setError('Something went wrong.')
        }
    }
    
    if (auth_loading) {
        return (
            <Loading>
                <Loader
                    type="Bars"
                    color={blue}
                    height={50}
                    width={50}
                />
            </Loading>
        )
    } else return (
        <Form>
            <Title>Terms of Service Agreement</Title>
            <Notification>Upon signing and creation of account you will receive a signed copy of this agreement emailed to you at your account email address and it will also be made available to you via your online profile.</Notification>
            <DocumentContainer>
                <Document
                    file={currentUser.role === 'client' ? ClientContract : ContractorContract }
                    onLoadSuccess={({numPages}) => setNumPages(numPages)}
                    options={{
                        cMapUrl: 'cmaps/',
                        cMapPacked: true,
                    }}
                >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )
                    }
                </Document>
            </DocumentContainer>
            <SignatureContainer>
                <PleaseSignHere>Please sign here</PleaseSignHere>
                <SignatureBox>
                    <SignatureCanvas
                        ref={sigRef}
                        penColor='green'
                        backgroundColor='rgba(221, 221, 221, .9)'
                        onEnd={() => setSigned(true)}
                        canvasProps={{
                            width: 350,
                            height: 100,
                            className: 'igCanvas',
                        }}
                    />
                </SignatureBox>
            </SignatureContainer>
            <Input type='text' value={printedName} placeholder="Full Name" onChange={e => setPrintedName(e.target.value)}/>
            { currentUser.role === 'client' && <Input type='text' value={ITS} placeholder="ITS (If Applicable)" onChange={e => setITS(e.target.value)}/> }
            <ButtonGroup>
                <Button onClick={handleClear}>Clear</Button>
                { signed && printedName.length >= 2 && <Button next onClick={handleNext} className="next">Next</Button>}
            </ButtonGroup>
        </Form>
    )
}


const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        signed: state.form.onboard && state.form.onboard.values ? state.form.onboard.values.signed : null,
        auth_loading: state.user.auth_loading
    }
}

export default connect(mapStateToProps, { updateUser, signContract, setError, setAuthLoading })(ToS)