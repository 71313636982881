import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import validate from '../validate'
import { blue, white } from '../../../Shared/colors'

const Page = styled.div`
  background: ${props => props.odd ? blue : white};
  color: ${props => props.odd ? white : blue};
  height: 100%;
  width: 100%;
  padding: 20px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 200ms ease-in;
`

const Title = styled.p`
  font-size: 1.6rem;
  font-family: Krub;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  & input {
    height: 30px;
    border: none;
    width: 250px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 2px solid ${props => props.odd ? white : blue};
  }

  & input:focus {
    outline: none;
    caret-color: ${props => props.odd ? blue : white};
  }
`

const ButtonGroup = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  margin-left: ${props => props.next && 'auto'};
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  opacity: ${props => props.show ? 1 : 0 };
  transition: opacity 500ms ease-out;
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const Placeholder = styled.div`
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
`

const Wipe = styled.div`
  position: absolute;
  width: ${props => props.grow ? '1200px' : '0'};
  height: ${props => props.grow ? '1200px' : '0'};
  border-radius: 50%;
  background: ${props => props.odd ? white : blue};
  transition: height 350ms ease-in, width 350ms ease-in;
`

const renderField = ({ input }) => <input autoFocus {...input} key='key' type='text' />

const ProjectName = ({ handleSubmit, previousPage, name, odd, reset }) => {

  const [grow, setGrow] = useState(false)
  const [show, setShow] = useState(false)
  const [showNext, setShowNext] = useState(false)


  useEffect(() => {
    setTimeout(() => setShow(true), 1)
  }, [])

  useEffect(() => {
    
    if (!name || name.length < 3) {
      setShowNext(false)
    } else {
      setTimeout(() => setShowNext(true), 1)
    }

  }, [name])

  const handleNext = e => {
    e.preventDefault()
    setGrow(true)
    setTimeout(handleSubmit, 350)
  }

  const handleBack = () => {
    reset()
    previousPage()
  }

  const renderNext = () => {
    return <Button odd={odd} next show={showNext} onClick={handleNext} className="next">Next</Button>
  }
  
  return (
    <Page odd={odd}>
      <Container show={show}>
        <Title>What should we call your project?</Title>
        <Form onSubmit={handleSubmit} odd={odd}>
          <Field
            name="name"
            type="text"
            component={renderField}
          />
        </Form>
        <ButtonGroup>
            <Button show odd={odd} stype="button" className="previous" onClick={handleBack}>Back</Button>
            { name && name.length >= 3 ? renderNext() : <Placeholder /> } 
          </ButtonGroup>
        <Wipe odd={odd} grow={grow}/>
      </Container>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    name: state.form.newProject.values ? state.form.newProject.values.name : null
  }
}

const connected = connect(mapStateToProps)(ProjectName)

export default reduxForm({
    form: 'newProject', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
  })(connected)