import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import projectReducer from './projectReducer'
import commentReducer from './commentReducer'
import viewReducer from './viewReducer'
import userReducer from './userReducer'
import googleReducer from "./googleReducer"
import calendarReducer from './calendarReducer'
import paymentReducer from './paymentReducer'
import notificationReducer from './notificationReducer'
import historyReducer from './historyReducer'

import {
    ERROR,
    SET_MOBILE,
    SET_REFRESH_INTERVAL
} from "../actions/types";

const initialState = { error: null, mobile: false, maintenance: false, refreshInterval: null }

const indexReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MOBILE:
            return { ...state, mobile: action.payload }
        case ERROR:
            return { ...state, error: action.payload }
        case SET_REFRESH_INTERVAL:
            clearInterval(state.refreshInterval)
            if (action.payload !== null)
                return { ...state, refreshInterval: action.payload }
            return state
        default:
            return state
    }
}

export default combineReducers({
    index: indexReducer,
    project: projectReducer,
    comment: commentReducer,
    view: viewReducer,
    user: userReducer,
    form: formReducer,
    google: googleReducer,
    calendar: calendarReducer,
    payment: paymentReducer,
    notification: notificationReducer,
    history: historyReducer
})