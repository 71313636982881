import axios from 'axios'
import { reset } from 'redux-form'

import {
    SET_AUTH_LOADING,
    GET_CURRENT_USER,
    SIGN_UP,
    SIGN_IN,
    SIGN_OUT,
    FETCH_USERS,
    UPDATE_USER,
    DELETE_USER,
    SELECT_USER,
    CHANGE_USER_ROLE,
    CHANGE_USER_SEARCH_TERM,
    SET_MODAL_VIEW,
    SET_SHOW_OVERLAY,
    NEXT_PAGE,
    SORT_USERS_BY,
    UPDATE_USER_AVAILABILITY,
    FETCH_USER_AVAILABILITY,
    FETCH_AVAILABLE_EDITORS,
    LOAD_USER,
    REDIRECT,
    CLEAR_USERS,
    ADD_NOTIFICATION
} from './types'

export const getCurrentUser = () => async dispatch => {

    try {   
        const response = await axios.get('/api/users/currentuser')
        dispatch({ type: SET_AUTH_LOADING, payload: false})

        if (response.status === 200 || 304) {
            const { currentUser } = response.data

            const payload = {
                isSignedIn: Boolean(currentUser),
                currentUser: currentUser || null,
                selectedUser: currentUser || null
            }

            if (window.location.pathname === '/dashboard/password-reset')
                dispatch({ type: SET_MODAL_VIEW, payload: 'reset' })
            else if (!currentUser)
                if (window.location.pathname === '/signup/editors')
                    dispatch({ type: SET_MODAL_VIEW, payload: 'signUpEditors' })
                else
                    dispatch({ type: SET_MODAL_VIEW, payload: 'signIn' })
            else if (!currentUser.completedOnboarding)
                dispatch({ type: SET_MODAL_VIEW, payload: 'onboard' })
            else 
                dispatch({ type: SET_SHOW_OVERLAY, payload: false })
            
            dispatch({
                type: GET_CURRENT_USER,
                payload
            })
        } else {

            dispatch({ 
                type: SIGN_OUT,
                payload: { 
                    isSignedIn: false, 
                    currentUser: null, 
                    selectedUser: null 
                }
            })
    
            dispatch({ type: SET_SHOW_OVERLAY, payload: true })
            dispatch({ type: SET_MODAL_VIEW, payload: 'signIn' })
        }
    } catch (e) {
        dispatch({ 
            type: SIGN_OUT,
            payload: { 
                isSignedIn: false, 
                currentUser: null, 
                selectedUser: null 
            }
        })

        dispatch({ type: SET_SHOW_OVERLAY, payload: true })
        dispatch({ type: SET_MODAL_VIEW, payload: 'signIn' })
    }
}

export const signUp = formValues => async dispatch => {
    let response 

    try {
        response = await axios.post('/api/users/signup', formValues)
    } catch (e) {
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        throw new Error(e.response ? e.response.data.errors[0].message : e.message)
    }
        
    if (response.status === 201) {
        const currentUser = response.data

        dispatch(
            { 
                type: SIGN_UP, 
                payload: { 
                    isSignedIn: true, 
                    currentUser,
                    selectedUser: currentUser
                }
            }
        )
            
        const payload = 'onboard'
        dispatch({ type: SET_MODAL_VIEW, payload })
    } else {
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        throw new Error(response.data.errors[0].message)
    } 

    dispatch({ type: SET_AUTH_LOADING, payload: false})
}

export const signIn = formValues => async dispatch => {
    let response
    
    try {
        response = await axios.post('/api/users/signin', formValues)
    } catch (e) {
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        dispatch(reset('signInForm'))
        throw new Error(e.response.data.errors[0].message)
    }

    if (response.status === 200) {
        const currentUser = response.data
    
        dispatch({
            type: SIGN_IN,
            payload: {
                isSignedIn: true, 
                currentUser,
                selectedUser: currentUser
            }
        })

        if (currentUser.role === 'client' && !currentUser.completedOnboarding) {
            dispatch({ type: SET_MODAL_VIEW, payload: 'onboard' })
        } else {
            dispatch({ type: SET_MODAL_VIEW, payload: null })
            dispatch({ type: SET_SHOW_OVERLAY, payload: false })
        }

        if (currentUser.role === 'client' && !currentUser.anchorPositionConfirmation) {
            return dispatch({ type: ADD_NOTIFICATION, 
                payload: { 
                    type: 'info', 
                    message: 'Moving forward we are asking our photographers to please position your anchors at the beginning of each new set to help us best understand your direction of the gallery and for our ability to deliver the best cohesiveness possible! Thank you so much! :)',
                    noClear: true,
                    update: {anchorPositionConfirmation: true }
                } 
            })
        }

    } else {
        throw new Error('Server error. Please try again.')
    }
   
    dispatch({ type: SET_AUTH_LOADING, payload: false})
}

export const signOut = () => async dispatch => {

    await axios.post('/api/users/signout')
    window.location.replace(process.env.REACT_APP_HOST + "/dashboard");
}

export const signContract = (role, printedName, ITS, img, ip) => async dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true })
    
    try {
        const response = await axios.post('/api/users/signContract', { role, printedName, ITS, img, ip })
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        dispatch({ type: UPDATE_USER, payload: response.data })
    } catch (e) {
        throw new Error(e)
    }
}

export const setAuthLoading = isLoading => async dispatch => {
    return dispatch({ type: SET_AUTH_LOADING, payload: isLoading})
}

export const fetchUsers = (role, page, sortBy, sortDirection, searchTerm, pageChange) => async dispatch => {    
    let url = `/api/users?role=${role}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}`
    if (searchTerm) url += `&searchTerm=${searchTerm}`

    try {
        const response = await axios.get(url)
        dispatch({ type: FETCH_USERS, payload: { ...response.data, pageChange }})
    } catch(e) {
        throw new Error(e)
    }
}

export const fetchAvailableEditors = (startDate, stopDate) => async dispatch => {
    let url = `/api/users?role=editor&startDate=${startDate}&stopDate=${stopDate}&deactivated=false`
    try {
        const response = await axios.get(url)
        dispatch({ type: FETCH_AVAILABLE_EDITORS, payload: response.data })
    } catch (e) {
        throw new Error(e)
    }
}

export const changeUserRole = role => async dispatch => {
    return dispatch({ type: CHANGE_USER_ROLE, payload: role })
}

export const changeUserSearchTerm = term => async dispatch => {
    return dispatch({ type: CHANGE_USER_SEARCH_TERM, payload: term })
}

export const updateUser = (id, update) => async dispatch => {
    try {
        const response = await axios.put(`/api/users/${id}`, { update } )
        
        if (update.completedOnboarding) {
            dispatch({ type: SET_MODAL_VIEW, payload: null })
            dispatch({ type: SET_SHOW_OVERLAY, payload: false })
        }

        dispatch({ type: UPDATE_USER, payload: response.data})
    } catch (e) {
        throw new Error(e)
    }
}

export const deleteUser = id => async dispatch => {
    try {
        const response = await axios.delete(`/api/users/${id}`)
        dispatch({ type: DELETE_USER, payload: response.data})
    } catch (e) {
        throw new Error(e)
    }
}

export const selectUser = id => async dispatch => {
    if (id) {
        try {
            const response = await axios.get(`/api/users/${id}`)
            dispatch({ type: SELECT_USER, payload: response.data })
        } catch (e) {
            throw new Error(e)
        }
    } else {
        dispatch({ type: SELECT_USER, payload: null })
    }
}

export const sortUsersBy = type => async dispatch => {
    dispatch({ type: SORT_USERS_BY, payload: type })
}

export const nextPage = () => dispatch => {
    dispatch({ type: NEXT_PAGE })
}

export const uploadProfileImage = (file, id) => async dispatch => {
    let data = new FormData();
    data.append('file', file, file.name);

    const headers = {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }
    
    try {
        const response = await axios.post(`/api/users/uploadProfileImage/${id}`, data, headers)
        const payload = response.data
        return payload
    } catch(e) { 
        throw new Error(e)
    }
}

export const updateUserAvailability = (start, stop) => async dispatch => {
    try {
        const response = await axios.post(`/api/users/availability`, { start, stop })
        const payload = response.data
        dispatch({ type: UPDATE_USER_AVAILABILITY, payload })
    } catch (e) {
        throw new Error(e)
    }
}

export const fetchUserAvailibility = (month, year) => async dispatch => {
    try {
        const response = await axios.get(`/api/users/availability`)
        const payload = response.data
        dispatch({ type: FETCH_USER_AVAILABILITY, payload })
    } catch (e) {
        throw new Error(e)
    }
}

export const downloadUserCSV = async role => {
    try {
        const response = await axios.get(`/api/users/csv?role=${role}`)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${role}s.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        throw new Error(e)
    }
}

export const passwordReset = async email => {
    try {
        await axios.post(`/api/users/passwordReset`, { email })
    } catch (e) {
        throw new Error(e)
    }
}

export const resetPassword = vals => async dispatch => {
    let response 

    try {
        response = await axios.put('/api/users/passwordReset', vals)
    }
    catch (e) {
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        throw new Error(e.response.data.errors[0].message)
    }

    if (response.status === 200) {
        const currentUser = response.data
    
        dispatch({
            type: SIGN_IN,
            payload: {
                isSignedIn: true, 
                currentUser,
                selectedUser: currentUser
            }
        })

        if (currentUser.role === 'client' && !currentUser.completedOnboarding) {
            dispatch({ type: SET_MODAL_VIEW, payload: 'onboard' })
        } else {
            dispatch({ type: SET_MODAL_VIEW, payload: null })
            dispatch({ type: SET_SHOW_OVERLAY, payload: false })
        }

    } else {
        dispatch({ type: SET_AUTH_LOADING, payload: false})
        throw new Error('Server error. Please try again.')
    }
   
    dispatch({ type: SET_AUTH_LOADING, payload: false})
}

export const loadUser = currentUser => dispatch => {
    dispatch({ type: LOAD_USER, payload: currentUser })
}

export const redirect = path => dispatch => {
    dispatch({ type: REDIRECT, payload: path })
}

export const clearUsers = () => dispatch => {
    dispatch({ type: CLEAR_USERS })
}