import { connect } from 'react-redux'
import styled from 'styled-components'

import { 
    setModalView, 
    setDrawerView,
    setShowOverlay,
    selectUser
} from '../../actions'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    z-index: 999;
    display: ${props => props.showOverlay ? 'block' : 'none'};
    opacity: .7;
    transition: opacity 300ms ease-out;
`

const Overlay = ({ 
    drawerView, 
    modalView, 
    setDrawerView, 
    setModalView,
    setShowOverlay,
    submittingNotes,
    uploadProgress
}) => {
    
    const handleClick = () => {
        const modalViewOK = !['onboard', 'editorUpload', 'signIn', 'signUp'].includes(modalView)

        if (modalViewOK) {
            if (!submittingNotes && [0, null].includes(uploadProgress)) {
                setDrawerView(null)
                setModalView(null)
                setShowOverlay(false)
                selectUser(null)
            }
        }
    }

    return <Container 
                className='Overlay' 
                showOverlay={drawerView || modalView} 
                onClick={handleClick}
            />
}

function mapStateToProps(state) {
    return {
        drawerView: state.view.drawerView,
        modalView: state.view.modalView,
        submittingNotes: state.project.submittingNotes,
        uploadProgress: state.project.uploadProgress
    }
}

export default connect(mapStateToProps, { setDrawerView, setModalView, setShowOverlay, selectUser })(Overlay)