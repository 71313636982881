import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getCurrentUser,
  setMobile,
  addNotification,
  setModalView,
  setShowOverlay,
  redirect
} from '../../actions'
import MobileDetect from 'mobile-detect'
import 'semantic-ui-css/semantic.min.css'

import styled from 'styled-components'

import Header from './Header'
import Menu from './Menu'
import Overlay from './Overlay'
import NotificationContainer from './NotificationContainer'
import NotFound from './NotFound'

import Drawer from '../Drawer'
import Modal from '../Modal'

import Users from '../Views/Users'
import Projects from '../Views/Projects'
import Calendar from '../Views/Calendar'
import AuditTrail from '../Views/AuditTrail'
import Tutorials from '../Views/Tutorials'
import Guides from '../Views/Guides'
import SignUpEditors from '../Modal/SignUpEditors'
import { GoogleOAuthProvider } from '@react-oauth/google';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Footer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 120px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${props => props.visible ? 'block' : 'none'}
`

const footerWarning = {
  color: 'red',
  marginBottom: '5px'
}

const App = ({
  getCurrentUser,
  currentUser,
  modalView,
  drawerView,
  showOverlay,
  setMobile,
  addNotification,
  setModalView,
  setShowOverlay,
  view,
  mobile
}) => {

  useEffect(() => {
    async function init() {
      try {
        await getCurrentUser()
        const md = new MobileDetect(window.navigator.userAgent)
        setMobile(md.mobile() && !md.tablet())
      } catch (e) {
        addNotification(e)
      }
    }
    init()
  }, [getCurrentUser, setMobile, addNotification])

  const renderRouter = () => {
    return !currentUser ? <></> : (
      <>
          <Route exact path='/'>
            <Header />
            <Menu mobile={mobile}/>
            <Projects />
          </Route>
          <Route exact path='/projects/:projectId'>
            <Header />
            <Menu mobile={mobile}/>
            <Projects />
          </Route>
          <Route path='/users'>
            <Header />
            <Menu mobile={mobile}/>
            <Users />
          </Route>
          <Route path='/calendar'>
            <Header />
            <Menu mobile={mobile}/>
            <Calendar />
          </Route>
          <Route path='/history'>
            <Header />
            <Menu mobile={mobile}/>
            <AuditTrail />
          </Route>
          <Route path='/tutorials'>
            <Header />
            <Menu mobile={mobile}/>
            <Tutorials />
          </Route>
          <Route path='/guides'>
            <Header />
            <Menu mobile={mobile}/>
            <Guides />
          </Route>
          <Route path='*'>
            <Redirect to='/' />
          </Route>
      </>
    )
  }

  const DashboardFooter = ({ visible }) => (
    <Footer visible={visible}>
      <div style={footerWarning}>Please note that "the editors" at batch.ai is not affiliated with "the plugin" at batch.ai or any subscription service. Projects completed here are billed monthly as a pay per use service. Review your client agreement for more details.</div>
      All invoicing is done monthly through Quickbooks. Invoices will be sent on
      the last day of the month and due on the 5th of the following month
      totaling all monthly catalogs. Payment is accepted via card or ACH
      transfer. If you'd like your invoice sooner don't hesitate to reach out!
      <br />
      Hours of Operation: Mon-Fri, 9AM-5PM PST, excluding holidays.
      <br />
      Please email{' '}
      <a href='mailto:hello@batch.ai?subject=Expedite'>
        hello@batch.ai
      </a>{' '}
      if you would like to request an expedited catalog.
    </Footer>
  )

  const renderModal = () => {
    if (currentUser && ['reset', 'signIn', 'signUp'].includes(modalView)) {
        setModalView(null)
        setShowOverlay(false)
        return <Redirect to='/' />
    }

    return <Modal />
  }


  return (
    <>
      <Container>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BrowserRouter basename='/dashboard'>
            {renderRouter()}
            <Drawer showDrawer={drawerView} />
            { renderModal() }
          </BrowserRouter>
          <NotificationContainer />
          <Overlay showOverlay={showOverlay} />
          <DashboardFooter visible={ ['projects', null].includes(view) && currentUser && currentUser.role === 'client' }/>
        </GoogleOAuthProvider>
      </Container>
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    view: state.view.view,
    mobile: state.index.mobile,
    drawerView: state.view.drawerView,
    modalView: state.view.modalView,
    showOverlay: state.view.showOverlay,
    error: state.index.error
  }
}

export default connect(mapStateToProps, {
  getCurrentUser,
  setMobile,
  addNotification,
  setModalView,
  setShowOverlay
})(App)
