import React from 'react'
import styled from 'styled-components'
import Bubble from '../../Shared/Bubble'
import moment from 'moment'
import { Icon } from 'semantic-ui-react'

const Container = styled.div`
    display: grid;
    grid-template-rows: 40px 1fr;
    grid-template-columns: 60px 1fr;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
        border-bottom: none;
    }
`

const Avatar = styled.div`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Header = styled.div`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Body = styled.div`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
`

const AuthorBlock = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`   

const Author = styled.p`
    display: block;
    width: 100%;
    font-family: Krub;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
`

const AuthorType = styled.p`
    display: block;
    width: 100%;
    font-family: Roboto;
    font-size: .8rem;
    font-weight: 300;
    text-transform: capitalize;
`

const MetaBlock = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
`

const Timestamp = styled.span`
    font-family: Roboto;
    font-weight: 200;
    font-size: 12px;
`

const Text = styled.p`
    font-size: 13px;
    font-family: Roboto;
    font-weight: 300;
    margin-top: 3px;
`

const Comment = ({ comment, currentUser }) => {
    let initials, name

    if (['admin', 'client'].includes(currentUser.role) && comment.author.role === 'editor') {
        initials = 'E'
        name = 'Editor'
    } else if (['admin', 'editor'].includes(currentUser.role) && comment.author.role === 'client') {
        initials = 'C'
        name = 'Client'
    } else {
        initials = comment.author.name.split(' ').map(name => name[0]).join('')
        name = comment.author.name
    }

    const visibleTo = Array.from(new Set(comment.viewableTo)).filter(role => {
        return !['super', 'admin', 'dev'].includes(role)
    })

    return (
        <Container>
            <Avatar>
                <Bubble initials={initials} />
            </Avatar>
            <Header>
                <AuthorBlock>
                    <Author>{name}</Author>
                    <AuthorType>{comment.topic}</AuthorType>
                </AuthorBlock>
                <MetaBlock>
                    <Timestamp>{moment(comment.createdAt).from(moment())}</Timestamp>
                    { ['super', 'admin', 'dev'].includes(currentUser.role) && <AuthorType><Icon name='eye' />{ visibleTo.join(', ')}</AuthorType> }
                </MetaBlock>
            </Header>
            <Body>
                <Text>{comment.text}</Text>
            </Body>
        </Container>
    )
}

export default Comment