import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { error, success } from './colors'
import { clearNotification, updateUser } from '../../actions'
import { blue, white } from './colors'

const Container = styled.div`
    min-height: 80px;
    width: 300px;
    border-radius: 5px;
    background: #fff;
    position: relative;
    right: ${props => props.show ? '310px' : '-310px'};
    z-index: 99999;
    display: grid;
    grid-template-columns: 20% 70% 10%;
    overflow: hidden;
    transition: right 250ms ease-out;
    background: #374146;
    color: #fff;
`

const IconBox = styled.div`
    width: 100%;
    height: 100%;
    background: ${props => ['success', 'info'].includes(props.type) ? success : error };
    display: flex;
    align-items: center;
    justify-content: center;
`

const IconContainer = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Icon = styled.svg`
    color: ${props => {
        switch(props.type) {
            case 'success':
                return success
            case 'failure':
                return error
            default:
                return success
        }
    }}
`

const Error = styled.p`
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 700;
`

const Body = styled.div`
    height: 100%;
    padding: 10px;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Title = styled.h3`
    font-size: 1.05rem;
    font-family: Krub;
    margin-bottom: 2px;
`

const Message = styled.div`
    font-size: .85rem;
    font-family: Roboto;
`

const CloseBox = styled.div`
    display: flex;
    justify-content: center;
    font-family: Roboto;
    font-size: 1.3rem;
    font-weight: 300;
`

const Close = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
`

const Button = styled.button`
  color: ${blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 80px;
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  background: ${white};
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const Notification = ({ currentUser, updateUser, notification, clearNotification }) => {

    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(false)
        setTimeout(() => clearNotification(notification.id), 250)
    }

    useEffect(() => {
        setTimeout(() => setShow(true), 10)
        if (!notification.noClear) {
            setTimeout(() => {
                setShow(false)
                clearNotification(notification.id)
            }, 4000)
        }
    }, [notification, clearNotification])

    const handleClear = () => { 
        clearNotification(notification.id) 
        if (notification.update) updateUser(currentUser._id, notification.update)
    }

    return (
        <Container show={show}>
            <IconBox type={notification.type}>
                <IconContainer>
                    { ['success', 'info'].includes(notification.type) ? 
                        <Icon type='success' id="Icons" height="512" viewBox="0 0 74 74" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m56.3 65.99a1 1 0 0 1 -.563-1.827c.709-.482 1.452-1.04 2.27-1.708a1 1 0 1 1 1.264 1.55c-.864.7-1.652 1.3-2.41 1.812a1 1 0 0 1 -.561.173z"/><path d="m37 72a34.96 34.96 0 1 1 32.642-22.338 1 1 0 1 1 -1.864-.724 33.006 33.006 0 1 0 -18.278 18.607 1 1 0 0 1 .76 1.851 34.747 34.747 0 0 1 -13.26 2.604z"/><path d="m63.549 59.24a1 1 0 0 1 -.777-1.628c.582-.721 1.148-1.49 1.729-2.351a1 1 0 1 1 1.658 1.118c-.613.909-1.212 1.724-1.831 2.49a1 1 0 0 1 -.779.371z"/><path d="m37 63.625a26.625 26.625 0 1 1 26.625-26.625 26.655 26.655 0 0 1 -26.625 26.625zm0-51.25a24.625 24.625 0 1 0 24.625 24.625 24.653 24.653 0 0 0 -24.625-24.625z"/><path d="m31.987 46.111a1 1 0 0 1 -.707-.293l-6.2-6.2a1 1 0 1 1 1.42-1.41l5.487 5.492 15.513-15.518a1 1 0 1 1 1.416 1.418l-16.222 16.218a1 1 0 0 1 -.707.293z"/></Icon> :
                        <Error style={{ color: error }}>!</Error>
                    }
                </IconContainer>
            </IconBox>
            <Body>
                {}
                <Title>{notification.type === 'success' ? 'Success!' : 'info' ? '' : 'Error!'}</Title>
                { notification.message && <Message length={notification.message.length}>{ notification.message }</Message> }
                { notification.noClear && <Button onClick={handleClear}>Ok</Button> }
            </Body>
            <CloseBox>
                <Close onClick={handleClick}>x</Close>
            </CloseBox>
        </Container>
    ) 
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

export default connect(mapStateToProps, { clearNotification, updateUser })(Notification)
