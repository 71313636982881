import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import validate from '../validate'
import { blue, white } from '../../../Shared/colors'
import moment from 'moment-timezone'
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";

import { getEstimatedDeadline } from '../../../../actions/projectActions'

const Page = styled.div`
  background: ${props => props.odd ? blue : white};
  height: 100%;
  width: 100%;
  color: ${props => props.odd ? white : blue};
  padding: 20px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 200ms ease-in;
`

const Title = styled.p`
  font-size: 1.8rem;
  font-family: Krub;
  font-weight: 700;
  margin-bottom: 10px;
`

const CheckText = styled.p`
  text-align: center;
  font-size: .9rem;
  font-family: Krub;
  margin-bottom: 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  gap: 10px;
`

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  margin-left: ${props => props.next && 'auto'};
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  opacity: ${props => props.show ? 1 : 0 };
  transition: opacity 500ms ease-out;
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const Wipe = styled.div`
  position: absolute;
  width: ${props => props.grow ? '1200px' : '0'};
  height: ${props => props.grow ? '1200px' : '0'};
  border-radius: 50%;
  background: ${props => props.odd ? white : blue};
  transition: height 350ms ease-in, width 350ms ease-in;
`

const renderField = ({ input }) => <input style={{ width: '20px'}} {...input} key='key' type='checkbox' checked={input.value}/>

const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
    <DatePicker
      onChange={onChange}
      format="DD MMM YYYY"
      time={showTime}
      min={new Date()}
      value={!value ? null : new Date(value)}
    />
  
const RushDeliveryCheck = ({ 
  handleSubmit, 
  previousPage, 
  rushDelivery, 
  rushRequestDate,
  getEstimatedDeadline,
  estimatedDeadline,
  service, 
  odd 
}) => {

  const [grow, setGrow] = useState(false)
  const [show, setShow] = useState(false)


  useEffect(() => {
    if (service) {
      const projectLength = service === "editing and culling" ? 3 : 2
      getEstimatedDeadline(projectLength )
    }
    
    setTimeout(() => setShow(true), 1)
  }, [])

  const handleNext = e => {
    e.preventDefault()
    setGrow(true)
    setTimeout(handleSubmit, 350)
  }

  const determineRushRate = () => {
    if (service === 'culling') {
      return '($.12/photo)'
    } else if (service === 'editing') {
      return '($.50/photo)'
    } else if (service === 'retouching') {
      return '($2/min)'
    }
    return '($.50/photo for editing, $.12/photo for culling)'
  }

  const showNext = !rushDelivery || (rushDelivery && rushRequestDate)
  console.log(rushDelivery, rushRequestDate, rushRequestDate !== null)
  const showThanks = rushDelivery && rushRequestDate && rushRequestDate !== null

  return (
    <Page odd={odd}>
      <Container show={show}>
        <Title>Catalog Rush</Title>
        { estimatedDeadline && estimatedDeadline.deliveryDate && <CheckText>
          <strong>Projected standard delivery deadline: {moment(estimatedDeadline.deliveryDate).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mma')}</strong>
          <br />
          * Projection based on assumption that upload completes same day before 5:00pm Pacific
          <br />
          <br />
        </CheckText> }
        <Form odd={odd} onSubmit={handleSubmit}>
          <CheckText style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
            <Field
                name="rushDelivery"
                component={renderField}
            />
              Request expedited processing {determineRushRate()}
          </CheckText>

          { rushDelivery && 
            <Field
              name="rushRequestDate"
              showTime={false}
              component={renderDateTimePicker}
            />
          }        
          { showThanks && <CheckText style={{ marginTop: '20px'}}>Thanks for the rush request! The team will review your request after double checking your catalog and verifying our availability with the team. We will be in touch shortly!</CheckText> }
          <ButtonGroup>
            <Button show odd={odd} type="button" className="previous" onClick={previousPage}>Back</Button>
            <Button odd={odd} next show={showNext} onClick={handleNext} className="next">Next</Button>
          </ButtonGroup>
        </Form>
        <Wipe odd={odd} grow={grow} />
      </Container>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    rushDelivery: state.form.newProject.values ? state.form.newProject.values.rushDelivery : false,
    rushRequestDate: state.form.newProject.values ? state.form.newProject.values.rushRequestDate : null,
    service: state.form.newProject.values && state.form.newProject.values.service,
    estimatedDeadline: state.project.estimatedDeadline
  }
}

const connected = connect(mapStateToProps, { getEstimatedDeadline })(RushDeliveryCheck)

export default reduxForm({
    form: 'newProject', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
  })(connected)