import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

import { 
    toggleMenu, 
    setDrawerView, 
    selectUser, 
    setShowOverlay
} from '../../actions'

import { blue } from '../Shared/colors'
import Bubble from '../Shared/Bubble'

const Container = styled.div`
    width: 100vw;
    height: 60px;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 1;
    background: #fff;
`

const Toggle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-left: 10px;
`

const StyledIcon = styled(Icon)`
    color: ${blue};
    font-size: 30px;
    z-index: 99999;
    transform: ${props => props.visible === 'true' && 'rotate(90deg)'};
    transition: transform .3s ease-in-out;
    justify-self: center;
    align-self: center;
    &:hover {
        cursor: pointer
    }
`

const Greeting = styled.div`
    margin-left: auto;
    color: #2b4265;
    display: flex;
    flex-direction: row;
    font-family: Krub;
    align-items: center;
    margin-right: 10px;
`

const Slogan = styled.p`
    margin: 0 10px 0 0;
    align-self: center;
    font-weight: 400;
    color: #2b4265;
    font-size: ${props => props.mobile ? '.9rem' : '1rem'};
`

const Header = ({ isSignedIn, currentUser, sidebarView, setDrawerView, selectUser, mobile, setShowOverlay, toggleMenu, showMenu }) => {
    
    const handleClick = async () => {
        await selectUser(currentUser._id)
        await setDrawerView('user')
        setShowOverlay(true)
    }

    function renderGreeting() {
        if (isSignedIn && !sidebarView) {
            return (
                <Greeting>
                    <Slogan mobile={mobile}>We've got your back{(currentUser && currentUser.firstName) ? `, ${currentUser.firstName}!` : '!'}</Slogan>
                    <Bubble onClick={handleClick} background={currentUser && currentUser.avatar} initials={currentUser && !currentUser.avatar && currentUser.firstName[0] + currentUser.lastName[0]} />
                </Greeting>
            )
        }
    }

    return (
        <Container>
            <Toggle>
                <StyledIcon size='big' name='bars' visible={showMenu.toString()} onClick={toggleMenu}/>
            </Toggle>
            { renderGreeting() }
        </Container>
    )
}

const mapStateToProps = state => {
    return { 
        showMenu: state.view.showMenu, 
        isSignedIn: state.user.isSignedIn,
        currentUser: state.user.currentUser,
        mobile: state.index.mobile,
        sidebarView: state.view.sidebarView
    }
}

export default connect(mapStateToProps, { toggleMenu, setDrawerView, selectUser, setShowOverlay })(Header)
