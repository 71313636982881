import _ from 'lodash'

import {
    CREATE_COMMENT,
    DELETE_COMMENT,
    SET_VIEWABLE_TO,
    SET_COMMENT_TEXT
} from '../actions/types'

const initialState = {
    viewableTo: [],
    commentText: null
}

const commentReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_COMMENT:
            return { ...state, viewableTo: [] }
        case DELETE_COMMENT:
            return _.omit(state, action.payload)
        case SET_VIEWABLE_TO:
            if (state.viewableTo.includes(action.payload))
                return { ...state, viewableTo: state.viewableTo.filter(role => action.payload !== role) }
            else 
                return { ...state, viewableTo: [...state.viewableTo, action.payload] }
        case SET_COMMENT_TEXT:
            return { ...state, commentText: action.payload }
        default:
            return state
    }
}

export default commentReducer