import axios from 'axios'

import {
    CREATE_ACTION,
    FETCH_ACTIONS,
    NEXT_HISTORY_ACTION_PAGE
} from './types'

export const fetchActions = (page, searchTerm, sortBy, sortDirection, actionFilter, roleFilter, resourceFilter) => async dispatch => {
    let url = `/api/history?page=${page}`
    searchTerm && (url += `&searchTerm=${searchTerm}`)
    sortBy && (url += `&sortBy=${sortBy}`)
    sortBy && (url += `&sortDirection=${sortDirection || 'desc'}`)
    actionFilter && (url += `&action=${actionFilter}`)
    roleFilter && (url += `&actingUserRole=${roleFilter}`)
    resourceFilter && (url += `&resourceType=${resourceFilter}`)
     
    try {
        const response = await axios.get(url)
        return dispatch({ type: FETCH_ACTIONS, payload: response.data })
    } catch (e) {
        throw new Error(e)
    }

    // dispatch({ type: FETCH_ACTIONS, payload: { actions: [], length: 0 }})
}

export const createAction = newAction => async dispatch => {
    try {
        const response = await axios.post('/api/history', newAction)
        return dispatch({ type: CREATE_ACTION, payload: response.data.actions, count: response.data.count })
    } catch (e) {
        throw new Error(e)
    }
}
export const nextHistoryActionPage = () => dispatch => {
    dispatch({ type: NEXT_HISTORY_ACTION_PAGE })
}


