import axios from 'axios'

import {
    CREATE_COMMENT,
    SET_VIEWABLE_TO,
    RESET_VIEWABLE_TO,
    SET_COMMENT_TEXT

} from './types'

export const createComment = (projectId, comment) => async dispatch => {
    try {
        const response = await axios.post(`/api/projects/${projectId}/comment`, { comment })
        dispatch({ type: CREATE_COMMENT, payload: response.data })
    } catch (e) {
        throw new Error(e)
    }
}

export const setViewableTo = role => async dispatch => {
    return dispatch({ type: SET_VIEWABLE_TO, payload: role })
}

export const resetViewableTo = () => async dispatch => {
    return dispatch({ type: RESET_VIEWABLE_TO})
}

export const setCommentText = text => dispatch => {
    return dispatch({ type: SET_COMMENT_TEXT, payload: text})
}