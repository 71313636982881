import { 
    SET_FOCUS
} from '../actions/types'


const initialState = { field: 'name'}

const paymentReducer =  (state = initialState, action) => {
    switch(action.type) {
        case SET_FOCUS:
            return { ...state, field: action.payload}
        default:
            return state;
    }
}

export default paymentReducer