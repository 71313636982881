import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
import moment from 'moment'

import UploadImage from '../../Shared/UploadImage'
import DefaultProfileImage from '../../../images/DefaultProfile.png'
import capitalize from '../../../lib/capitalize'
import { blue } from '../../Shared/colors'

import { 
    Icon, 
    Input, 
    Button, 
} from 'semantic-ui-react'


import { 
    updateUser, 
    setDrawerView, 
    setShowOverlay, 
    addNotification ,
    deleteUser
} from '../../../actions'


const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    & .fileUploader {
        margin-top: 40px;
    }

    & .fileContainer {
        align-self: center;
        width: 250px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        text-align: center; !important;
    }

    & .errorsContainer {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        text-align: center; !important;
    }
`

const Body = styled.div`
  height: 75vh;
  padding: 10px 20px;
  font-size: ${props => (props.mobile ? '.75rem' : '.85rem')};
  overflow-y: scroll;
`

const Header = styled.div`
    height: 40vh;
    width: 100%;
    background-image: url("${props => props.image ? `/images/profile-images/${props.image}` : DefaultProfileImage} ");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    overflow: hidden;
    margin: -3px 0;
    position: relative;
`

const UpdateProfileImage = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    background: ${blue};
    padding: 5px;
    border-radius: 10px;
    color: #fff;

    &:hover {
        opacity: .6;
        cursor: pointer;
    }
`

const EditProfile = styled.div`
    margin: 20px;
    display: flex;
    margin-left: auto;
    align-items: center;
    background: #fff;
    padding: 5px;
    border-radius: 10px;
    color: ${blue};
    
    & i {
        margin: 0;
    }

    &:hover {
        opacity: .6;
        cursor: pointer;
    }
`

const UploadContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`  

const StyledImageUploader = styled(UploadImage)`
    width: 100px !important;
`

const CloseSidebar = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    opacity: .6;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
`

const IdContainer = styled.div`
    display: flex;
    padding: 10px 20px;
    background: ${blue};
    color: #fff;
    font-weight: 700;
`
const Id = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Title = styled.p`
    font-family: Krub;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
`

const StyledHr = styled.hr`
    opacity: .25;
`

const StyledInput = styled.input`
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
`
const StyledTextArea = styled.textarea`
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
`


const StyledSelect = styled.select`
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotateZ(0);
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: .78571429em 2.1em .78571429em 1em;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
`

const StyledIcon = styled(Icon)`
    & .icon {
        margin: 0 !important;
    }   
`

const Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-weight: 300;
  align-items: center;
  margin-bottom: 5px;

  &:first-of-type {
      & .ui.input {
      }
  }

  &:last-of-type {
      height: 100px;
      align-items: start;
  }
`

const DetailTitle = styled.p`
  display: block;
  width: 30%;
  justify-self: center;
  font-weight: 300;
  font-size: 0.8rem;
  color: #999;
  margin: 0;
`

const DetailValue = styled.div`
  width: 70%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-family: Roboto;

  & .birthday {
      width: 150px !important;
  }

  & .birthday .ui.selection.dropdown {
      width: 100% !important;
      height: 100% !important;
      min-width: 0;
  }

  & textarea {
      width: 100%;
      height: 100px;
  }
`

const ShareCheckbox = styled(Input)`
    margin-right: auto;
`

let User = ({ selectedUser, currentUser, updateUser, setDrawerView, setShowOverlay, editUserForm, deleteUser }) => {
    const { profileImage, firstName, lastName, createdAt, _id, lastProjectName} = selectedUser
    const [editMode, setEditMode] = useState(false)
    const [role, setRole] = useState(selectedUser.role)
    const [email, setEmail] = useState(selectedUser.email)
    const [birthDay, setBirthDay] = useState(selectedUser.birthDay)
    const [birthMonth, setBirthMonth] = useState(selectedUser.birthMonth)
    const [bio, setBio] = useState(selectedUser.bio)
    const [sharePermission, setSharePermission] = useState(selectedUser.sharePermission)
    const [deactivated, setDeactivated] = useState(selectedUser.deactivated)
    const [showImageUpload, setShowImageUpload] = useState(false)

    useEffect(() => {
        if (selectedUser) {
            setRole(selectedUser.role)
            setEmail(selectedUser.email)
            setBirthDay(selectedUser.birthDay)
            setBirthMonth(selectedUser.birthMonth)
            setBio(selectedUser.bio)
            setSharePermission(selectedUser.sharePermission)
            setDeactivated(selectedUser.deactivated)
        }
    }, [selectedUser])

    const handleClose = () => {
        setDrawerView(null)
        setShowOverlay(false)
    }

    const toggleActivation = async active => {
        try {
            await updateUser(_id, { deactivated: active })
        } catch (e) {
            addNotification({ type: 'error', message: e })
        }
    }

    const handleEditMode = () => {
        if (editMode) {
            updateUser(selectedUser._id, {
                bio,
                birthDay,
                birthMonth,
                email,
                sharePermission,
                deactivated,
                role
            })
        }
        setEditMode(!editMode)
    }

    const handleDeleteUser = async () => {
    const confirmed = window.confirm(`Delete user?`)
    if (confirmed) {
      try {
        handleClose()
        await deleteUser(selectedUser._id)
      } catch (e) {
          addNotification({
            type: 'error',
            message: 'Error deleting user'
        })
      }
    }
  }

    const renderIcon = role => {
        switch(role) {
            case 'editor':
                return <Icon name='paint brush' />
            case 'client':
                return <Icon name='camera retro' />
            case 'dev':
                return <Icon name='code' />
            default:
                return <Icon name='chess king' />
        }
    }

   const renderDays = () => {
    const dayCount = { 1: 31, 2: 29, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31 }

    const numDays = dayCount[birthMonth || 1]
    const fields = []

    for (let i = 1; i <= numDays; i++) {
      fields.push(<option key={i} value={i}>{i}</option>)
    }
    return [<option key='none-day' value={null}>Day</option>, ...fields]
  }

  const monthMap = { 1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' }

  const renderMonthOptions = () => {
    let months = Object.keys(monthMap).map(month => <option key={month} value={month}>{monthMap[month]}</option>)
    return [<option key='none-month' value={null}>Month</option>, ...months]
  }

  const handleSelectMonth = e => {
    setBirthMonth(e.target.value)
    setBirthDay(1)
  }

  const handleSelectDay = e => {
    setBirthDay(e.target.value)
  }

    const SelectRole = () => {
        let options = [
            'Client',
            'Editor',
            'Admin',
            'Dev'
        ]
        
        return (
            <StyledSelect onChange={e => setRole(options[e.target.selectedIndex].toLowerCase())}>
                { options.map((option, i) => <option selected={option && currentUser.role === option.toLowerCase()} key={option} value={option}>{option}</option>)}
            </StyledSelect>
        )
    }

    const SelectAllRole = () => {
        let options = [
            'Client',
            'Editor',
            'Admin',
            'Dev',
            'Super',
        ]
        
        return (
            <StyledSelect onChange={e => setRole(options[e.target.selectedIndex].toLowerCase())}>
                { options.map((option, i) => <option selected={option && selectedUser.role === option.toLowerCase()} key={option} value={option}>{option}</option>)}
            </StyledSelect>
        )
    }

    const handleUpdateUserRole = async event => {
        const role =
            Array.from(event.target)
                .map(option => option.value)
                .indexOf(event.target.value) + 1

        try {
            await updateUser(selectedUser._id, { role: role.toLowerCase() })
        } catch (e) {
            addNotification({
                type: 'error',
                message: 'Error updating user role'
            })
        }
    }

    const handleUpdateBio = e => {
        setBio(e.target.value)
    }

    const renderUserDetails = () => {
        return (
            <Body onClick={() => setShowImageUpload(false)}>
                <Detail>
                    <DetailTitle>{ renderIcon()} Email</DetailTitle>
                    { !editMode && <DetailValue>{ email.toLowerCase() }</DetailValue> }
                    { editMode && <StyledInput name="email" type="text" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)}/>}
                </Detail>
                    
                <Detail>
                    <DetailTitle><StyledIcon name='calendar alternate outline' />Joined in</DetailTitle>
                    <DetailValue>{ moment(createdAt).year() > 2000 ? moment(createdAt).format('MMMM YYYY') : 'Unavailable' }</DetailValue>
                </Detail>
                
                <Detail>
                    <DetailTitle><StyledIcon name='birthday cake' />Birthday</DetailTitle>
                    { !editMode && <DetailValue>{ birthMonth && birthDay ? ` ${monthMap[birthMonth]} ${birthDay} ` : ' not provided '}</DetailValue> }
                    { editMode && (
                        <DetailValue>
                            <StyledSelect onChange={handleSelectMonth} value={birthMonth}>
                                { renderMonthOptions() }
                            </StyledSelect>
                            <StyledSelect onChange={handleSelectDay} value={birthDay}>
                                { renderDays() }
                            </StyledSelect>
                        </DetailValue>
                    ) }
                </Detail>

                { role === 'client' && (
                    <>
                        <Detail>
                            <DetailTitle><StyledIcon name='camera retro' /> Most Recent Project:</DetailTitle>
                            <DetailValue>{ lastProjectName ? ' ' + lastProjectName : ' New Client'}</DetailValue>
                        </Detail> 
                        <Detail>
                            <DetailTitle><StyledIcon name='instagram' /> Share Permission:</DetailTitle>
                            { !editMode && <DetailValue>{ sharePermission ? 'Yes' : 'No'}</DetailValue> }
                            { editMode && <DetailValue><ShareCheckbox name="sharePermission" type="checkbox" checked={sharePermission} onChange={e => setSharePermission(e.target.checked)}/></DetailValue>}
                        </Detail> 
                        <Detail>
                            <DetailTitle><StyledIcon name='user outline' /> Bio:</DetailTitle>
                            { !editMode && <DetailValue>{ bio ? ` ${bio} ` : '' }</DetailValue> }
                            { editMode && (
                                <DetailValue>
                                    <StyledTextArea name="bio" onChange={handleUpdateBio} placeholder="Tell us a bit about your style and what you're looking for in your edits." value={bio}/>
                                </DetailValue>
                                )
                            }
                        </Detail>
                    </>
                )}

                { ['super', 'dev'].includes(currentUser.role) && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px'}}>
                        { !deactivated && <Button color='yellow' size='mini' onClick={() => toggleActivation(true)}><Icon name='exclamation' />Deactivate User</Button> }
                        { deactivated && <Button positive size='mini' onClick={() => toggleActivation(false)}><Icon name='check' />Reactivate User</Button> }
                        <Button negative size='mini' onClick={handleDeleteUser}>Delete User</Button>
                    </div>
                )}
            </Body>
        )
    }

    return (
        <Container>
            <Header image={profileImage || null}>
                { showImageUpload && (
                    <UploadContainer>
                        <StyledImageUploader 
                            hideImageUploader={() => setShowImageUpload(false)} 
                            updateUser={updateUser}
                        />
                    </UploadContainer>
                )}
                <CloseSidebar onClick={handleClose}> 
                    <Icon name='close' size='big'/>
                </CloseSidebar>
                <UpdateProfileImage onClick={e => {
                    e.stopPropagation()
                    setShowImageUpload(true)}
                }>
                    <StyledIcon name='camera' size='large'/>
                </UpdateProfileImage>
            </Header>
            <IdContainer onClick={() => setShowImageUpload(false)}>
                <Id>
                    <Title>{firstName} {lastName}</Title>
                    { (!editMode || !['super', 'admin', 'dev'].includes(currentUser.role)) && capitalize(selectedUser.role) }
                    { ['admin', 'dev'].includes(currentUser.role) && editMode && <SelectRole />}
                    { ['super'].includes(currentUser.role) && editMode && <SelectAllRole />}
                </Id>
                <EditProfile onClick={e => handleEditMode()}>
                    <StyledIcon name={editMode ? 'checkmark' : 'edit'} size='large'/>
                </EditProfile>
            </IdContainer>
            <StyledHr />
            { renderUserDetails() }
        </Container>
    )
}


const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        selectedUser: state.user.selectedUser,
        editUserForm: state.form.editUserForm,
    }
}

User = reduxForm({
  form: 'editUserForm'
})(User)

// You have to connect() to any reducers that you wish to connect to yourself
User = connect(mapStateToProps, { updateUser, setDrawerView, setShowOverlay, addNotification, deleteUser })(User)

export default User


