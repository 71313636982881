import {
  SET_GOOGLE_AUTH,
  GOOGLE_OAUTH2,
  SET_GAPI,
  FETCH_EVENTS,
} from "../actions/types";

const initialState = {
  gauth: null, 
  gapi: null
}

const googleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GOOGLE_AUTH:
      return { ...state, gauth: action.payload }
    case GOOGLE_OAUTH2:
      return action.googleResponse;
    case SET_GAPI:
      return { ...state, gapi: action.payload }
    case FETCH_EVENTS:
      return { ...state, events: action.payload }
    default:
      return state;
  }
};

export default googleReducer