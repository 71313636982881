import {
    SIGN_IN, 
    SIGN_OUT, 
    SIGN_UP, 
    GET_CURRENT_USER, 
    SET_AUTH_ERROR, 
    SET_AUTH_LOADING,
    SELECT_USER,
    FETCH_USERS,
    UPDATE_USER,
    DELETE_USER,
    CHANGE_USER_ROLE,
    CHANGE_USER_SEARCH_TERM,
    SORT_USERS_BY,
    NEXT_PAGE,
    UPLOAD_PROFILE_IMAGE,
    UPDATE_USER_AVAILABILITY,
    FETCH_USER_AVAILABILITY,
    FETCH_AVAILABLE_EDITORS,
    LOAD_USER,
    REDIRECT,
    CLEAR_USERS
} from '../actions/types'

const initialState = {
    loading: false,
    isSignedIn: false,
    selectedUser: null,
    currentUser: null, 
    users: [],
    searchTerm: null,
    sort_by: 'lastName',
    sort_direction: 'asc',
    page: 1,
    count: 0,
    role: 'client',
    holidays: [],
    userAvailability: [],
    availableEditors: [],
    redirect: null
}

const userReducer = (state = initialState, action) => {
    let currentUsers, role, selectedUser, newState, updatedUsers
    switch(action.type) {
        case SIGN_IN:
            return { ...state, ...action.payload}
        case SIGN_OUT:
            return { ...state, isSignedIn: false, currentUser: null }
        case SIGN_UP:
            return { ...state, ...action.payload}
        case GET_CURRENT_USER:
            return { ...state, ...action.payload}
        case SET_AUTH_LOADING: 
            return {...state, auth_loading: action.payload }
        case SET_AUTH_ERROR:
            return {...state, error: action.payload}
        case FETCH_USERS:
            const userUpdate = action.payload.pageChange ? [ ...state.users, ...action.payload.users ] : action.payload.users
            return { ...state, count: action.payload.count, users: userUpdate }
        case CHANGE_USER_ROLE:
            return { ...state, users: [], page: 1, count: 0, role: action.payload }
        case CHANGE_USER_SEARCH_TERM:
            return { ...state, users: [], page: 1, count: 0, searchTerm: action.payload }
        case UPDATE_USER:
            newState = { ...state }

            const updatedUser = action.payload

            if (state.currentUser._id === updatedUser._id) {
                newState.currentUser = updatedUser
            }

            if (state.selectedUser && state.selectedUser._id === updatedUser._id) {
                newState.selectedUser = updatedUser
            }

            const indexInUsersArray = state.users.map(user => user._id).indexOf(updatedUser._id)
            // checks if updated user is included in displayed users when in 'user' view on dashboard
            if (indexInUsersArray !== -1) {
                updatedUsers = [ ...state.users]
                updatedUsers[indexInUsersArray] = updatedUser
                newState.users = updatedUsers
            } 

            return newState
        case SELECT_USER:
            return { ...state, selectedUser: action.payload }
        case DELETE_USER:
            updatedUsers = state.users.filter(user => user._id !== action.payload)
            return { ...state, selectedUser: null, users: updatedUsers }
        case SORT_USERS_BY:
            return { ...state, sort_by: action.payload, users: [], page: 1, count: 0 }
        case NEXT_PAGE:
            return { ...state, page: state.page + 1 }
        case UPLOAD_PROFILE_IMAGE:
            newState = { ...state, selectedUser: { ...state.selectedUser, profileImage: action.payload } }
            if (state.currentUser._id === state.selectedUser._id)
                newState.currentUser = { ...state.currentUser, profileImage: action.payload}
            return newState
        case UPDATE_USER_AVAILABILITY:
            return { ...state, currentUser: action.payload }
        case FETCH_USER_AVAILABILITY:
            return { ...state, userAvailability: action.payload}
        case FETCH_AVAILABLE_EDITORS:
            return { ...state, availableEditors: action.payload }
        case LOAD_USER:
            return { data: selectedUser }
        case CLEAR_USERS:
            return { ...state, users: [] }
        case REDIRECT:
            return { redirectTo: action.payload };
        default:
            return state
    }
}

export default userReducer