import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import LogoImage from '../../../images/MenuLogo.png'
import { Icon } from 'semantic-ui-react'

import { 
    toggleMenu, 
    setView, 
    signOut,
    setDrawerView, 
    setShowOverlay, 
    updateDate, 
    googleOAuth2,
    setLoadingProjects,
    setModalView,
    setFetchInterval
} from '../../../actions'

import { blue, white } from '../../Shared/colors'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { GoogleLogout } from 'react-google-login';

import AdminMenu from './AdminMenu'
import EditorMenu from './EditorMenu'
import ClientMenu from './ClientMenu'

const Container = styled.div`
    position: absolute;
    z-index: ${props => props.mobile ? '2' : '1'};
    background: ${blue};
    color: ${white};
    top: 0;
    left: ${props => props.visible ? 0 : '-100vw'};
    height: 100%;
    width: ${props => props.mobile ? '100vw' : '325px'};
    transition: left .1s ${props => props.visible && '.3s' } ease-out;
    font-family: Krub;
    user-select: none;
    display: flex;
    flex-direction: column;
`

const MainMenu = styled.div`
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
`

const Logo = styled.img`
    height: 50px;
    transition: opacity .3s ease-out;
    transition-delay: .3s;
    justify-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
`

const Hamburger = styled.i`
    color: #fff;
    font-size: 30px;
    position: absolute;
    left: 10px;
    top: 16px;
    z-index: 99999;
    transform: ${props => props.visible && 'rotate(90deg)'};
    transition: transform .3s ease-in-out;
    &:hover {
        cursor: pointer
    }
`

const LinkContainer = styled.span`
    color: ${white};
    text-decoration: none;
    padding: 10px 0;
    opacity: ${props => !props.show ? '0' : '1'};
    visibilty: ${props => props.show ? 'visible' : 'hidden'};
    background: ${props => props.active ? 'rgba(0,0,0,.25)' : 'rgba(0,0,0,0)' };
    &:hover {
        cursor: pointer;
        background: rgba(0,0,0,.25);
    }
    &:nth-of-type(1):not(.logout) {
        transition: ${props => props.show && 'opacity .3s .5s ease-out'};
    }
    &:nth-of-type(2) {
        transition: ${props => props.show && 'opacity .3s .6s ease-out'};
    }
    &:nth-of-type(3) {
        transition: ${props => props.show && 'opacity .3s .7s ease-out'};
    }
    &:nth-of-type(4) {
        transition: ${props => props.show && 'opacity .3s .8s ease-out'};
    }
    &:nth-of-type(5) {
        transition: ${props => props.show && 'opacity .3s .9s ease-out'};
    }
    &:nth-of-type(6) {
        transition: ${props => props.show && 'opacity .3s 1s ease-out'};
    }
    &:nth-of-type(7) {
        transition: ${props => props.show && 'opacity .3s 1.1s ease-out'};
    }
    &:nth-of-type(8) {
        transition: ${props => props.show && 'opacity .3s 1.2s ease-out'};
    }
    &:last-of-type {
        margin-top: auto;
        margin-bottom: ${props => props.mobile ? '30px' : '10px'};
        width: 100%;
    }
`

const StyledLink = styled(Link)`
    color: ${white};
    text-decoration: none;
`

const NavItem = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: ${ props => props.mobile ? 'calc(50% - 50px)' : '110px'};
`

const NavItemTitle = styled.div`
    font-size: 1rem;
    color: #ddd;
    margin-left: 10px;
    position: relative;
    line-height: 1.2rem;
    ${ props => props.notification && `&:after {
        content: "${props.notification}";
        font-size: .75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        width: 18px;
        position: absolute;
        right: -18px;
        bottom: 10px;
        border-radius: 50%;
        background: red;
        color: white;
    }`}
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
`

const Footer = styled.div`
    justify-self: flex-end;
    margin-top: auto;
    padding: 20px 0;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
`

const CopyRight = styled.p`
    font-size: .7rem;
    text-align: center;
    & a {
        color: #fff;
    }
    opacity: ${props => props.show ? 1 : 0};
    margin-bottom: 0;
`

const FlashSpark = styled.p`
    font-size: .7rem;
    text-align: center;
    margin-bottom: 10px;
    & a {
        color: #fff;
    }
    opacity: ${props => props.show ? 1 : 0};
`

const PrivacyPolicy = styled.p`
    font-size: .7rem;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 20px;
    & a {
        color: #fff;
    }
    opacity: ${props => props.show ? 1 : 0};
    &:hover {
        cursor: pointer;
    }
`

const Menu = ({ 
    showMenu, 
    toggleMenu, 
    setView, 
    view, 
    signOut, 
    currentUser, 
    setShowOverlay, 
    googleOAuth2, 
    isLoggedInToGoogle,
    setModalView,
    mobile,
    fetchInterval,
    setFetchInterval
}) => {

    async function handleShowView(view) {
        await setView(view)
        toggleMenu()
    }

    function handleShowPrivacy() {
        setModalView('privacy')
        setShowOverlay(true)
    }

    function handleSignOut() {
        clearInterval(fetchInterval)
        setFetchInterval(null)
        signOut()
    }


    function renderLogout() {
        if (isLoggedInToGoogle) {
            return (
                <LinkContainer show={showMenu} view={view} onClick={handleSignOut} className='logout' mobile={mobile}>
                    <StyledLink to="/">
                        <NavItem mobile={mobile}>
                            <GoogleLogout
                                clientId='853198385487-k0plig9r2n2ie1ij9aqci9hunk66dpcn.apps.googleusercontent.com'
                                buttonText="Logout"
                                onLogoutSuccess={googleOAuth2}
                                className='googleLogout'
                            />
                        </NavItem>
                    </StyledLink>
                </LinkContainer>
            )
        } else {
            return (
                <LinkContainer show={showMenu} view={view} onClick={handleSignOut} mobile={mobile}>
                    <StyledLink to="/" >
                        <NavItem mobile={mobile}>
                            <StyledIcon name='log out' size='large'/>
                            <NavItemTitle>Log Out</NavItemTitle>
                        </NavItem>
                    </StyledLink>
                </LinkContainer>
            )
        }
    }

    function renderMenu() {
        switch (currentUser.role) {
            case 'admin':
            case 'super':
            case 'dev':
                return <AdminMenu currentUser={currentUser} showMenu={showMenu} view={view} handleShowView={handleShowView} mobile={mobile}/>
            case 'editor':
                return <EditorMenu currentUser={currentUser} showMenu={showMenu} view={view} handleShowView={handleShowView} mobile={mobile}/>
            case 'client':
                return <ClientMenu currentUser={currentUser} showMenu={showMenu} view={view} handleShowView={handleShowView} mobile={mobile}/>
            default:
                return null
        }
    }


    return (
        <Container visible={showMenu} mobile={mobile}>
            <Hamburger visible={showMenu} className='fas fa-bars' onClick={toggleMenu} mobile={mobile}/>
            <Header>
                <Logo src={LogoImage}></Logo>
            </Header>
            <MainMenu>
                { renderMenu() }
            </MainMenu>
            <Footer mobile={mobile}>
                { renderLogout() }
                <FlashSpark show={showMenu}>Developed by<br /><a target='_blank' rel="noreferrer" href='http://www.flashspark.io'>FlashSpark Media, LLC.</a></FlashSpark>
                <CopyRight show={showMenu}>Copyright © {(new Date().getFullYear())} Batch.ai, Inc.</CopyRight>
                <PrivacyPolicy show={showMenu} onClick={handleShowPrivacy}>Privacy Policy</PrivacyPolicy>
            </Footer>
        </Container>
    )
}

const mapStateToProps = state => {
    return { 
        showMenu: state.view.showMenu,
        view: state.view.view,
        currentUser: state.user.currentUser,
        date: state.project.date,
        isLoggedInToGoogle: state.google.accessToken,
        mobile: state.index.mobile,
        fetchInterval: state.project.fetchInterval
    }
}

export default connect(mapStateToProps, 
    { 
        toggleMenu, 
        setView, 
        setDrawerView, 
        signOut, 
        setShowOverlay, 
        updateDate, 
        googleOAuth2, 
        setLoadingProjects, 
        setModalView,
        setFetchInterval
    }
)(Menu)