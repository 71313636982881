import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setModalView, updateProject, addNotification, createComment } from '../../actions'
import styled from 'styled-components'


const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    & select {
        height: 30px;
        width: 80px;
        font-size: 1rem;
        padding: 5px;
        margin: 5px 0 ;
    }

    & select:focus {
      outline: none;
    }

    & textarea {
        height: 150px;
        width: 90%;
        font-size: 1rem;
        padding: 5px;
        margin: 5px 5px 20px 5px;
        border-radius: 5px;
    }

    & textarea:focus {
      outline: none;
    }
`

const Title = styled.h2`
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 15px;
    color: #fff;
    font-family: Krub;
`

const Button = styled.button`
    border: none;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #aaa;
    font-family: Krub;
    font-weight: 500;
    font-size: ${props => props.mobile ? '.68rem' : '.8rem'};
    background: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
        font-size: .8rem;
        cursor: pointer;
        box-shadow: 1px 1px 2px #888;
    }
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`


const EditorUpload = ({ selectedProject, updateProject, setModalView, createComment }) => {
    const [approvalNotes, setApprovalNotes] = useState('')

    const handleApprove = e => {
       e.preventDefault()
       createComment(selectedProject._id, {
            author: {
                name: 'Shawn Hawkins',
                role: 'super'
            },
            type: 'Comment',
            topic: "Notes",
            viewableTo: ['admin', 'super', 'dev', 'client'],
            text: approvalNotes
        })

        updateProject(selectedProject._id, { state: 7, notesToClient: approvalNotes })
        setModalView(null)
    }

    return (
        <React.Fragment>
            <Form>
                <Title>Notes to Client</Title>
                <textarea name="approvalNotes" onChange={e => setApprovalNotes(e.target.value)} placeholder="..." value={approvalNotes} />
                <Button onClick={handleApprove}>Approve</Button>
            </Form>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        mobile: state.index.mobile,
        editorNotes: state.project.editorNotes,
        selectedProject: state.project.selectedProject
    }
}

export default connect(mapStateToProps, { setModalView, updateProject, addNotification, createComment })(EditorUpload)