import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { blue, white } from '../../../Shared/colors'
import { Icon } from 'semantic-ui-react'

const Page = styled.div`
  background: ${props => props.odd ? blue : white};
  height: 100%;
  width: 100%;
  color: ${props => props.odd ? white : blue};
  padding: 20px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 200ms ease-in;
`

const Title = styled.p`
  font-size: 1.8rem;
  font-family: Krub;
  font-weight: 700;
  margin-bottom: 10px;
`

const CheckText = styled.p`
  text-align: center;
  font-size: .9rem;
  font-family: Krub;
  margin-bottom: 20px;
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  margin-left: ${props => props.next && 'auto'};
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  opacity: ${props => props.show ? 1 : 0 };
  transition: opacity 500ms ease-out;
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const LetsGoPage = ({ onSubmit, odd }) => {

  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => setShow(true), 1)
  }, [])

  const handleClick = e => {
    setShow(false)
    onSubmit()
  }

  return (
    <Page odd={odd}>
      <Container show={show}>
        <Title>Lets Go!</Title>
        <CheckText>Submit your project. You'll be able to upload your zip file directly to your project once it's submitted. Just look for the <Icon name='upload' /> Icon. </CheckText>
        <Button show odd={odd} type="submit" onClick={handleClick}>Submit</Button>
      </Container>
    </Page>
  )
}

export default LetsGoPage