import React, { useState, useEffect, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import { DateTime } from "luxon";
import { connect } from 'react-redux'
import { 
    addNotification, 
    setDrawerView, 
    setShowOverlay, 
    fetchUsers, 
    selectUser, 
    clearUsers, 
    changeUserRole, 
    changeUserSearchTerm,  
    downloadUserCSV 
} from '../../../actions'

import { Button, Table, Icon, Dropdown, Header, Input, Loader } from 'semantic-ui-react'
import { blue } from '../../Shared/colors'

import styled from 'styled-components'

const Container = styled.div` 
    height: 100%;
    width: ${props => props.mobile ? 'calc(100% - 60px)' : '100%'};
    overflow-x: hidden;
    font-family: Roboto;
    margin-top: 50px;
    ${props => props.mobile && 'margin-left: 60px;'}
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: ${props => props.fadeIn ? '1' : '0'};
    transition: left .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .4s ease-out;

    & .infinite-scroll-component__outerdiv {
        width: 100%;
    }

    @media only screen and (max-width1160pxpx) {
        padding: 60px 20px;
        width: calc(100vw - 70px);
    }
`

const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    & h5 {
        margin-top: 0 !important;
    }

    & div:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
    }

    & i {
        margin: 0 !important;
    }

    @media only screen and (max-width: 1160px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &>div {
            width: 100%;
            text-align: center;
        }
    }
`

const StyledHeader = styled(Header)`
    display: inline;
    margin-bottom: 0;
`

const StyledInput = styled(Input)`
    display: flex !important;
    width: 250px;
`

const StyledTable = styled(Table)`
    margin-top: 20px !important;
    border: none !important;
`

const StyledTableHeader = styled(Table.Header)`
    display: table;
    width: 100%;
    table-layout: fixed;
    width: calc( 100% - 1em ) !imnportant;
    
    @media only screen and (max-width: 767px) {
        display: none !important;
    }
`

const TableHeaderCell = styled(Table.HeaderCell)`
    background: ${blue} !important;
    color: #fff !important;
    position: sticky !important;
    top: 0;

    & i {
        margin-left: 5px;
    }

     @media only screen and (max-width: 1160px) {
        text-align: center !important;
    }
`

const StyledTableBody = styled(Table.Body)`
    display: block;
    height: 65vh;
    overflow: auto;
    
    & tr {
        background: rgba(0,0,0,.05);
        border: none;
     }
 
     & tr:nth-of-type(even) {
         background: rgba(0,11,133, .1);
     }

     & tr:hover {
        background: ${blue};
        color: #fff;
        opacity: .9;
     }
 

    @media only screen and (max-width: 1160px) {
        max-height: 75vh !important;
    }
`

const UserRow = styled(Table.Row)`
    display: table;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;

    @media only screen and (max-width: 1160px) {
        & td {
            text-align: center !important;
        }
    }
`

const UserView = ({ 
    currentUser,
    selectedUser,
    selectUser,
    fetchUsers,
    clearUsers,
    changeUserRole,
    changeUserSearchTerm,
    showMenu, 
    setDrawerView, 
    setShowOverlay, 
    users,
    count,
    mobile
}) => {
    
    const [fadeIn, updateFadeIn] = useState(false)
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [userSearchTerm, setUserSearchTerm] = useState(null)
    const [userRole, setUserRole] = useState('client')
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState('firstName')
    const [sortDirection, setSortDirection] = useState('asc')

    useEffect(() => {
        updateFadeIn(true)
    }, [], () => {
        clearUsers()
    })

    useEffect(() => {
        try {
            fetchUsers(userRole, 1, sortBy, sortDirection, userSearchTerm)
        } catch (e) {
            addNotification(e)
        }
    }, [sortBy, sortDirection, userRole])
    
    useEffect(() => {
        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(() => {
            fetchUsers(userRole, 1, sortBy, sortDirection, userSearchTerm)
        }, 500))
    }, [userSearchTerm])

    useEffect(() => {
        fetchUsers(userRole, page, sortBy, sortDirection, userSearchTerm, true)
    }, [page])

    const handleClick = async id => {
        await selectUser(id)
        await setDrawerView('user')
        setShowOverlay(true)
    }

    const handleSort = val => {
        if (val === sortBy) {
            let newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
            setSortDirection(newSortDirection)
        } else {
            setSortBy(val)
            setSortDirection('asc')
        }
    }

    const handleRoleChange = async (e, { value }) => {
        setUserRole(value)
        setPage(1)
        try {
            fetchUsers(value, 1, sortBy, 'asc', userSearchTerm)
        } catch (e) {
            addNotification(e)
        }
    }

    const handleUpdateSearchTerm = async (e, { value }) => {
        setUserSearchTerm(value)
    }

    const fetchMoreData = async () => {
        setPage(page + 1)
    };

    const roleOptions = [
        { key: 1, text: 'Clients', value: 'client' },
        { key: 2, text: 'Editors', value: 'editor' },
        { key: 3, text: 'Admins', value: 'admin'},
        { key: 4, text: 'Devs', value: 'dev'},
    ]

    const allRoleOptions = [
        { key: 1, text: 'Clients', value: 'client' },
        { key: 2, text: 'Editors', value: 'editor' },
        { key: 3, text: 'Admins', value: 'admin'},
        { key: 4, text: 'Devs', value: 'dev'},
        { key: 5, text: 'Super', value: 'super'}
    ]

    return (
        <Container showMenu={showMenu} fadeIn={fadeIn} mobile={mobile}>
            <Title>
                <div>
                    <StyledHeader as='h2'>
                        {!['super'].includes(currentUser.role) &&
                        <Dropdown 
                            options={roleOptions}
                            text={userRole[0].toUpperCase() + userRole.substring(1) + 's'}
                            onChange={handleRoleChange}
                        />}
                        {['super'].includes(currentUser.role) &&
                        <Dropdown 
                            options={allRoleOptions}
                            text={userRole[0].toUpperCase() + userRole.substring(1) + 's'}
                            onChange={handleRoleChange}
                        />}
                    </StyledHeader>
                    <Header as='h5'>{count} matching {userRole}{count !== 1 ? 's' : ''}</Header>
                </div>
                <div>
                    { ['super', 'admin'].includes(currentUser.role) && <Button onClick={() => downloadUserCSV(userRole)}>
                        <Icon name='table'/>
                    </Button> }
                    <StyledInput 
                        size='large' 
                        icon='search' 
                        placeholder='Search...'
                        onChange={handleUpdateSearchTerm}
                        value={userSearchTerm}
                    />
                </div>
                
            </Title>
            <InfiniteScroll
                style={{ width: '100%'}}
                dataLength={users.length} //This is important field to render the next data
                next={fetchMoreData}
                hasMore={users.length < count}
                scrollableTarget="scrollableDiv"
                loader={<Loader />}
            >
                <StyledTable celled sortable stackable>
                    <StyledTableHeader fullWidth>
                        <Table.Row>
                            <TableHeaderCell onClick={() => handleSort('firstName')}>
                                Name
                                { sortBy === 'firstName' && <Icon name={sortDirection === 'asc' ? 'triangle up' : 'triangle down'} />}
                            </TableHeaderCell>
                            <TableHeaderCell width={8}>Email</TableHeaderCell>
                            <TableHeaderCell onClick={() => handleSort('createdAt')}>
                                Joined
                                {sortBy === 'joinedDate' && <Icon name={sortDirection === 'asc' ? 'triangle up' : 'triangle down'} /> }
                            </TableHeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <StyledTableBody id="scrollableDiv">
                        { users.map(user => (
                            <UserRow onClick={() => handleClick(user._id)}>
                                <Table.Cell>{user.fullName}</Table.Cell>
                                <Table.Cell width={8}>{user.email}</Table.Cell>
                                <Table.Cell>{(user.createdAt !== '1900-01-01T00:00:00.000Z' && DateTime.fromISO(user.createdAt).toLocaleString()) || 'Unavailable'}</Table.Cell>
                            </UserRow>
                        ))}
                    </StyledTableBody>
                </StyledTable>
            </InfiniteScroll>
        </Container>
    )
}

    

const mapStateToProps = state => {
    return { 
        showMenu: state.view.showMenu,
        currentUser: state.user.currentUser,
        selectedUser: state.user.selectedUser,
        loadingUsers: state.user.loadingUsers,
        role: state.user.role,
        mobile: state.index.mobile,
        users: state.user.users,
        searchTerm: state.user.searchTerm,
        sortBy: state.user.sortBy,
        sortDirection: state.user.sortDirection,
        page: state.user.page,
        count: state.user.count
    }
}

export default connect(mapStateToProps, 
    { 
        selectUser, 
        setDrawerView, 
        setShowOverlay, 
        fetchUsers, 
        changeUserRole, 
        changeUserSearchTerm, 
        downloadUserCSV,
        clearUsers
    })(UserView)
