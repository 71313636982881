import React, { Component } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { updateUser } from '../../actions'

import ToS from './OnboardSlides/ToS'
import Bio from './OnboardSlides/Bio'

const Container = styled.div`
    width: 100%;
    height: 100%;
    align-self: center;
    padding: 40px;
`

class Onboard extends Component {
    constructor(props) {
        super(props)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.state = { page: 1 }
        if (props.currentUser.tosAccepted && props.currentUser.role === 'client')
            this.state = { page: 2 }        
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 })
    }
    
    previousPage() {
        this.setState({ page: this.state.page - 1 })
    }

    handleSubmit = async e => {
        e.preventDefault()
        try {
            await updateUser(this.props.currentUser._id, { completedOnboarding: true }, true)
        } catch (e) {
            console.log('Something went wrong.')
        }
    }

    render() {
        const { page } = this.state
        return (
            <Container>
                {page === 1 && <ToS onSubmit={this.nextPage} back={this.previousPage} />}
                {page === 2 && <Bio odd back={this.previousPage} />}
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return { 
        currentUser: state.user.currentUser,
        onboard: state.form.onboard
     }
}

export default connect(mapStateToProps, { updateUser })(Onboard)

