import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setModalView, updateProject, addNotification, createComment } from '../../actions'
import styled from 'styled-components'
import { Icon, Button, Popup } from 'semantic-ui-react'

import { initializeApp } from 'firebase/app';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    & select {
        height: 30px;
        width: 80px;
        font-size: 1rem;
        padding: 5px;
        margin: 5px 0 ;
    }

    & select:focus {
      outline: none;
    }

    & textarea {
        height: 150px;
        width: 90%;
        font-size: 1rem;
        padding: 5px;
        margin: 5px 5px 20px 5px;
        border-radius: 5px;
    }

    & textarea:focus {
      outline: none;
    }
`

const Title = styled.h2`
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 15px;
    color: #fff;
    font-family: Krub;
`

const StyledLabel = styled.label`
  background-color: lightgrey;
  color: #000;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: ${props => props.disabled !== 'true' && 'pointer'};
  margin-top: 1rem;
  opacity: ${props => props.disabled === 'true' && '.6'};
  &:hover {
    opacity: .6;
  }
`

const EditorUpload = ({ currentUser, selectedProject, addNotification, updateProject, setModalView, createComment }) => {
    const [storage, setStorage] = useState(null)
    const [editorNotes, setEditorNotes] = useState('')

    useEffect(() => {
        const app = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: `hawkinspng-37410.firebaseapp.com`,
            projectId: 'hawkinspng-37410',
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
        })

        const appStorage = getStorage(app)
        setStorage(appStorage)
    }, [])

    const uploadFile = e => {
        const { files, name } = e.target

        if (storage) {

            createComment(selectedProject._id, {
                author: {
                    name: currentUser.fullName,
                    role: currentUser.role
                },
                type: 'Comment',
                topic: "Editor Submission Notes",
                text: editorNotes,
                viewableTo: ['admin', 'super', 'dev', 'editor']
            })

            const uploadRef = ref(storage, `Projects/${selectedProject.name}/${selectedProject.name}-${name}`)
            const uploadTask = uploadBytesResumable(uploadRef, files[0], { contentType: 'application/zip' })
            setModalView(null)
            updateProject(selectedProject._id, { state: 5 })

            uploadTask.on('state_changed', ({ bytesTransferred, totalBytes, state, metadata, task, ref }) => {
                const uploadProgress = (bytesTransferred / totalBytes) * 100
                if (Math.floor(uploadProgress) % 5 === 0) {
                    updateProject(selectedProject._id, { uploadProgress })
                }
            }, ({ code }) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
            }, () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
                    try {
                        updateProject(selectedProject._id, { workedUpload: downloadURL, state: 6, uploadProgress: null })
                        addNotification({ type: 'success', message: 'File uploaded'})
                    } catch (e) {
                        addNotification({ type: 'error', message: 'Error updating project. Please try again.'})
                    }
                });
            })
        }
    }

    const FileField = ({ version, reupload }) => (
      <div>
          <input
            hidden
            disabled={Boolean(editorNotes.length === 0)}
            type='file'
            id={'workedUpload'} 
            name={'workedUpload.zip'} 
            accept='application/zip' 
            onChange={uploadFile}
          />
          <StyledLabel htmlFor='workedUpload' disabled={Boolean(editorNotes.length === 0)} >
            <Popup content={`${reupload ? 'Re-U' : 'U'}pload`} trigger={<Icon name='upload' />} />
          </StyledLabel>
      </div>
    )
  
    return (
        <React.Fragment>
            <Form>
                <Title>Submission Notes</Title>
                <textarea name="editorNotes" onChange={e => setEditorNotes(e.target.value)} placeholder="..." value={editorNotes} />
                <FileField version='workedUpload' reupload={Boolean(selectedProject.workedUpload)}/>
            </Form>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        mobile: state.index.mobile,
        editorNotes: state.project.editorNotes,
        selectedProject: state.project.selectedProject
    }
}

export default connect(mapStateToProps, { setModalView, updateProject, addNotification, createComment })(EditorUpload)