import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import validate from '../validate'
import { blue, white } from '../../../Shared/colors'

const Page = styled.div`
  background: ${blue};
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 20px;
`

const Title = styled.p`
  font-size: 2rem;
  font-family: Krub;
  font-weight: 700;
  margin-bottom: 20px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & label {
    line-height: 25px;
  }
`

const Container = styled.label`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 100%;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
`

const StyledField = styled.div`
  font-size: 1.3rem;
  font-family: Roboto;
  & > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 5px;
  }
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  opacity: ${props => props.show ? 1 : 0 };
  transition: opacity 500ms ease-out;
  align-self: center;
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const Placeholder = styled.div`
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
`

const Wipe = styled.div`
  position: absolute;
  width: ${props => props.grow ? '1200px' : '0'};
  height: ${props => props.grow ? '1200px' : '0'};
  border-radius: 50%;
  background: ${white};
  transition: height 350ms ease-in, width 350ms ease-in;
`

const Radio = styled.input`
  height: 20px;
  width: 20px;
`

const EditCullDiv = styled.label`
  display: flex;
  align-items: center;
`

const renderField = ({ input, label, meta: { touched, error } }) => (
  <div>
    <Container>
        <Radio {...input} type='radio' />
        { label }
    </Container>
  </div>
)

let editCullOption = false

const ProjectTypePage = ({ handleSubmit, service, odd }) => {

  const [grow, setGrow] = useState(false)
  const [show, setShow] = useState(false)
  const [showNext, setShowNext] = useState(false)

  useEffect(() => {
    setTimeout(() => setShow(true), 1)
  }, [])

  useEffect(() => {
    if (!service)
      setShowNext(false)
    else
      setTimeout(() => setShowNext(true), 1)
  }, [service])


  const handleNext = e => {
    e.preventDefault()
    setGrow(true)
    setTimeout(handleSubmit, 350)
  }

  const renderNext = () => {
    return <Button odd={odd} next show={showNext} onClick={handleNext} className="next">Next</Button>
  }

  return (
    <Page className='page' show={show}>
      <Title>Start a New Project</Title>
      <Form onSubmit={handleSubmit}>
          <StyledField>
            <Field
                label="Editing"
                name="service"
                type="radio"
                component={renderField}
                value="editing"
              />
          </StyledField>
          <StyledField>
            <Field
                label="Culling"
                name="service"
                type="radio"
                component={renderField}
                value="culling"
              />
          </StyledField>
          <StyledField>
            <Field
                label="Editing & Culling"
                name="service"
                type="radio"
                component={renderField}
                value="editing and culling"
            />
          </StyledField>
          <StyledField>
            <Field
                label="Retouching"
                name="service"
                type="radio"
                component={renderField}
                value="retouching"
              />
          </StyledField>
          { service ? renderNext() : <Placeholder /> }
      </Form>
      { editCullOption && <EditCullDiv>A paired edit and cull project will add an additional 24 hours to our standard 72-hour turnaround within business hours.</EditCullDiv> }
      <Wipe grow={grow}/>
    </Page>
  )
}

const mapStateToProps = state => {
  if (state.form.newProject.values && state.form.newProject.values.service === 'editing and culling') {
    editCullOption = true
  } else {
    editCullOption = false
  }

  return {
    service: state.form.newProject.values ? state.form.newProject.values.service : null
  }
}

const connected = connect(mapStateToProps)(ProjectTypePage)

export default reduxForm({
    form: 'newProject', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(connected)
  