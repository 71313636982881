import {
    ADD_NOTIFICATION,
    CLEAR_NOTIFICATION
} from './types'

export const addNotification = notification => async dispatch => {
    return dispatch({ type: ADD_NOTIFICATION, payload: notification })
}

export const clearNotification = id => async dispatch => {
    return dispatch({ type: CLEAR_NOTIFICATION, payload: id })
}