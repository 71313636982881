import {
    ERROR,
    SET_MOBILE
  } from "../actions/types";

export const setError = error => async dispatch => {
    return dispatch({ type: ERROR, payload: error })
}

export const setMobile = mobile => async dispatch => {
  return dispatch({ type: SET_MOBILE, payload: mobile })
}

export * from './commentActions'
export * from './googleActions'
export * from './projectActions'
export * from './userActions'
export * from './viewActions'
export * from './notificationActions'
export * from './historyActions'

