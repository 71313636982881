module.exports = str => {
    const words = str.split(' ')
    try {
        if (words.length) {
            return words.map(word => {
                if (word.length > 0)
                    return word[0].toUpperCase() + word.substring(1)
                else return word[0].toUpperCase()
            }).join()
        } else return ''
    } catch (e) {
        return ''
    }
}