import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import ProgressBar from 'react-customizable-progressbar';
import styled from 'styled-components';
import {connect} from 'react-redux';

import StatusChecks from './StatusChecks'

import {
  addNotification,
  selectProject,
  setDrawerView,
  setShowOverlay,
  updateProject,
  fetchProject,
  addFetchInterval
} from '../../../actions';

import { Table } from 'semantic-ui-react';

const UserRow = styled (Table.Row)`
  width: 100%;
  cursor: pointer;
  text-transform: capitalize;

  & td {
    border-top: ${props => {
      if (props.daytransition == true)
        return '2px solid #000 !important'
    }};
  }
`;

const StyledTableCell = styled (Table.Cell)``;

const StyledProgressBar = styled (ProgressBar)`
  width: 100px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: cetner;
  font-size: 14px;
  margin-right: -20px;
`;

const Project = ({
  project,
  currentUser,
  selectProject,
  setDrawerView,
  setShowOverlay,
  fetchProject,
  addFetchInterval,
  sortProjectsBy,
  projects,
  i,
  sortDirection
}) => {

  useEffect(() => {
    fetchProject(project._id)
  }, [])

  const handleClick = async id => {
    await selectProject (id);
    setDrawerView ('project');
    setShowOverlay (true);
  };

  const renderProjectState = project => {
    switch (project.state) {
      case 1:
        return 'Awaiting Upload';
      case 2:
        return (
          <StyledProgressBar
            progress={project.uploadProgress}
            strokeWidth={3}
            trackStrokeWidth={3}
            radius={11}
          >
            <span>{Math.floor (project.uploadProgress)}%</span>
          </StyledProgressBar>
        );
      case 3:
        return 'New';
      case 4:
        return 'In Editing';
      case 5:
        return (
          <StyledProgressBar
            progress={project.uploadProgress}
            strokeWidth={3}
            trackStrokeWidth={3}
            radius={11}
          >
            <span>{Math.floor (project.uploadProgress)}%</span>
          </StyledProgressBar>
        );
      case 6:
        return 'In Review';
      case 7:
        return 'Complete';
      default: 
        return
    }
  };

  const determineDemarkation = () => {
    if (i > 0) {
      const dif = moment (project[sortProjectsBy]).tz('america/los_angeles').endOf('day').diff (
        moment(projects[i - 1][sortProjectsBy]).tz('america/los_angeles').endOf('day'),
        'minutes'
      )

      const notUploadingOrTBD = projects[i - 1][sortProjectsBy] && (!projects[i - 1][sortProjectsBy].uploading && moment (projects[i - 1][sortProjectsBy]).year() !== 3000)

      return dif !== 0 && dif != NaN && notUploadingOrTBD
    }
  }

  return (
    <UserRow
      daytransition={determineDemarkation() }
      key={project._id}
      onClick={() => handleClick (project._id)}
    >
      <StyledTableCell className="headCol">
        {project.name}
      </StyledTableCell>
      {['admin', 'dev', 'super'].includes (currentUser.role) &&
        <StyledTableCell className="tableValue">
          <StatusChecks project={project} />
        </StyledTableCell>}
      {['admin', 'super', 'dev'].includes (currentUser.role) &&
        <StyledTableCell className="tableValue">
          {project.client?.fullName}
        </StyledTableCell>}
      {['admin', 'super', 'dev'].includes (currentUser.role) &&
        <StyledTableCell className="tableValue">
          {project.editor?.fullName || 'Unassigned'}
        </StyledTableCell>}
      <StyledTableCell className="tableValue">
        { project.service }
      </StyledTableCell>
      <StyledTableCell className="tableValue">
        {renderProjectState (project)}
      </StyledTableCell>
      {project.uploadDate && moment(new Date()).add(1, 'year') > moment(project.uploadDate) ?
        <StyledTableCell className="tableValue">
            {moment (project.uploadDate).format ('MM/DD/YYYY hh:MMa')}
          </StyledTableCell>
        : <StyledTableCell className="tableValue">TBD</StyledTableCell>}
      {project.createdAt ?
        <StyledTableCell className="tableValue">
            {moment (project.createdAt).format ('MM/DD/YYYY hh:MMa')}
          </StyledTableCell>
        : <StyledTableCell className="tableValue"></StyledTableCell>}
      {!['client'].includes (currentUser.role) &&
        (project.editorDeadline && moment(new Date()).add(1, 'year') > moment(project.editorDeadline) ?
          <StyledTableCell className="tableValue" style={project.rushApproved ? { color: 'red' } : {}}>
              {moment (project.editorDeadline).format ('MM/DD/YYYY')}
            </StyledTableCell>
          : <StyledTableCell className="tableValue">
              TBD
            </StyledTableCell>)}
      {!['editor'].includes (currentUser.role) &&
        (project.deliveryDate && moment(new Date()).add(1, 'year') > moment(project.editorDeadline) ?
            <StyledTableCell className="tableValue" style={project.rushApproved ? { color: 'red' } : {}}>
              {moment (project.deliveryDate).format ('MM/DD/YYYY')}
            </StyledTableCell>
          : <StyledTableCell className="tableValue">
              TBD
            </StyledTableCell>)}
    </UserRow>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

export default connect (mapStateToProps, {
  selectProject,
  setDrawerView,
  setShowOverlay,
  updateProject,
  fetchProject,
  addNotification,
  addFetchInterval
}) (Project);
