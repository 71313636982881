import axios from 'axios'
import queryString from 'query-string'

import { addNotification } from './notificationActions'

import {
    SELECT_PROJECT,
    CREATE_PROJECT,
    FETCH_PROJECTS,
    SET_LOADING_PROJECTS,
    DELETE_PROJECT,
    UPDATE_PROJECT,
    UPDATE_DATE,
    SET_UPLOAD_PROGRESS,
    SET_UPLOAD_TYPE,
    SET_EDITOR_NOTES,
    SET_UPLOAD,
    SET_SUBMITTING_NOTES,
    SET_SUBMISSION_NOTES,
    SET_UPLOAD_START,
    SET_UPLOAD_REMAINING_TIME,
    NEXT_PROJECT_PAGE,
    SORT_PROJECTS_BY,
    CHANGE_PROJECT_SEARCH_TERM,
    CHANGE_PROJECT_STATE_FILTER,
    CLEAR_PROJECTS,
    SET_FETCH_INTERVAL,
    SET_PAGE,
    ADD_FETCH_INTERVAL,
    CLEAR_FETCH_INTERVALS,
    UPDATE_SEARCH_TERM,
    SET_REFRESH_INTERVAL,
    ESTIMATE_DEADLINE
} from './types'

export const fetchProjects = (page, sortBy, sortDirection, searchTerm, stateFilter) => async dispatch => {
    
    let url = `/api/projects?page=${page}`
    sortBy && (url += `&sortBy=${sortBy}`)
    sortDirection && (url += `&sortDirection=${sortDirection}`)
    searchTerm && (url += `&searchTerm=${searchTerm}`)
    stateFilter && (url += `&state=${stateFilter}`)
     
    try {
        const response = await axios.get(url)
        return dispatch({ type: FETCH_PROJECTS, payload: response.data })
    } catch (e) {
        throw new Error(e)
    }
}

export const fetchProject = id => async dispatch => {
    try {
        const response = await axios.get(`/api/projects/${id}`)
        return response.data
    } catch (e) {
        if (e.response.status !== 404)
            throw new Error(e)
    }
}

export const selectProject = id => async dispatch => {
    try {
        const response = await axios.get(`/api/projects/${id}`)
        return dispatch({ type: SELECT_PROJECT, payload: response.data})
    } catch (e) {
        throw new Error(e)
    }
}

export const createProject = (newProject, user) => async dispatch => {
    try {
        const response = await axios.post('/api/projects', { newProject, user })
        dispatch({ type: CREATE_PROJECT, payload: response.data })
        return dispatch(addNotification({ type: 'success', message: 'Project created' }))
    } catch (e) {
        throw new Error(e)
    }
}

export const updateProject = (id, query) => async dispatch => {
    if (query && Object.keys(query)) {
        Object.keys(query).forEach(key => {
            if (typeof(query[key]) === 'object') {
                query[key] = JSON.stringify(query[key])
            }
        })

        const url = `/api/projects/${id}?${queryString.stringify(query)}`

        try {
            const response = await axios.put(url)
            dispatch({ type: UPDATE_PROJECT, payload: response.data})
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    } 
}

export const deleteProject = id => async dispatch => {
    try {
        await axios.delete(`/api/projects/${id}`)
        dispatch({ type: DELETE_PROJECT, payload: id})
    } catch (e) {
        throw new Error(e)
    }
}

export const approveOrRejectRushRequest = (id, approved) => async dispatch => {
    try {
        const response = await axios.put(`/api/projects/${id}?rushApproved=${approved}`)
        dispatch({ type: UPDATE_PROJECT, payload: response.data})
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

export const getEstimatedDeadline = projectLength => async dispatch => {
    try {
        const response = await axios.get(`/api/projects/estimate-deadlines?projectLength=${projectLength}`)
        dispatch({ type: ESTIMATE_DEADLINE, payload: response.data})
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

export const updateDate = date => dispatch => {
    return dispatch({ type: UPDATE_DATE, payload: date })
}

export const setLoadingProjects = () => dispatch => {
    return dispatch({ type: SET_LOADING_PROJECTS, payload: true })
}

export const setUploadProgress = progress => dispatch => {
    return dispatch({ type: SET_UPLOAD_PROGRESS, payload: progress })
}

export const setUploadRemainingTime = remainingTime => dispatch => {
    return dispatch({ type: SET_UPLOAD_REMAINING_TIME, payload: remainingTime })
}

export const setUploadType = type => dispatch => {
    return dispatch({ type: SET_UPLOAD_TYPE, payload: type })
}

export const setEditorNotes = notes => dispatch => {
    return dispatch({ type: SET_EDITOR_NOTES, payload: notes})
}

export const setUpload = upload => dispatch => {
    return dispatch({ type: SET_UPLOAD, payload: upload })
}

export const setUploadStart = start => dispatch => {
    return dispatch({ type: SET_UPLOAD_START, payload: start ? new Date() : null })
}

export const setSubmittingNotes = submitting => dispatch => {
    return dispatch({ type: SET_SUBMITTING_NOTES, payload: submitting })
}

export const setSubmissionNotes = notes => dispatch => {
    return dispatch({ type: SET_SUBMISSION_NOTES, payload: notes })
}

export const nextProjectPage = () => dispatch => {
    dispatch({ type: NEXT_PROJECT_PAGE })
}

export const sortProjectsBy = type => async dispatch => {
    dispatch({ type: SORT_PROJECTS_BY, payload: type })
}

export const changeProjectSearchTerm = term => async dispatch => {
    return dispatch({ type: CHANGE_PROJECT_SEARCH_TERM, payload: term })
}

export const changeProjectStateFilter = state => async dispatch => {
    return dispatch({ type: CHANGE_PROJECT_STATE_FILTER, payload: state })
}

export const clearProjects = () => dispatch => {
    return dispatch({ type: CLEAR_PROJECTS })
}

export const setFetchInterval = fetchInterval => dispatch => {
    return dispatch({ type: SET_FETCH_INTERVAL, payload: fetchInterval })
}

export const setPage = page => dispatch => {
    return dispatch({ type: SET_PAGE, payload: page })
}

export const addFetchInterval = interval => dispatch => {
    return dispatch({ type: ADD_FETCH_INTERVAL, payload: interval })
}

export const clearFetchIntervals = () => dispatch => {
    return dispatch({ type: CLEAR_FETCH_INTERVALS })
}

export const updateSearchTerm = searchTerm => dispatch => {
    return dispatch({ type: UPDATE_SEARCH_TERM, payload: searchTerm })
}

export const setRefreshInterval = interval => dispatch => {
    return dispatch({ type: SET_REFRESH_INTERVAL, payload: interval })
}



