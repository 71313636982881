import {
    SELECT_EVENT
} from '../actions/types'

const initialState = {
    loading: false,
    events: [], 
    selectedEvent: null 
}

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_EVENT:
            return { ...state, selectedEvent: action.payload }
        default:
            return state
    }
}

export default userReducer