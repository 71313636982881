import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from "react-pdf";
import styled from 'styled-components'
import { Header, Icon } from 'semantic-ui-react'
import EditorChecklist from './documents/editor_checklist.pdf'
import ReviewerChecklist from './documents/reviewer_checklist.pdf'
import ExportingGuide from './documents/editor_exporting.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div` 
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: Roboto;
    display: flex;
    gap: 20px;
    margin-top: 50px;
    padding: 20px;
    width: ${props => '100%'};
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: ${props => props.fadeIn ? '1' : '0'};
    transition: left .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .4s ease-out;

    & span {
        margin-bottom: 25px !important;
    }

    & span h2 {
        display: inline;
    }

    & span i {
        position: relative;
        bottom: 10px;
        left: 5px;
    }

    @media only screen and (max-width1160pxpx) {
        padding: 60px 20px;
        width: calc(100vw - 70px);
    }
`

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
`

const PDFDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(0,0,0,.7);
    text-align: center;
    gap: 10px;
    justify-content: center;
    align-items: center;
`  

const Label = styled.a`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;

    &:hover {
        color: rgba(255,255,255,.7);
    }
`   

const Checklist = ({ docType }) => {

    const [fadeIn, setFadeIn] = useState(false)

    useEffect(() => {
        setFadeIn(true)
    }, [])

    


     return (
        <Container fadeIn={fadeIn}>
            <Header as='h2'>Guides</Header>
            <Content>
                <PDFDiv>
                    <Document file="/documents/editor_checklist.pdf" style={{ opacity: .5}}>
                        <Page pageNumber={1} height={360} />
                    </Document>
                    <Overlay />
                    <Label href="/documents/editor_checklist.pdf" download >
                        <Icon size='huge' name='file pdf outline' />
                        <p>Editor Checklist</p>
                    </Label>
                </PDFDiv>
                <PDFDiv>
                    <Document file="/documents/editor_exporting.pdf" style={{ opacity: .5}}>
                        <Page pageNumber={1} height={360} />
                    </Document>
                    <Overlay />
                    <Label href="/documents/editor_exporting.pdf" download >
                        <Icon size='huge' name='file pdf outline' />
                        <p>Exporting Guide</p>
                    </Label>
                </PDFDiv>
                <PDFDiv>
                    <Document file="/documents/reviewer_checklist.pdf" style={{ opacity: .5}}>
                        <Page pageNumber={1} height={360} />
                    </Document>
                    <Overlay />
                    <Label href="/documents/reviewer_checklist.pdf" download >
                        <Icon size='huge' name='file pdf outline' />
                        <p>Reviewing Checklist</p>
                    </Label>
                </PDFDiv>
            </Content>
        </Container>
    )
}



export default Checklist
    