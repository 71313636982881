import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Bubble from '../../Shared/Bubble'
import ReactTooltip from 'react-tooltip';
import { error } from '../../Shared/colors'

import { 
    createComment, 
    setViewableTo, 
    addNotification, 
    setUploadProgress, 
    setUploadType, 
    setSubmittingNotes, 
    setSubmissionNotes, 
    setCommentText 
} from '../../../actions'



const Container = styled.div`
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    justify-content: flex-start;
`

const MessageBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Krub;
    display: grid;
    align-items: center;
`

const TextAreaContainer = styled.div`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Roboto;
    font-weight: 200;
    margin-top: 5px;
    display: grid;
    grid-template: 1fr / 1fr;
    &  textarea {
        border-radius: 5px;
        outline: none;
        border: none;
        height: ${props => props.submitting ? '150px' : '30px'};
        padding: 3px;
        transition: height 200ms ease-out;
        resize: none;
        &:focus {
            height: 150px;
        }
    }
`

const ToolBar = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;
    & button {
        margin-right: 0;
        margin-left: 5px;
    }
`

const RoleSelector = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: auto;
`

const Button = styled.button`
    border: none;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #888, inset 0 0 5px #ccc;
    font-family: Roboto;
    font-size: ${props => props.mobile ? '.68rem' : '.8rem'};
    background: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 2px #888;
    }
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`

const FileButton = styled(Button)`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`

const Cancel = styled(FileButton)`
    font-family: Roboto;
    background: ${error};
    color: #fff;
    font-weight: 500;
    font-size: .9rem;
`


const NewComment = ({  
    selectedProject,
    currentUser, 
    placeholder, 
    createComment, 
    addNotification,
    submittingNotes,
    setSubmittingNotes,
    setSubmissionNotes,
    setCommentText,
    commentText
}) => {

    const textAreaRef = useRef()
    const [audience, setAudience] = useState(selectedProject.editor ? 'editor' : null)

    
    useEffect(() => {
        if(submittingNotes)
            textAreaRef.current.focus()
        setCommentText('')
    }, [submittingNotes, setCommentText])

    const selectAudience = role => {
        setAudience(role)
        setViewableTo(['admin', 'super', 'dev', role])
    }

    const handleNewComment = async text => {

        const viewableTo = ['admin', 'super', 'dev', currentUser.role]

        if (['admin', 'super', 'dev'].includes(currentUser.role)) {
            viewableTo.push(audience)
        } else if (currentUser.role === 'client') {
            viewableTo.push('editor')
        }
        
        const comment = {
            author: {
                name: currentUser.fullName,
                role: currentUser.role
            },
            viewableTo,
            topic: submittingNotes ? `Submission Notes` : 'Comment',
            text,
            
        }
        
        try {   
            await createComment(selectedProject._id, comment)
        } catch (e) {
            addNotification({ type: 'error', message: 'Error submitting comment' })
        }

        setSubmittingNotes(false)
        setSubmissionNotes(null)
        setCommentText('')
    }

    const renderRoleSelector = () => {
        return (
            <RoleSelector>
                <Bubble selection dataFor='clientSelect' dataTip="Client" selected={audience === 'client'} initials='C'onClick={() => selectAudience('client')}/>
                <ReactTooltip
                    id='clientSelect'
                    effect="solid"
                    place="right"
                />
                
                { selectedProject.editor && 
                    <>
                        <Bubble selection dataFor='editorSelect' dataTip="Editor" selected={audience === 'editor'} initials='E' onClick={() => selectAudience('editor')}/>
                        <ReactTooltip
                            id='editorSelect'
                            effect="solid"
                            place="right"
                        />
                    </>
                }
            </RoleSelector>
        )
    }

    return (
        <Container>
            <MessageBox>
                <TextAreaContainer>
                    <textarea
                        name='commentText'
                        ref={textAreaRef}
                        autoComplete="off"
                        placeholder={submittingNotes ? 'Submission Notes' : 'Leave a comment...'}
                        onChange={async e => setCommentText(e.target.value)}
                        value={ commentText || '' }
                    />
                </TextAreaContainer>
                <ToolBar>
                    { ['admin', 'super', 'dev'].includes(currentUser.role) &&  renderRoleSelector() }
                    <button
                        disabled={(['admin', 'super', 'dev'].includes(currentUser.role) && !audience) || !commentText || (commentText && commentText.length === 0) }
                        style={{ 
                            visibility: submittingNotes ? 'hidden' : 'visible', 
                            width: submittingNotes ? '0' : '100px',
                            padding: submittingNotes ? 0 : '5px',
                            margin: submittingNotes ? 0 : 'inherit'
                        }}
                        onClick={() => handleNewComment(commentText)}>
                        Send
                    </button> 
                    { submittingNotes && 
                        <React.Fragment>
                            <ReactTooltip
                                id="worked"
                                effect="solid"
                                place="right"
                            />   
                        </React.Fragment>
                    }
                    { submittingNotes && 
                        <React.Fragment>
                            <Cancel data-for='cancel' data-tip="Cancel" onClick={() => {
                                setSubmissionNotes(null)
                                setSubmittingNotes(false)
                                setCommentText(null)
                            }}>X</Cancel>
                            <ReactTooltip
                                id="cancel"
                                effect="solid"
                                place="right"
                            />  
                        </React.Fragment> 
                    }
                </ToolBar>
            </MessageBox>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        selectedProject: state.project.selectedProject,
        viewableTo: state.comment.viewableTo,
        submittingNotes: state.project.submittingNotes,
        mobile: state.index.mobile,
        submissionNotes: state.project.submissionNotes,
        commentText: state.comment.commentText
    }
}

export default connect(mapStateToProps, { createComment, setViewableTo, addNotification, setUploadProgress, setUploadType, setSubmittingNotes, setSubmissionNotes, setCommentText })(NewComment)

