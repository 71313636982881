export const SET_LOADING = 'SET_LOADING'
export const ERROR = 'ERROR'
export const SET_REFRESH_INTERVAL = 'SET_REFRESH_INTERVAL'

// AUTH ACTIONS
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_UP = 'SIGN_UP'
export const CONTRACT_SIGNED = 'CONTRACT_SIGNED'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'

// CALENDAR ACTIONS
export const SET_CALENDAR_LOADING = 'SET_CALENDAR_LOADING'
export const SET_CALENDAR_ERROR = 'SET_CALENDAR_ERROR'
export const SELECT_EVENT = 'SELECT_EVENT'

// COMMENT ACTIONS
export const SET_COMMENTS_LOADING = 'SET_COMMENTS_LOADING'
export const SET_COMMENTS_ERROR = 'SET_COMMENTS_ERROR'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const FETCH_COMMENT = 'FETCH_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const EDIT_COMMENT = 'EDIT_COMMENT'
export const SET_VIEWABLE_TO = 'SET_VIEWABLE_TO'
export const RESET_VIEWABLE_TO = 'RESET_VIEWABLE_TO'

// GOOGLE ACTIONS
export const SET_GOOGLE_AUTH = 'SET_GOGLE_AUTH'
export const GOOGLE_OAUTH2 = "GOOGLE_OAUTH2"
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const SET_GAPI = 'SET_GAPI'

// PROJECT ACTIONS
export const SET_PROJECTS_LOADING = 'SET_PROJECTS_LOADING'
export const SET_PROJECTS_ERROR = 'SET_PROJECTS_ERROR'
export const SELECT_PROJECT = 'SELECT_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const UPDATE_DATE = 'UPDATE_DATE'
export const SET_LOADING_PROJECTS = 'LOADING_PROJECTS'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
export const SET_UPLOAD_TYPE = 'SET_UPLOAD_TYPE'
export const SET_EDITOR_NOTES = 'SET_EDITOR_NOTES'
export const SET_UPLOAD = 'SET_UPLOAD'
export const SET_SUBMITTING_NOTES = 'SET_SUBMITTING_NOTES'
export const SET_SUBMISSION_NOTES = 'SET_SUBMISSION_NOTES'
export const SET_COMMENT_TEXT = 'SET_COMMENT_TEXT'
export const SET_UPLOAD_REMAINING_TIME = 'SET_UPLOAD_REMAINING_TIME'
export const SET_UPLOAD_START = 'SET_UPLOAD_START'
export const NEXT_PROJECT_PAGE = 'NEXT_PROJECT_PAGE'
export const SORT_PROJECTS_BY = 'SORT_PROJECTS_BY'
export const CHANGE_PROJECT_SEARCH_TERM = 'CHANGE_PROJECT_SEARCH_TERM'
export const CHANGE_PROJECT_STATE_FILTER = 'CHANGE_PROJECT_STATE_FILTER'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
export const SET_FETCH_INTERVAL = 'SET_FETCH_INTERVAL'
export const SET_PAGE = 'SET_PAGE'
export const ADD_FETCH_INTERVAL = 'ADD_FETCH_INTERVAL'
export const CLEAR_FETCH_INTERVALS = 'CLEAR_FETCH_INTERVALS'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const ESTIMATE_DEADLINE = 'ESTIMATE_DEADLINE'

// USER ACTIONS
export const SELECT_USER_MESSAGING = 'SELECT_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const FETCH_USERS = 'FETCH_USERS'
export const SELECT_USER = 'SELECT_USER'
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE'
export const CHANGE_USER_SEARCH_TERM = 'CHANGE_USER_SEARCH_TERM'
export const SORT_USERS_BY = 'SORT_USERS_BY'
export const NEXT_PAGE = 'NEXT_PAGE'
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const UPDATE_USER_AVAILABILITY = 'UPDATE_USER_AVAILABILITY'
export const FETCH_USER_AVAILABILITY = 'FETCH_USER_AVAILABILITY'
export const FETCH_AVAILABLE_EDITORS = 'FETCH_AVAILABLE_EDITORS'
export const DOWNLOAD_USER_CSV = 'DOWNLOAD_USER_CSV'
export const LOAD_USER = 'LOAD_USER'
export const REDIRECT = 'REDIRECT'
export const CLEAR_USERS = 'CLEAR_USERS'

// VIEW ACTIONS
export const SET_VIEW_LOADING = 'SET_VIEW_LOADING'
export const SET_VIEW_ERROR = 'SET_VIEW_ERROR'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const SET_SHOW_OVERLAY ='SET_SHOW_OVERLAY'
export const SET_VIEW = 'SET_VIEW'
export const SET_MODAL_VIEW = 'SET_MODAL_VIEW'
export const SET_DRAWER_VIEW = 'SET_DRAWER_VIEW'

// PAYMENT ACTIONS
export const SET_FOCUS = 'SET_FOCUS'

// GENERAL ACTIONS
export const SET_MOBILE = 'SET_MOBILE'
export const GET_MAINTENANCE = 'GET_MAINTENANCE'
export const SET_MAINTENANCE = 'SET_MAINTENANCE'
// NOTIFICATION ACTIONS
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

//HISTORY ACTIONS
export const FETCH_ACTIONS = 'FETCH_ACTIONS'
export const CREATE_ACTION = 'CREATE_ACTION'
export const NEXT_HISTORY_ACTION_PAGE = 'NEXT_HISTORY_ACTION_PAGE'
