import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

import { 
    setDrawerView,
    setShowOverlay, 
} from '../../actions'


import Project from './Project'
import NewProject from './NewProject'
import User from './User'

const Container = styled.div`
    width: ${props => props.mobile ? '100vw' : '450px'};
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    right: ${props => {
        if (props.mobile)
            return props.show ? '0' : '-560px'
        return props.show ? '0' : '-650px'
    }};
    transition: right 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    justify-content: center;
    overflow: hidden;
    z-index: 9999;
`

const Close = styled.div`
    color: #fff;
    height: 10px;
    width: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    margin: 12px;
    transition: transform 200ms ease-out;

    &:hover {
        transform: rotate(90deg);
    }
`
const StyledIcon = styled(Icon)`
    margin: 0 !important;
    line-height: 14px;
`

const Sidebar = ({ showDrawer, drawerView, mobile, setDrawerView, setShowOverlay, selectedUser }) => {

    const handleClick = () => {
        setDrawerView(null)
        setShowOverlay(false)
    }
    
    const renderSidebar = () => {
        switch (drawerView) {
            case 'project':
                return <Project />
            case 'newProject':
                return <NewProject />
            case 'user':
                return <User type={selectedUser.role}/>
            default:
                return
        }
    }

    return (
        <Container className='Sidebar' show={showDrawer} mobile={mobile}>
            <Close onClick={handleClick} >
                <StyledIcon name='close' size='large'/>
            </Close>
            {renderSidebar(drawerView)}
        </Container>
    )
}

const mapStateToProps = state => {
    return { 
        drawerView: state.view.drawerView,
        mobile: state.index.mobile,
        selectedUser: state.user.selectedUser
    }
}


export default connect(mapStateToProps, { setDrawerView, setShowOverlay })(Sidebar)