import {
    TOGGLE_MENU,
    SET_SHOW_OVERLAY,
    SET_VIEW,
    SET_MODAL_VIEW,
    SET_DRAWER_VIEW
} from './types'

export const toggleMenu = () => dispatch => {
    return dispatch({ type: TOGGLE_MENU })
}

export const setShowOverlay = show => dispatch => {
    return dispatch({ type: SET_SHOW_OVERLAY, payload: show })
}

export const setView = view => dispatch => {
    return dispatch({ type: SET_VIEW, payload: view })
}

export const setModalView = view => dispatch => {
    return dispatch({ type: SET_MODAL_VIEW, payload: view })
}

export const setDrawerView = view => dispatch => {
    return dispatch({ type: SET_DRAWER_VIEW, payload: view })
}