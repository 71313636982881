import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import Loader from "react-loader-spinner";
import { blue } from '../Shared/colors'
import { setModalView, signIn, googleOAuth2, googleSignIn, setAuthLoading, addNotification, setError } from '../../actions'
import { Field, reduxForm } from 'redux-form'
// import { GoogleLogin } from 'react-google-login';
import { Icon } from 'semantic-ui-react'
import LogoImage from '../../images/Logo.png'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


const Header = styled.div`
    grid-row: 1 / 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`

const Logo = styled.img`
    height: 70px;
    align-self: center;
`

const Title = styled.h3`
    margin: 0 auto 20px auto;
    text-transform: uppercase;
    font-size: 1.2rem;
    user-select: none;
`

const Body = styled.div`
    grid-row: 2 / 3;    
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
`

const Form = styled.form`
    width: 80%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-self: center;
`

const InputContainer = styled.div`
    background: #ddd;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    & > div {
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
    display: block !important;
    width: 60px !important;
    height: 18px !important;
    opacity: ${props => props.showPass ? 1 : .5} !important;
`

const TogglePasswordVisibility = styled(StyledIcon)`
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
`

const Input = styled.input`
    outline: none;
    border: none;
    background: #ddd;
    font-weight: 700;
    width: 100%;
    height: 100%;
`

const Submit = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: ${blue};
    color: #fff;
    margin-top: 20px;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
`

const GoogleButton = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    width: 80%;
    outline: none;
    border: none;
    border-radius: 5px;
    background: #ddd;
    color: ${blue};
    font-weight: 700;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 10px;
`

const GoogleLogo = styled.img`
    height: 60%;
    margin-right: 5px;
`

const MyCustomButton = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-self: center;
`

const Footer = styled.div`
    grid-row: 3 / 4;    
    display: flex;
    flex-direction: column;
    user-select: none;
    color: #999;
    width: 80%;
    text-align: center;
    justify-self: center;
    font-size: .9rem;
`

const SignUp = styled.p`
    align-self: center;
`

const ForgotPassword = styled.p`
    lign-self: center;
    margin-bottom: 30px;
`

const Link = styled.a`
    text-decoration: underline;
    color: ${blue};
    &:hover {
        cursor: pointer;
        color: #999;
    }
`

const ErrorMessage = styled.p`
    color: red;
    font-size: .7rem;
    user-select: none;
    position: relative;
    bottom: 15px;
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const renderInput = ({ input, type, meta, placeholder }) => {
    return (
        <div>
            <Input {...input}  type={type} placeholder={placeholder}  autoComplete="off"/>
            { renderError(meta)}
        </div>
    )
}

const renderError = ({ error, touched, active}) => {
    if (touched && error && !active) {
        return <ErrorMessage>{error}</ErrorMessage>
    } else return <div />
}


const SignIn = ({ setModalView, handleSubmit, signIn, googleOAuth2, googleSignIn, setAuthLoading, auth_loading, addNotification }) => {

    const [showPass, setShowPass] = useState(false)
    
    const onSubmit = async formValues => {
        setAuthLoading(true)

        try {
            await signIn(formValues)
        } catch (e) {
            addNotification(e)
        }

        setAuthLoading(false)
    }

    const handleGoogleLogin = async data => {
        try {
            googleOAuth2(data)
            const googleData = jwt_decode(data.credential);
            await googleSignIn(googleData, 'signin')
        } catch(e) {
            addNotification(e)
        }
    }

    if (auth_loading) {
        return (
            <Loading>
                <Loader
                    type="Bars"
                    color={blue}
                    height={50}
                    width={50}
                />
            </Loading>
        )
    } else {
        return (
            <Fragment>
                <Header>
                    <Logo src={LogoImage} />
                </Header>
                <Body>
                    <Title>Sign In</Title>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <InputContainer>
                            <StyledIcon name='mail' />
                            <Field name="email" component={renderInput} placeholder='Email'/>
                        </InputContainer>
                        <InputContainer>
                            <StyledIcon name='shield' />
                            <Field name="password" type={showPass ? 'text' : 'password'} component={renderInput} placeholder='Password'/>
                            <TogglePasswordVisibility showPass={showPass} onClick={() => setShowPass(!showPass)} name='eye' />
                        </InputContainer>
                        <Submit type='submit'>Sign In</Submit>
                    </Form>
                    <br></br>
                    <MyCustomButton>
                        <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onFailure={setError}
                            cookiePolicy={'single_host_origin'}
                            size = 'medium'    
                        />
                    </MyCustomButton>   

                </Body>
                <Footer>
                    <SignUp>Not a member? <Link onClick={() => setModalView('signUp')}>Sign up now!</Link></SignUp>
                    <ForgotPassword><Link onClick={() => setModalView('forgotPassword')} >I forgot my password</Link></ForgotPassword>
                </Footer>
            </Fragment>
        )
    }      
}

const validate = formValues => {
    const errors = {}

    if (!formValues.email) {
        errors.email = 'Email Required'
    }

    if (!formValues.password) {
        errors.password = 'Password Required'
    }

    if (!validateEmail(formValues.email)) {
        errors.email = 'Invalid Email Address'
    }

    return errors
}

const validateEmail = email =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const mapStateToProps = state => {
    return {
        googleAuth: state.user.googleAuth,
        error: state.user.error,
        auth_loading: state.user.auth_loading
    }
}

const connected = connect(mapStateToProps, { setModalView, signIn, setError, googleSignIn, setAuthLoading, addNotification, googleOAuth2 })(SignIn)

export default reduxForm({
    form: 'signInForm',
    validate,
    setModalView
})(connected)