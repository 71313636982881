import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import SignIn from './SignIn'
import SignUp from './SignUp'
import SignUpEditors from './SignUpEditors'
import Reset from './Reset'
import ForgotPassword from './ForgotPassword'
import Onboard from './Onboard'
import CalendarEvent from './CalendarEvent'
import Privacy from './Privacy'
import EditorUpload from './EditorUpload'
import AdminApproval from './AdminApproval'
import { setModalView, setShowOverlay } from '../../actions'
import { blue } from '../Shared/colors'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    z-index: 9999;
    user-select: none;
`

const Window = styled.div`
    min-width: ${props => props.width};
    min-height: ${props => props.height};
    background: ${props => props.blue ? blue : '#fff' };
    max-width: 50%;
    padding: 30px 0;
    display: grid;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    z-index: 100000;
`

const Modal = ({ modalView, currentUser, setModalView, setShowOverlay }) => {
    let width, height

    function selectModal() {
        switch(modalView) {
            case 'signIn':
                return <SignIn />
            case 'signUp':
                return <SignUp />
            case 'signUpEditors':
                return <SignUpEditors />
            case 'reset':
                return <Reset />
            case 'onboard':
                if (currentUser && !currentUser.completedOnboarding)
                    return <Onboard/>
                break
            case 'calendarEvent':
                return <CalendarEvent />
            case 'privacy':
                return <Privacy />
            case 'editorUpload':
                return <EditorUpload />
            case 'forgotPassword':
                return <ForgotPassword />
            case 'AdminApproval':
                return <AdminApproval />
            default:
                return null
        }
    }

    if (modalView === 'onboard') {
        width = '700px'
        height = 'auto'
    } else if (modalView === 'editorUpload' || modalView === 'AdminApproval') {
        width = 'auto'
        height = 'auto'
    } else {
        width = '300px'
        height = '550px'
    }

    if (modalView === 'calendarEvent') {
        height = '110px'
    }

    const handleClick = () => {
        if (currentUser) {
            if (modalView !== 'onboard') {
                setModalView(null)
                setShowOverlay(false)
            }
        }
    }

    return (
        <Container show={modalView} onClick={handleClick}>
            <Window height={height} width={width} blue={modalView === 'editorUpload' || modalView === 'AdminApproval'} onClick={e => e.stopPropagation()}>
                { selectModal(modalView, currentUser, setModalView) }
            </Window>
        </Container> 
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        modalView: state.view.modalView
    }
}

export default connect(mapStateToProps, { setModalView, setShowOverlay })(Modal)
