import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { updateUser, setModalView, setError, setAuthLoading } from '../../../actions'
import styled from 'styled-components'
import Loader from "react-loader-spinner";
import { blue } from '../../Shared/colors'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & select {
        height: 30px;
        width: 80px;
        font-size: 1rem;
        padding: 5px;
        margin: 5px 0 ;
    }

    & select:focus {
      outline: none;
    }

    & textarea {
        height: 150px;
        width: 500px;
        font-size: 1rem;
        padding: 5px;
        margin: 5px;
    }

    & textarea:focus {
      outline: none;
    }
`

const Container = styled.label`
  display: flex;
  position: relative;
  height: 30px;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & p {
    align-self: center;
  }

  & span {
    align-self: center;
  }
`

const StyledField = styled.div`
  font-size: 1.3rem;
  font-family: Roboto;
  display: flex;
  & > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 5px;
    gap: 5px;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  & input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & > label {
    margin: 0;
  }

  & > p {
    align-self: center;
    font-size: .9rem;
  }
`

const Title = styled.h2`
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: 15px;
`

const DOB = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`

const Button = styled.button`
    width: 100px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px auto;
    background: ${blue};
    color: #fff;
    border: none;
    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Bio = ({ currentUser, updateUser, setError, auth_loading, setAuthLoading }) => {
  const [day, setDay] = useState(null)
  const [month, setMonth] = useState(null)
  const [bio, setBio] = useState('')
  const [share, setShare] = useState(false)

  const history = useHistory();

  const renderDays = month => {
    const dayCount = { 1: 31, 2: 29, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31 }
    month = month === null ? 1 : month
    const numDays = dayCount[month]
    const fields = []

    for (let i = 1; i <= numDays; i++) {
      fields.push(<option key={i} value={i}>{i}</option>)
    }
    return [<option key='none-day' value={null}>Day</option>, ...fields]
  }

  const renderMonthOptions = () => {
    const monthMap = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' }
    let months = Object.keys(monthMap).map(month => <option key={month} value={month}>{monthMap[month]}</option>)
    return [<option key='none-month' value={null}>Month</option>, ...months]
  }

  const handleSelectMonth = e => {
    setMonth(e.target.value)
    setDay(1)
  }
  const handleSelectDay = e => {
    setDay(e.target.value)
  }
  const handleUpdateBio = e => {
    setBio(e.target.value)
  }
  const handleUpdateShare = e => {
    setShare(e.target.checked)
  }

  const handleNext = async e => {
    e && e.preventDefault()
    try {
      const update = currentUser.role === 'client' ? { birthDay: day, birthMonth: month, bio, completedOnboarding: true, sharePermission: share } : { completedOnboarding: true }
      await updateUser(currentUser._id, update, true)
      if (currentUser.role === 'client') return history.push("/tutorials");
    } catch (e) {
      setError('Something went wrong.')
    }
  }

  if (currentUser.role === 'editor') {
    return handleNext()
  }

  if (auth_loading) {
        return (
            <Loading>
                <Loader
                    type="Bars"
                    color={blue}
                    height={50}
                    width={50}
                />
            </Loading>
        )
    } else {
      return (
        <Form>
          <Title>Tell us about yourself!</Title>
          <p>Care to tell us your birthday?</p>
          
          <DOB>
            <select onChange={handleSelectMonth} value={month}>
              {renderMonthOptions()}
            </select>
            <select onChange={handleSelectDay} value={day}>
              {renderDays(month)}
            </select>
          </DOB>

          <div>
            <div>
              <textarea name="bio" onChange={handleUpdateBio} placeholder="Tell us a bit about your style and what you're looking for in your edits." value={bio}/>
            </div>
          </div>

          <Container>
            <StyledField>
              <input type='checkbox' value={share} onClick={handleUpdateShare}/>
              <Checkmark className='checkmark'/>
              <p>May we share your amazing photos through our social media channels?</p>
            </StyledField>
          </Container>
          
          <div>
            <Button type="submit" onClick={handleNext}>Submit</Button>
          </div>
        </Form>
      )
    }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    auth_loading: state.user.auth_loading
  }
}

export default connect(mapStateToProps, { updateUser, setModalView, setError, setAuthLoading })(Bio)