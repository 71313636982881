import React, { useState, useEffect } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components'
import validate from '../validate'
import { blue, white } from '../../../Shared/colors'
import { Input } from 'semantic-ui-react'


const Page = styled.div`
  background: ${props => props.odd ? blue : white};
  height: 100%;
  width: 100%;
  color: ${props => props.odd ? white : blue};
  padding: 20px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 200ms ease-in;
`


const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  & input:focus {
    outline: none;
  }
`

const ButtonGroup = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const Button = styled.button`
  color: ${props => props.odd ? white : blue};
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100px;
  height: 40px;
  margin: 10px;
  margin-top: 20px;
  margin-left: ${props => props.next && 'auto'};
  border-radius: 5px;
  border: 2px solid ${props => props.odd ? white : blue};
  display: ${props => props.hidden && 'none'};
  background: ${props => props.odd ? blue : white};
  transition: opacity 500ms ease-out;
  &:hover {
    cursor: pointer;
    background: ${props => props.odd ? white : blue};
    color: ${props => props.odd ? blue : white};
  }
  &:active {
    outline: none;
  }
`


const ToolOption = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  & div {
    display: flex;
    flex-direction: column;
  }

  & div p {
    margin: 0;
  }

  & div label {
    font-family: Krub;
    font-size: 14px;
    font-weight: 300;
  }
`

const ToolHeader = styled.p`
  font-size: 20px;
  font-weight: 300;
`

const StyledRadio = styled.input`
`

const StyledInput = styled(Input)`
  width: 50px;
  height: 40px;
`

const Wipe = styled.div`
  position: absolute;
  width: ${props => props.grow ? '1200px' : '0'};
  height: ${props => props.grow ? '1200px' : '0'};
  border-radius: 50%;
  background: ${props => props.odd ? white : blue};
  transition: height 350ms ease-in, width 350ms ease-in;
`

const renderRadio = ({ input, header, label, type, checked, name }) => (
    <ToolOption>
      <StyledRadio {...input} type={type} checked={checked} />
      <div>
         <ToolHeader>{header}</ToolHeader>
        <label>{label}</label>
      </div>
    </ToolOption>
)

const renderInput = ({ input, header, label, type, checked, name }) => (
    <ToolOption>
      <StyledInput {...input} type={type} checked={checked} />
      <div>
         <ToolHeader>{header}</ToolHeader>
        <label>{label}</label>
      </div>
    </ToolOption>
)
 
const ToolsCheckPage = ({ handleSubmit, previousPage, tools, minutes, optional, odd, dispatch }) => {

  const [grow, setGrow] = useState(false)
  const [show, setShow] = useState(false)
  
  useEffect(() => {
    if (!optional) dispatch(change('newProject', 'tools', 1))
    setTimeout(() => setShow(true), 1)
  }, [])

  const handleNext = e => {
    e.preventDefault()
    setGrow(true)
    setTimeout(handleSubmit, 350)
  }

  let label = "Please enter an allotted amount of time for tool work you’d like us to complete. This is added at a rate of $1/min."
  if (!optional) label += " 10% off for batch.ai users!"

  return (
    <Page odd={odd}>
      <Container show={show}>
        <Form odd={odd} onSubmit={handleSubmit}>
            { optional && <Field name="tools" component={renderRadio} type="radio" checked={tools == 0} value={0} header="No Tools" label="We will leave all tool work alone in the gallery. *No up-charge required."/> }
            <Field name="tools" component={renderRadio} type="radio" checked={tools == 1} value={1} header="Tool Allotment Time" label={label}/> 
            { tools == 1 && <Field name="minutes" component={renderInput} value={minutes} type="text" label="Allotted time in minutes for excess tool work"/> }
        </Form> 
        <ButtonGroup>
            <Button show odd={odd} type="button" className="previous" onClick={previousPage}>Back</Button>
            { tools && ((tools == 1 && parseInt(minutes) > 0) || tools == 0) && <Button odd={odd} next onClick={handleNext} className="next">Next</Button> }
          </ButtonGroup>
        <Wipe odd={odd} grow={grow} />
      </Container>
    </Page>
  )
}

const mapStateToProps = state => {
  return {
    tools: state.form.newProject.values ? state.form.newProject.values.tools : 0,
    minutes: state.form.newProject.values && state.form.newProject.values.tools ? state.form.newProject.values.minutes : 0,
    options: state.form.newProject.values ? state.form.newProject.values.options : false,
  }
}

const connected = connect(mapStateToProps)(ToolsCheckPage)

export default reduxForm({
    form: 'newProject', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
  })(connected)