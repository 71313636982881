import React from 'react';
import { connect } from 'react-redux'
import ImageUploader from 'react-images-upload';
import { uploadProfileImage } from '../../actions'
 
class UploadImage extends React.Component {
 
    constructor(props) {
        super(props);
         this.state = { pictures: [] };
         this.onDrop = this.onDrop.bind(this);
    }

    
    async onDrop(picture) {
        if (picture.length > 0) {
            try {
                const URL = await this.props.uploadProfileImage(picture[0], this.props.selectedUser._id)
                this.props.updateUser(this.props.selectedUser._id, { profileImage: URL })
                this.props.hideImageUploader()
            } catch(e) {
                console.log(e)
            }
        } else {
            // handle invalid image here
        }
    }

    handleError(e) {
        console.log(e)
    }
 
    render() {
        return (
            <ImageUploader
                withIcon={true}
                buttonText='Select Image'
                onChange={this.onDrop}
                onError={this.handleError}
                imgExtension={['.jpg', '.jpeg', '.png']}
                maxFileSize={716800}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedUser: state.user.selectedUser
    }
}


export default connect(mapStateToProps, { uploadProfileImage })(UploadImage)
