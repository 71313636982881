
import { 
    SET_DRAWER_VIEW, 
    TOGGLE_MENU, 
    SET_SHOW_OVERLAY, 
    SET_VIEW, 
    SET_MODAL_VIEW,
    DELETE_PROJECT
} from '../actions/types'


const INITIAL_STATE = { 
    showMenu: false, 
    showOverlay: true, 
    drawerView: null, 
    modalView: null,
    view: null
}

const viewReducer =  (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TOGGLE_MENU:
            return { ...state, showMenu: !state.showMenu }
        case SET_VIEW:
            return {...state, view: action.payload }
        case SET_DRAWER_VIEW:
            return { ...state, drawerView: action.payload }
        case SET_MODAL_VIEW:
            return {...state, modalView: action.payload}
        case SET_SHOW_OVERLAY:
            return {...state, showOverlay: action.payload }
        case DELETE_PROJECT:
            return { ...state, drawerView: null }
        default:
            return state
    }
}

export default viewReducer