import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Table,
  Icon,
  Pagination
} from 'semantic-ui-react'

import {
  fetchProjects,
  clearProjects,
  setFetchInterval,
  setPage,
  setRefreshInterval
} from '../../../actions'

import { blue } from '../../Shared/colors'

import Project from './Project'

const OuterContainer = styled.div`
  margin: ${props => props.mobile ? '0' : '20px'};
  width: 80vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
  height: ${props => props.mobile ? 'calc(100% - 120px)' : '100%'};
`

const StyledTable = styled(Table)`
  width: 100% !important;
  overflow: scroll;
`

const StyledTableBody = styled(Table.Body)`

  font-family: Roboto !important;

  & * {
    border: none !important;
  }

  & tr {
    background: rgba(0, 0, 0, 0.05);
  }

  & tr:nth-of-type(even) {
    background: rgba(0, 11, 133, 0.1);
  }

  & tr:hover td {
    background: ${blue} !important;
    color: #fff;
    opacity: 0.9;
  }
`

const StyledTableHeader = styled(Table.Header)`
  background: ${blue} !important;
  color: #fff !important;
`

const StyledTableCell = styled(Table.Cell)``

const TableHeaderCell = styled(StyledTableCell)`
  color: #fff !important;

  & i {
    margin-left: 5px;
  }
`

const SortableTableHeaderCell = styled(StyledTableCell)`
  cursor: pointer !important;
`

const PaginationContainer = styled.div`
  margin-top: 10px;
`

const ProjectTable = ({
  currentUser,
  projects,
  fetchProjects,
  searchTerm,
  stateFilter,
  clearProjects,
  setRefreshInterval,
  count,
  mobile
}) => {

    const [sortProjectsBy, setSortProjectsBy] = useState('editorDeadline')
    const [sortDirection, setSortDirection] = useState('desc')
    const [page, setPage] = useState(1)

    useEffect(() => {
      clearProjects()
      fetchProjects(page, sortProjectsBy, sortDirection, searchTerm, stateFilter)
      const refreshInterval = setInterval(() => fetchProjects(page, sortProjectsBy, sortDirection, searchTerm, stateFilter), 10000)
      setRefreshInterval(refreshInterval)
    },[searchTerm, page, sortProjectsBy, sortDirection, stateFilter], () => {
      setRefreshInterval(null)
      clearProjects()
    })

   const handleSort = sortBy => {
    setPage(1)
    let nextDirection = 'desc'

    if (sortBy === sortProjectsBy) {
      nextDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    setSortDirection(nextDirection)
    setSortProjectsBy(sortBy)
    fetchProjects(1, sortBy, nextDirection, searchTerm, stateFilter)
  } 

    return (
      <OuterContainer >
        <InnerContainer mobile={mobile}>
        <StyledTable singleLine unstackable collapsing>
          <StyledTableHeader>
            <Table.Row>
              <SortableTableHeaderCell
                className='headCol'
                onClick={() => handleSort('name')}
              >
                Name
                {sortProjectsBy === 'name' && (
                  sortDirection && <Icon
                    name={`sort ${
                      sortDirection === 'asc' ? 'ascending' : 'descending'
                    }`}
                  />
                )}
              </SortableTableHeaderCell>
              {['admin', 'super', 'dev'].includes(currentUser.role) && (
                <TableHeaderCell className='tableValue'>
                  <Icon name='check' />
                  Status Checks
                </TableHeaderCell>
              )}
              {['admin', 'super', 'dev'].includes(currentUser.role) && (
                <TableHeaderCell className='tableValue'>Client</TableHeaderCell>
              )}
              {['admin', 'super', 'dev'].includes(currentUser.role) && (
                <TableHeaderCell className='tableValue'>Editor</TableHeaderCell>
              )}
              <TableHeaderCell className='tableValue'>
                Service(s)
              </TableHeaderCell>
              <TableHeaderCell className='tableValue'>Status</TableHeaderCell>
              <SortableTableHeaderCell
                className='tableValue'
                onClick={() => handleSort('uploadDate')}
              >
                Uploaded
                {sortProjectsBy === 'uploadDate' && (
                  sortDirection && <Icon
                    name={`sort ${
                      sortDirection === 'asc' ? 'ascending' : 'descending'
                    }`}
                  />
                )}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                className='tableValue'
                onClick={() => handleSort('uploadDate')}
              >
                Created At
                {sortProjectsBy === 'uploadDate' && (
                  sortDirection && <Icon
                    name={`sort ${
                      sortDirection === 'asc' ? 'ascending' : 'descending'
                    }`}
                  />
                )}
              </SortableTableHeaderCell>
              {!['client'].includes(currentUser.role) && (
                <SortableTableHeaderCell
                  className='tableValue'
                  onClick={() => handleSort('editorDeadline')}
                >
                  Deadline
                  {sortProjectsBy === 'editorDeadline' && (
                    sortDirection && <Icon
                      name={`sort ${
                        sortDirection === 'asc' ? 'ascending' : 'descending'
                      }`}
                    />
                  )}
                </SortableTableHeaderCell>
              )}
              {!['editor'].includes(currentUser.role) && (
                <SortableTableHeaderCell
                  className='tableValue'
                  onClick={() => handleSort('deliveryDate')}
                >
                  Delivery
                  {sortProjectsBy === 'deliveryDate' && (
                    sortDirection && <Icon
                      name={`sort ${
                        sortDirection === 'asc' ? 'ascending' : 'descending'
                      }`}
                    />
                  )}
                </SortableTableHeaderCell>
              )}
            </Table.Row>
          </StyledTableHeader>
          
          <StyledTableBody sortProjectsBy={sortProjectsBy}>
            { projects.map((project, i) => <Project sortProjectsBy={sortProjectsBy} sortDirection={sortDirection} key={project._id} projects={projects} project={project} i={i} currentUser={currentUser}/>) }
          </StyledTableBody>
        
        </StyledTable>
        
        </InnerContainer>
        <PaginationContainer>
          <Pagination 
            size={mobile ? 'mini' : 'regular'}
            activePage={page} 
            totalPages={Math.ceil(count / 10)}
            onPageChange={(e,  { activePage }) => setPage(activePage)}
            boundaryRange={0}
          />
        </PaginationContainer>
      </OuterContainer>
    )
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    stateFilter: state.project.stateFilter,
    sort_by: state.project.sort_by,
    sort_direction: state.project.sort_direction,
    page: state.project.page,
    fetchInterval: state.project.fetchInterval,
    count: state.project.count,
    mobile: state.index.mobile,
  }
}

export default connect(mapStateToProps, {
  fetchProjects,
  clearProjects,
  setFetchInterval,
  setPage,
  setRefreshInterval
})(ProjectTable)