import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { blue } from '../Shared/colors'
import { setModalView, setAuthLoading, addNotification, setError, passwordReset } from '../../actions'
import { Field, reduxForm } from 'redux-form'
import { Icon } from 'semantic-ui-react'


const Title = styled.h3`
    margin: 0 auto 20px auto;
    text-transform: uppercase;
    font-size: 1.2rem;
    user-select: none;
`

const Body = styled.div`
    grid-row: 2 / 3;    
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
`

const Form = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-self: center;
`

const InputContainer = styled.div`
    background: #ddd;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    & > div {
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }
`

const StyledIcon = styled(Icon)`
    margin: 0 !important;
    display: block !important;
    width: 60px !important;
    height: 18px !important;
    opacity: ${props => props.showPass ? 1 : .5} !important;
`

const Input = styled.input`
    outline: none;
    border: none;
    background: #ddd;
    font-weight: 700;
    width: 100%;
    height: 100%;
`

const Submit = styled.button`
    font-family: Roboto;
    font-size: .9rem;
    text-transform: uppercase;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: ${blue};
    color: #fff;
    margin: 10px 0;
    &:hover {
        cursor: pointer;
        opacity: .6;
    }
`

const Link = styled.a`
    text-decoration: underline;
    color: ${blue};
    &:hover {
        cursor: pointer;
        color: #999;
    }
`

const ErrorMessage = styled.p`
    color: red;
    font-size: .7rem;
    user-select: none;
    position: relative;
    bottom: 15px;
`

const GoBack = styled.p`
    text-align: center;
`

const renderInput = ({ input, type, meta, placeholder }) => {
    return (
        <div>
            <Input {...input}  type={type} placeholder={placeholder}  autoComplete="off"/>
            { renderError(meta)}
        </div>
    )
}

const renderError = ({ error, touched, active}) => {
    if (touched && error && !active) {
        return <ErrorMessage>{error}</ErrorMessage>
    } else return <div />
}


const ForgotPassword = ({ setModalView, handleSubmit, signIn, googleOAuth2, googleSignIn, setAuthLoading, auth_loading, addNotification }) => {
    
    const onSubmit = async formValues => {
        try {
            await passwordReset(formValues.email)
            addNotification({ type: 'success', message: 'Check your inbox for a password reset email.'})
            setModalView('signIn')
        } catch (e) {
            addNotification({ meessage: 'Error submitting password reset request.' })
        }
        setAuthLoading(false)
    }
    
    return (
        <Fragment>
            <Body>
                <Title>Reset Password</Title>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <StyledIcon name='mail' />
                        <Field name="email" component={renderInput} placeholder='Email'/>
                    </InputContainer>
                    <Submit type='submit'>Submit</Submit>
                    <GoBack><Link onClick={() => setModalView('signIn')} >Back</Link></GoBack>
                </Form>  
            </Body>
        </Fragment>
    )    
}

const validate = formValues => {
    const errors = {}

    if (!formValues.email) {
        errors.email = 'Email Required'
    }

    if (!validateEmail(formValues.email)) {
        errors.email = 'Invalid Email Address'
    }

    return errors
}

const validateEmail = email =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const mapStateToProps = state => {
    return {
        error: state.user.error,
        auth_loading: state.user.auth_loading
    }
}

const connected = connect(mapStateToProps, { setModalView, setError, setAuthLoading, addNotification })(ForgotPassword)

export default reduxForm({
    form: 'passwordResetForm',
    validate,
    setModalView
})(connected)