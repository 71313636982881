const validate = values => {
    const errors = {}
    if (!values.projectType) {
      errors.projectType= ''
    }

    if (!values.projectName) {
      errors.projectName = 'Required'
    }

    if (['editing', 'culling', 'editingAngCulling'].includes(values.projectType) && !values.quantity) {
      errors.quantity = 'Required'
    } else if (isNaN(parseInt(values.quantity)) || values.quantity <= 0) {
      errors.quantity = 'Must be a whole number greater than zero'
    }
    if (['culling', 'editingAngCulling'].includes(values.projectType) && !values.anchorCount) {
      errors.anchorCount = 'Required'
    } else if (isNaN(parseInt(values.anchorCount)) || values.anchorCount <= 0) {
      errors.anchorCount = 'Must be a whole number greater than zero'
    }

    if (['culling', 'editingAngCulling'].includes(values.projectType) && !values.cullCount) {
      errors.cullCount = 'Required'
    } else if (isNaN(parseInt(values.cullCount)) || values.cullCount <= 0) {
      errors.cullCount = 'Must be a whole number greater than zero'
    }

    if (['editing', 'editingAngCulling'].includes(values.projectType) && values.anchorCheck !== true) {
      errors.anchorCheck = 'Required'
    }

    if (['editing', 'editingAngCulling'].includes(values.projectType) && values.toolsCheck && values.toolsDisclaimer !== true) {
      errors.toolsDisclaimer = 'Required'
    }

    if (values.projectType === 'retouching' && values.jpgCheck !== true) {
      errors.jpgCheck = 'Required'
    }
    
    return errors
  }
  
  export default validate