import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ProgressBar from 'react-customizable-progressbar'
import Comment from './Comment'
import NewComment from './NewComment'
import Bubble from '../../Shared/Bubble'
import moment from 'moment-timezone'
import { blue } from '../../Shared/colors'
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";

import { Icon, Button, Popup, Label } from 'semantic-ui-react'

import { initializeApp } from 'firebase/app';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'

import {
  updateProject,
  deleteProject,
  setDrawerView,
  setShowOverlay,
  addNotification,
  setModalView,
  setUploadProgress,
  setUploadType,
  setSubmittingNotes,
  fetchAvailableEditors,
  approveOrRejectRushRequest
} from '../../../actions'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  background-position-x: center;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Banner = styled.div`
  width: 100%;
  padding: 5px 20px;
  background: rgb(43, 66, 101);
  background: ${blue};
  display: flex;
  flex-direction: row;
`

const Title = styled.p`
  color: #fff;
  font-family: Krub;
  font-size: 25px;
  font-weight: 600;
  text-shadow: 1px 1px #777;
  margin-bottom: 0;
`

const Type = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 200;
  color: #fff;
  text-shadow: 1px 1px #777;
`

const Body = styled.div`
  height: 75vh;
  padding: 10px 20px;
  font-size: ${props => (props.mobile ? '.75rem' : '.85rem')};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  &>button {
    margin-top: auto !important;
  }
`

const Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-weight: 300;
  height: 40px;
  align-items: center;
  margin-bottom: 5px;
`

const DetailTitle = styled.p`
  display: block;
  width: 30%;
  align-self: center;
  justify-self: center;
  font-weight: 300;
  font-size: 0.8rem;
  color: #999;
  margin: 0;
  text-transform: capitalize;
`

const DetailValue = styled.div`
  width: 70%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-family: Roboto;
  text-transform: capitalize;
`

const DetailDate = styled(DetailValue)`
  text-transform: none;
  ${props => props.rush && 'color: red;'}
`

const Spec = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-family: Roboto;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 5px;
`

const Notes = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-weight: 300;
  align-items: flex-start;

  & div {
    text-transform: none !important;
  }
`

const SpecValue = styled.div`
  display: inline;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-family: Krub;
  background: ${blue};
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  text-transform: uppercase;
`

const NotesTitle = styled.p`
  display: block;
  width: 30%;
  font-weight: 300;
  color: #999;
`

const CommentBox = styled.div`
  height: 40vh;
  background: rgb(240, 240, 240);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  display: ${props => (props.hidden ? 'none' : 'block')};
`

const UserSelect = styled.select`
  height: 25px;
  padding: 2px 8px;
  background: #eee;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #aaa;
  &:hover {
    font-size: 0.8rem;
    cursor: pointer;
    box-shadow: 1px 1px 2px #888;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

const specTypes = ['quantity', 'anchors', 'cullTo', 'rushRequsted']

const SpecLabel = styled.label`
  font-size: 0.8rem;
  text-transform: capitalize;
  font-family: Krub;
  font-weight: 600;
`

const SpecInput = styled.input`
  color: $#fff;
  border: none;
  width: 50px;
  font-size: 1rem;
  text-align: center;
  margin-left: 5px;
  border-bottom: 1px solid #000;
`

const StyledProgressBar = styled(ProgressBar)`
  width: 100px !important;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: cetner;
  font-size: 14px;
`

const StyledButton = styled(Button)`
  height: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;

  & i {
    margin: 0 !important;
  }
`

const StyledLabel = styled.label`
  height: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0,0,0,.6);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 0 .25em 0 0;
  padding: .78571429em 1.5em .78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
  box-shadow: 0 0 0 1px transparent inset,0 0 0 0 rgba(34,36,38,.15) inset;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
`

const projectStates = {
  1: {
    text:'Awaiting Client Upload',
    icon: 'hourglass half'
  },
  2: {
    text: 'Client Uploading',
    icon: 'upload'
  },
  3: {
    text: 'New',
    icon: 'picture'
  },
  4: {
    text: 'In Editing',
    icon: 'paint brush'
  },
  5: {
    text: 'Editor Uploading',
    icon: 'upload'
  },
  6: {
    text: 'In Review',
    icon: 'search'
  },
  7: {
    text: 'Complete',
    icon: 'circle check'
  }, 
  8: {
    text: 'Unknown',
    icon: 'question circle outline'
  }
}

const Project = ({
  currentUser,
  selectedProject,
  updateProject,
  deleteProject,
  mobile,
  addNotification,
  availableEditors,
  fetchAvailableEditors,
  setModalView,
  approveOrRejectRushRequest
}) => {
  const messageBox = useRef(null)
  const [show, setShow] = useState(false)
  const [editSpec, setEditSpec] = useState(null)
  const [editSpecValue, setEditSpecValue] = useState(null)
  const [storage, setStorage] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(null)

  useEffect(() => {

    if (['admin', 'super', 'dev'].includes(currentUser.role) && !selectedProject.editor) {
      fetchAvailableEditors(new Date(), selectedProject.deliveryDate)
    }

    messageBox.current.scrollTop = messageBox.current.scrollHeight
    setTimeout(() => setShow(true), 1)

    const app = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `hawkinspng-37410.firebaseapp.com`,
      projectId: 'hawkinspng-37410',
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    })

    const appStorage = getStorage(app)
    setStorage(appStorage)
  }, [currentUser, selectedProject, fetchAvailableEditors, setShow, setStorage])

  const uploadFile = e => {
    const { files, name } = e.target
    const file = files[0]
    setUploadProgress(0)

    if (storage) {
      const uploadRef = ref(storage, `Projects/${selectedProject.name}/${selectedProject.name}-${name}`)
      const uploadTask = uploadBytesResumable(uploadRef, file, { contentType: 'application/zip' })
      let nextState

      if (selectedProject.state > 2 && name === 'sourceUpload.zip') {
        updateProject(selectedProject._id, { state: 2 })
        nextState = 3
      } else if (selectedProject.state > 5 && name === 'workedUpload.zip') {
        updateProject(selectedProject._id, { state: 5 })
        nextState = 6
      } else {
        updateProject(selectedProject._id, { state: 2 })
        nextState = 3
      }

      uploadTask.on('state_changed', ({ bytesTransferred, totalBytes, state, metadata, task, ref }) => {
        const progress = Math.floor((bytesTransferred / totalBytes) * 100)
        if (progress !== uploadProgress) {
            setUploadProgress(progress)
            try {
              updateProject(selectedProject._id, { uploadProgress: progress })
            } catch (e) {
              console.log("Error updating upload progress")
            }
        }
      }, ({ code }) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
      }, () => {
        // Upload completed successfully, now we can get the download URL
        setUploadProgress(null)
        getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
          try {
            const uploadType = name.split('.')[0]
            updateProject(selectedProject._id, { [uploadType]: downloadURL, state: nextState, uploadProgress: 0 })
            addNotification({ type: 'success', message: 'File uploaded'})
          } catch (e) {
            console.log(e)
            addNotification({ type: 'error', message: 'Error updating project. Please try again.'})
          }
        });
      })
    }
  }

  const handleAssignEditor = e => {
    if (typeof e !== 'string') {
      const selectedEditor = JSON.parse(e.target.value)
      const confirmed = window.confirm(`Assign project to ${selectedEditor.fullName}?`)
      if (confirmed) updateProject(selectedProject._id, { editor: selectedEditor })
    }
  }

  const UnassignEditor = () => (
    <Button onClick={() => updateProject(selectedProject._id, { editor: null })}>Unassign</Button>
  )

  const handleUpdateSpec = async (spec, value) => {
    value = Number(value)
    if (isNaN(value) || value <= 0) {
      addNotification({ type: 'error', message: 'Invalid Spec Value' })
    } else {
      await updateProject(selectedProject._id, {
        [spec]: value
      })
      setEditSpec(null)
      setEditSpecValue(null)
    }
  }

  const handleDeliveryDateChange = async value => {
    try {
      await updateProject(selectedProject._id, { deliveryDate: value, editorDeadline: moment(value).subtract(1, 'day').toDate() })
      addNotification({ type: 'success', message: 'Delivery date updated'})
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'Error updating project delivery date'
      })
    }
  }

  const handleUpdateProjectState = async event => {
    if (window.confirm(`Update project state to ${projectStates[event.target.value].text}?`)) {
      try {
        await updateProject(selectedProject._id, { state: event.target.value })
      } catch (e) {
        addNotification({
          type: 'error',
          message: 'Error updating project state'
        })
      }
    }
  }

  const handleDeleteProject = async () => {
    const confirmed = window.confirm(`Delete project?`)
    if (confirmed) {
      try {
        await deleteProject(selectedProject._id)
      } catch (e) {
          addNotification({
            type: 'error',
            message: 'Error deleting project'
        })
      }
    }
  }

  const handleApproveEdits = () => {
    setModalView('AdminApproval')
  }

  const handleRejectEdits = () => {
    updateProject(selectedProject._id, { state: 4 })
  }

  const ProjectState = () => {
    let text, icon
        
    switch (selectedProject.state) {
      case 1:
        text = 'Awaiting Client Upload'
        icon = 'hourglass half'
        break
      case 2:
        text = 'Client Uploading'
        icon = 'upload'
        break
      case 3:
        text = 'New'
        icon = 'picture'
        break
      case 4:
        text = 'In Editing'
        icon = 'paint brush'
        break
      case 5:
        text = 'Editor Uploading'
        icon = 'upload'
        break
      case 6:
        text = 'In Review'
        icon = 'search'
        break
      case 7:
        text = 'Complete'
        icon = 'circle check'
        break
      default:
        text = 'Unknown'
        icon = 'question circle outline'
    }

    return <span><Icon name={icon}/> {text}</span>
  }

  const StateSelect = ({role, state}) => {
    if (['super', 'dev', 'admin'].includes(role)) {
      role = 'admin'
    }

    const stateValues = {
      1: 'Awaiting Client Upload',
      2: 'Source Uploading',
      3: 'New',
      4: 'In Editing',
      5: 'Edits Uploading',
      6: 'In Review',
      7: 'Complete',
      'Awaiting Client Upload': 1,
      'Source Uploading': 2,
      'New': 3,
      'In Editing': 4,
      'Edits Uploading': 5,
      'In Review': 6,
      'Complete': 7
    }

    const options = {
      admin: [
        'Awaiting Client Upload',
        'Source Uploading',
        'New',
        'In Editing',
        'Edits Uploading',
        'In Review',
        'Complete'
      ],
      editor: [
        'In Editing',
        'Edits Uploading'
      ],
      client: [
        'Awaiting Client Upload',
        'Source Uploading'
      ]
    }

    if (options[role].includes(stateValues[state])) {
       return (
        <UserSelect
          value={state}
          onChange={handleUpdateProjectState}
        >
          { options[role].map(option => <option key={option} value={stateValues[option]}>{option}</option>) }
        </UserSelect>
      )
    }


    return <ProjectState />
  }

  const Tools = () => {       

    const toolsComponent = {
      0: 'No Tools',
      1: `${selectedProject.minutes || 0} Minutes Excess Tool Work Allotted`
    } 
      
    return (
        <Spec>
            <DetailTitle>Tools</DetailTitle>
            <SpecValue>{toolsComponent[selectedProject.tools]}</SpecValue>
        </Spec>
    )
  }

  const FileField = ({ version, reupload }) => {
    if (reupload) {
      if (version === 'source' && currentUser.role !== 'editor') {
        return (
          <div>
            <input
              hidden
              type='file'
              id={'sourceUpload'} 
              name={'sourceUpload.zip'}
              accept='application/zip' 
              onChange={uploadFile}
            />
            <StyledLabel htmlFor={version + 'Upload'}>
              <Popup content='Re-Upload' trigger={<Icon name='upload' />} />
            </StyledLabel>
          </div>
        )
      } else return <div></div>
    } else {
      return (
        <div>
          <input
            hidden
            type='file'
            id={version + 'Upload'} 
            name={version + 'Upload.zip'} 
            accept='application/zip' 
            onChange={uploadFile}
          />
          <StyledLabel htmlFor={version + 'Upload'}>
            <Popup content='Upload' trigger={<Icon name='upload' />} />
          </StyledLabel>
      </div>
      )
    }
  }
  
  const FileSection = ({ version }) => (
    <Detail>
      <DetailTitle>{version} File</DetailTitle>
      { selectedProject.state === 2 && <StyledProgressBar progress={uploadProgress ? uploadProgress : selectedProject.uploadProgress} strokeWidth={3} trackStrokeWidth={3} radius={11}><span>{Math.floor(uploadProgress ? uploadProgress : selectedProject.uploadProgress)}%</span></StyledProgressBar>}

      { selectedProject[version + 'Upload'] && 
        <Popup content='Download' trigger={<a href={selectedProject[version + 'Upload']}>
          <StyledButton onClick={() => selectedProject.state === 3 && currentUser.role !== 'client' && updateProject(selectedProject._id, { state: selectedProject.state + 1 })}>
            <Icon name='download' />
          </StyledButton>
        </a>} />
      }
      { version === 'source' && currentUser.role !== 'editor' && selectedProject.state !== 2 && <FileField version={version} reupload={selectedProject[version + 'Upload']}/> }
      { version === 'worked' && selectedProject.state !== 5 && <FileField version={version} reupload={selectedProject[version + 'Upload']}/> }
    </Detail>
  ) 
  
  const EditorUpload = () => (
    <Detail>
      <DetailTitle>Worked File</DetailTitle>
      { selectedProject['workedUpload'] && 
        <Popup 
          content='Download' 
          trigger={
            <a href={selectedProject['workedUpload']}>
              <StyledButton>
                <Icon name='download'/>
              </StyledButton></a>
          } 
        /> 
      }
      <Popup 
        content={selectedProject['workedUpload'] ? 'Re-Upload' : 'Upload'} 
        trigger={
          <div>
            <StyledButton onClick={() => setModalView('editorUpload')}>
              <Icon name='upload'/>
            </StyledButton></div>
      } />
      { ['admin', 'super', 'dev'].includes(currentUser.role) && selectedProject.state === 6 &&
         <>
          <StyledButton positive onClick={handleApproveEdits} style={{ width: '80px'}}>
            <Icon name='check' /> Approve
          </StyledButton>
          <StyledButton negative onClick={handleRejectEdits} style={{ width: '80px'}}>
            <Icon name='cancel' /> Reject
          </StyledButton>
        </>
              
      }
      { selectedProject.state === 5 && <StyledProgressBar progress={uploadProgress ? uploadProgress : selectedProject.uploadProgress} strokeWidth={3} trackStrokeWidth={3} radius={11}><span>{Math.floor(uploadProgress ? uploadProgress : selectedProject.uploadProgress)}%</span></StyledProgressBar>}
    </Detail>
  )

  const CompletedFile = () => (
    <Detail>
      <DetailTitle>Completed File</DetailTitle>
      { selectedProject['workedUpload'] && 
        <Popup 
          content='Download' 
          trigger={
            <a href={selectedProject['workedUpload']}>
              <StyledButton>
                <Icon name='download'/>
              </StyledButton></a>
          } 
        /> 
      }
    </Detail>
  )

  const EditorSelect = () => {
    let options = []

    if (availableEditors) {
      options = availableEditors.map(editor => (
        <option key={editor.id} value={JSON.stringify(editor)}>
          {editor.fullName}
        </option>
      ))
    }

    return ['admin', 'super', 'dev'].includes(currentUser.role) ? (
      <UserSelect value={0} onChange={handleAssignEditor}>
        <option key={1}>Assign Editor</option>
        {options}
      </UserSelect>
    ) : <></>
  }

  const User = ({ role, name }) => (
    <Detail>
      <DetailTitle>{role}</DetailTitle>
      <DetailValue>
        <Bubble
          initials={name ? name
            .split(' ')
            .map(name => name[0])
            .join('') : ''
          }
        />
        { name ? name : (role === 'editor' && ['admin', 'super', 'dev'].includes(currentUser.role) && <EditorSelect /> )}
        { name && role === 'editor' && ['admin', 'super', 'dev'].includes(currentUser.role) && <UnassignEditor /> }
      </DetailValue>
    </Detail>
  )

  const ProjectDate = ({ type, date, rush }) => (
    <Detail>
      <DetailTitle>{type} Date</DetailTitle>
      <DetailDate rush={rush}>
        {date ? moment(date).format('MMM Do YYYY, h:mm a') : 'TBD'}
      </DetailDate>
    </Detail>
  )


  const Comments = ({ currentUser }) => {
    return selectedProject.comments
      .filter(comment => comment.viewableTo.includes(currentUser.role))
      .map(comment => <Comment key={comment._id} comment={comment} currentUser={currentUser}/>)
  }

  const RushRequested = () => {
    return (
      <Spec key='rush'>
        <DetailTitle>Rush Requested: <span style={{ color: 'black', fontWeight: 500 }}>{moment(selectedProject.rushRequestDate).format('MM/DD/YYYY')}</span></DetailTitle>
        <Button icon size='mini' color='green' onClick={() => approveOrRejectRushRequest(selectedProject._id, true)}><Icon name='check' />Approve</Button>
        <Button icon size='mini' color = 'red' onClick={() => approveOrRejectRushRequest(selectedProject._id, false)}><Icon name='cancel' />Reject</Button>
      </Spec>
    )
  }

  const RushLabel = () => (
    <Spec key='rush'>
      <DetailTitle></DetailTitle>
      <Label tag color='red'>Rush</Label>
    </Spec>
  )

  return (
    <Container show={show}>
      <Header>
        <Overlay>
          <Banner>
            <div>
              <Title>{selectedProject.name}</Title>
              <Type>{selectedProject.service}</Type>
            </div>
          </Banner>
        </Overlay>
      </Header>
      <Body mobile={mobile}>
        <Detail>
          <DetailTitle>Status</DetailTitle>
          <DetailValue>
          <StateSelect role={currentUser.role} state={selectedProject.state}/>
          </DetailValue>
        </Detail>
        {
          specTypes.filter(spec => selectedProject[spec]).map((spec, i) => {
            let value = selectedProject[spec]

            if (editSpec === spec) {
                return (
                    <Spec key={spec}>
                        <DetailTitle>{ i === 0 && 'Specs'}</DetailTitle>
                        <SpecLabel>{spec}:</SpecLabel>
                        <SpecInput value={editSpecValue} onChange={e => setEditSpecValue(e.target.value)}/>
                        <Icon name='checkmark' size='big' color='green' style={{ marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleUpdateSpec(spec, editSpecValue)}/>
                        <Icon name='cancel' size='big' color='red' style={{ marginLeft: '10px', cursor: 'pointer'}} onClick={() => { setEditSpec(null); setEditSpecValue(null)}}/>
                    </Spec>
                )
            }
            
            return (
                <Spec key={spec}>
                    <DetailTitle>{ i === 0 && 'Specs'}</DetailTitle>
                    { Boolean(value) !== value && <SpecValue>{spec !== 'cullTo' ? spec : 'CULL TO'}: {value}</SpecValue> }
                    { Boolean(value) === value && <SpecValue>{spec}</SpecValue> }
                    {
                        ['super', 'admin', 'dev', 'editor'].includes(currentUser.role) && 
                        typeof(value) === 'number' && 
                       <Icon size='big' name='pencil' style={{ marginLeft: '10px', cursor: 'pointer'}} onClick={() => { setEditSpec(spec); setEditSpecValue(value) }}/>
                    }
                </Spec>
            )
          })  
        }
        { selectedProject.tools === 1 && <Tools /> }
        { ['admin', 'super', 'dev'].includes(currentUser.role) && selectedProject.rushRequested && selectedProject.rushApproved === undefined && <RushRequested /> }
        { selectedProject.rushApproved && <RushLabel />}
        <FileSection version='source' />
        { (currentUser.role !== 'client' && selectedProject.state >= 4) && <EditorUpload /> }
        { (currentUser.role === 'client' && selectedProject.state === 7) && <CompletedFile /> }
        { currentUser.role !== 'editor' && <User role='author' name={selectedProject.client.fullName} /> }
        { currentUser.role !== 'client' && <User role='editor' name={selectedProject.editor ? selectedProject.editor.fullName : null} /> }
        { 
          selectedProject.uploadDate && 
          <ProjectDate 
            type='Upload' 
            date={moment(new Date()).add(1, 'year') > moment(selectedProject.uploadDate) ? selectedProject.uploadDate: null} 
          />
        }
        { 
          selectedProject.createdAt &&
          <Detail>
            <DetailTitle>Created At</DetailTitle>
            <DetailDate>
              {selectedProject.createdAt ? moment(selectedProject.createdAt).format('MMM Do YYYY, h:mm a') : ''}
            </DetailDate>
          </Detail> 
        }
        { 
          currentUser.role !== 'client' && 
          selectedProject.editorDeadline && 
          <ProjectDate 
            type='Editor Due' 
            date={moment(new Date()).add(1, 'year') > moment(selectedProject.editorDeadline) ? selectedProject.editorDeadline : null} 
          /> 
        }
        { 
          currentUser.role === 'client' && 
          selectedProject.deliveryDate && 
          <ProjectDate
            rush={selectedProject.rushApproved}
            type='Delivery' 
            date={moment(new Date()).add(1, 'year') > moment(selectedProject.deliveryDate) ? selectedProject.deliveryDate : null} 
          />
        }
        { 
          ['admin', 'super', 'dev'].includes(currentUser.role) && 
          selectedProject.deliveryDate && 
          moment(new Date()).add(1, 'year') > moment(selectedProject.deliveryDate) &&
          <Detail>
            <DetailTitle>Delivery Date</DetailTitle>
              <DatePicker
                dropUp={true}
                onChange={handleDeliveryDateChange}
                format="DD MMM YYYY"
                min={new Date()}
                value={moment(selectedProject.deliveryDate).toDate()}
              />
            </Detail>
        }
        { selectedProject.approved && (
          <Detail>
            <DetailTitle>Completed Project</DetailTitle>
            <DetailValue>
              <Button><Icon name='download' /></Button>
            </DetailValue>
          </Detail>
        )}
        <Notes>
          <NotesTitle style={{ textTransform: 'none' }}>Cropping Notes</NotesTitle>
          <DetailValue>{selectedProject.croppingNotes}</DetailValue>
        </Notes>
        <Notes>
          <NotesTitle style={{ textTransform: 'none' }}>Client Bio</NotesTitle>
          <DetailValue>{selectedProject.clientBio}</DetailValue>
        </Notes>
        { ['super', 'dev'].includes(currentUser.role) && (
          <Detail>
            <Button color='red' size='mini' style={{ margin: '10px auto'}} onClick={handleDeleteProject}>Delete Project</Button>
          </Detail>
        )}
      </Body>
      <CommentBox ref={messageBox}><Comments currentUser={currentUser}/></CommentBox>
      <NewComment />
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    selectedProject: state.project.selectedProject,
    currentUser: state.user.currentUser,
    editors: state.user.editors,
    reviewers: state.user.reviewers,
    mobile: state.index.mobile,
    uploadProgress: state.project.uploadProgress,
    uploadType: state.project.uploadType,
    uploadRemainingTime: state.project.uploadRemainingTime,
    availableEditors: state.user.availableEditors
  }
}

export default connect(mapStateToProps, {
  updateProject,
  deleteProject,
  setDrawerView,
  setShowOverlay,
  addNotification,
  setModalView,
  setUploadProgress,
  setUploadType,
  setSubmittingNotes,
  fetchAvailableEditors,
  approveOrRejectRushRequest
})(Project)
