import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Notification from '../Shared/Notification'

const Container = styled.div`
    width: 300px;
    height: 100vh;
    position: absolute;
    right: -300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
`

const NotificationContainer = ({ notifications }) => {

    const renderNotifications = () => {
        return notifications.map(notification => <Notification 
                                                    key={notification.id} 
                                                    notification={notification}
                                                />)
    }
    
    return (
        <Container>
            { renderNotifications() }
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        notifications: state.notification.notifications
    }
}

export default connect(mapStateToProps)(NotificationContainer)