import styled from 'styled-components'
import { blue, error } from './colors'

const Container = styled.div`
    display: inline-block;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.size};
    width: ${props => props.size};
    border-radius: 50%;
    border: ${props => (props.empty || (props.selection && !props.selected)) && `1px dashed #000`};
    background-image: ${props => 'url(' + props.background + ')' || null};
    background-size: cover;
    background-position: center;
    background-color: ${props => {
        if (props.danger)
            return error
        if (!props.empty || (props.selection && props.selected))
            return blue
        return '#fff'
    }};
    color: ${props => (props.selection && !props.selected) ? blue : '#fff'};
    font-family: Roboto;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    margin: 0 2px;
    &:hover {
        cursor: ${props => props.onClick && 'pointer'};
    }
`

const Bubble = ({ background, initials, onClick, selection, selected, size, danger, dataFor, dataTip }) => {
    return (
        <Container 
            danger={danger} 
            size={size || '35px'} 
            empty={(!background && !initials) || (selection && !selected)} 
            selection={selection || false} 
            selected={selected || false} 
            background={background} 
            initials={!background && initials} 
            onClick={onClick}
            data-for={dataFor}
            data-tip={dataTip}
        >
            { initials || selection }
        </Container>
    )
}

export default Bubble